import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Card, Typography, Link } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import BGImage from "../../assets/images/signin-bg-min.png";

const LoginBackground = ({ children }) => {
  const classes = useStyles();

  const onHandleTermsandUse = () => {};

  return (
    <div className={classes.loginBackground}>
      <Card className={classes.loginCard}>
        <CardContent sx={{padding: 0}}>{children}</CardContent>
      </Card>
      <footer className={classes.footer}>
        <Typography variant="caption" color="textSecondary" sx={{ color: "#C4C4C4" }}>
          Protected by one or more issued or pending patents, listed at Terms of
          Use
          <a
            href="https://www.vrad.com/terms-of-use/"
            target="_blank"
            rel="noreferrer"
            sx={{ color: "#C4C4C4" }}
          >
            Terms of Use
          </a>
        </Typography>
      </footer>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginBackground: {
      background: `url(${BGImage}) no-repeat center center fixed`,
      height: "100vh",
      width: "100vw",
      backgroundSize: "cover",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loginCard: {
      width: "500px",
      borderRadius: "1.5rem",
      padding: "72px 44px",
      height: "600px",
      boxShadow: "0px 6px 48px rgba(0, 0, 0, 0.35)",
      borderRadius: "20px",
    },
    footer: {
      position: "fixed",
      bottom: 0,
      width: "100%",
      backgroundColor: "rgba(13, 22, 36, 0.7)",
      textAlign: "center",
    },
  })
);

export default LoginBackground;

import React, { useEffect } from "react";
import DataTable from "../../components/DataTable";
import CommunicationImage from "../../assets/images/Communications_Center.svg";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import moment from "moment";

const HOME = () => {
  const [open, setOpen] = React.useState(true);
  const [isNotificationShow, setIsNotificationShow] = React.useState(false);
  const { notifications } = useSelector((state) => ({
    notifications: state.loginReducer.notifications,
  }));

  useEffect(() => {
    if (Object.keys(notifications).length > 0) {
      const data = JSON.parse(localStorage.getItem("vRadNotification"));

      if (!data) {
        // if no value exists associated with the key, return null
        setIsNotificationShow(true);
      } else if (moment(data.ttl).isBefore(moment(new Date()).format("DD/MM/YYYY hh:mm:ss"))) {
        localStorage.removeItem("vRadNotification");
        setIsNotificationShow(true);
      }
    } else {
      setIsNotificationShow(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    const data = {
      value: "Dismissed",
      ttl: moment(new Date(), "DD-MM-YYYY hh:mm:ss")
        .add(5, "days")
        .format("DD/MM/YYYY hh:mm:ss"),
    };

    // store data in LocalStorage
    localStorage.setItem("vRadNotification", JSON.stringify(data));
    setOpen(false);
  };
  const getAlertType = () => {
    if (
      Object.keys(notifications).length > 0 &&
      notifications[0].Type === "Warning"
    ) {
      return "0px 0px 48px 0px #ED6C02";
    } else if (
      Object.keys(notifications).length > 0 &&
      notifications[0].Type === "Info"
    ) {
      return "0px 0px 48px 0px #0288D1";
    } else if (
      Object.keys(notifications).length > 0 &&
      notifications[0].Type === "Success"
    ) {
      return "0px 0px 48px 0px #2E7D32";
    } else if (
      Object.keys(notifications).length > 0 &&
      notifications[0].Type === "Error"
    ) {
      return "0px 0px 48px 0px #D32F2F";
    }
  };

  const getIconColor = () => {
    if (
      Object.keys(notifications).length > 0 &&
      notifications[0].Type === "Warning"
    ) {
      return "#ED6C02";
    } else if (
      Object.keys(notifications).length > 0 &&
      notifications[0].Type === "Info"
    ) {
      return "#0288D1";
    } else if (
      Object.keys(notifications).length > 0 &&
      notifications[0].Type === "Success"
    ) {
      return "#2E7D32";
    } else if (
      Object.keys(notifications).length > 0 &&
      notifications[0].Type === "Error"
    ) {
      return "#D32F2F";
    }
  };

  const getTextColor = () => {
    if (
      Object.keys(notifications).length > 0 &&
      notifications[0].Type === "Warning"
    ) {
      return "linear-gradient(0deg, #ED6C02, #ED6C02), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))";
    } else if (
      Object.keys(notifications).length > 0 &&
      notifications[0].Type === "Info"
    ) {
      return "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), linear-gradient(0deg, #2E7D32, #2E7D32)";
    } else if (
      Object.keys(notifications).length > 0 &&
      notifications[0].Type === "Success"
    ) {
      return "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), linear-gradient(0deg, #0288D1, #0288D1)";
    } else if (
      Object.keys(notifications).length > 0 &&
      notifications[0].Type === "Error"
    ) {
      return "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), linear-gradient(0deg, #D32F2F, #D32F2F)";
    }
  };

  return (
    <>
      {isNotificationShow && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          onClose={handleClose}
          sx={{
            boxShadow: getAlertType(),
            borderRadius: "6px",
            marginTop: "15px",
          }}
        >
          <Alert
            severity={
              Object.keys(notifications).length > 0 &&
              notifications[0].Type.toString().toLowerCase()
            }
            sx={{
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.99), rgba(255, 255, 255, 0.99))",
              borderRadius: "6px",
              width: "50vw",
              "& .MuiAlert-message": {
                padding: "4px 0 !important",
              },
              "& .MuiAlert-icon": {
                color: getIconColor(),
              },
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "45vw",
              }}
            >
              <div style={{ width: "85%" }}>
                <span
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    fontSize: "1rem",
                    fontWeight: 700,
                    // color: getTextColor(),
                  }}
                >
                  {Object.keys(notifications).length > 0 &&
                    notifications[0].Title}
                </span>
                <span
                  style={{
                    display: "block",
                    fontSize: "0.8rem",
                    textAlign: "justify",
                    // color: getTextColor()
                  }}
                >
                  {Object.keys(notifications).length > 0 &&
                    parse(notifications[0].Description)}
                </span>
              </div>
              <div
                style={{ display: "block", width: "15%", textAlign: "right" }}
              >
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <span style={{ fontSize: "0.8rem" }}>Dismiss</span>{" "}
                  <CloseIcon style={{ fontSize: "0.8rem" }} />
                </IconButton>
              </div>
            </div>
          </Alert>
        </Snackbar>
      )}
      <div
        style={{
          margin: "1rem 1rem 2rem 2rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <Typography variant="h3" sx={{textTransform: 'uppercase', color: ''}}>
      Communications Center
    </Typography> */}
        <img
          src={`${CommunicationImage}`}
          alt="VRad Communcatons"
          width="654"
          height="64"
        />
        <DataTable />
      </div>
    </>
  );
};

export default HOME;

import { request } from "./request";
import {
  FETCH_GROUP_RECIPIENTS,
  FETCH_INDIVIDUAL_RECIPIENTS,
  FETCH_STATUS_REPORTS_BY_ID,
  SAVE_SELECTED_GROUP,
  POST_SEND_DISPATCH,
  FETCH_TEMPLATE_LIST,
} from "../constants/constants";

export function fetchGroupRecipientsWithFilter() {
  return (dispatch) => {
    return request("Group", "GET", {}).then((response) => {
      let zeroTempGroupRecipients = [];
      response.Data.map((newRecipient, index) => {
        if (newRecipient.RecipientCount !== 0) {
          zeroTempGroupRecipients.push(newRecipient);
        }
      });
      dispatch({
        type: FETCH_GROUP_RECIPIENTS,
        payload: zeroTempGroupRecipients,
      });
    });
  };
}

export function fetchIndividualRecipients() {
  return (dispatch) => {
    return request("Recipient", "GET", {}).then((response) => {
      dispatch({
        type: FETCH_INDIVIDUAL_RECIPIENTS,
        payload: response.Data,
      });
    });
  };
}

export function fetchTemplates() {
  return (dispatch) => {
    return request("Template", "GET", {}).then((response) => {
      dispatch({
        type: FETCH_TEMPLATE_LIST,
        payload: response.Data,
      });
    });
  };
}

export function postDispatchRecipients(payload) {
  return (dispatch) => {
    return request("Message/SendDispatch", "POST", payload).then((response) => {
      dispatch({
        type: POST_SEND_DISPATCH,
        payload: response.Data,
      });
    });
  };
}

export function saveSelectedValues(existingSelectedGroup) {
  return (dispatch) => {
    return dispatch({
      type: SAVE_SELECTED_GROUP,
      payload: existingSelectedGroup,
    });
  };
}

export function fetchStatusReportById(Id) {
  return (dispatch) => {
    return request(`MessageLog/${Id}`, "GET", {}).then((response) => {
      dispatch({
        type: FETCH_STATUS_REPORTS_BY_ID,
        payload: response.Data,
      });
    });
  };
}

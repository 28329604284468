import React, { useState, useEffect } from "react";
import {
  Typography,
  IconButton,
  Dialog,
  Button,
  Grid,
  CircularProgress,
  Paper,
  Divider,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";
import Slide from "@mui/material/Slide";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import { useFormik } from "formik";
import * as yup from "yup";
import useAPIError from "../../common/hooks/useAPIError";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchTemplates,
  fetchTemplatesById,
  postTemplate,
  putTemplate,
  deleteTemplatesById,
} from "../../redux/actions/templateActions";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import store from "../../redux/store";
import ManageTemplateDetails from "./ManageTemplateDetails";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return <Paper {...props} />;
}

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: "#e2596b !important",
        },
      },
      MuiTableHead: {
        root: {
          backgroundColor: "#e2596b",
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            backgroundColor: "#e2596b",
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: "#e2596b",
          },
        },
      },
    },
  });

export default function ManageTemplatesDialog({ open, onclose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showProgress, setShowProgress] = useState(false);
  const [showDeleteProgress, setShowDeleteProgress] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteContent, setDeleteContent] = useState([]);
  const [showTemplateDetails, setShowTemplateDetails] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [dialogTitle, setDialogTitle] = useState("Manage Templates");
  const [saveProgress, setSaveProgress] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { showNotification } = useAPIError();

  const { templates, template, role } = useSelector((state) => ({
    templates: state.templateReducer.templates || [],
    template: state.templateReducer.template || [],
    role: state.loginReducer.role
  }));

  // setCellProps: () => ({ style: { minWidth: "800px", maxWidth: "800px" }}),
  const columns = [
    {
      name: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "Name",
      label: "Template Name",
    },
    {
      name: "TemplateHtml",
      label: "Message Preview",
      options: {
        setCellProps: () => ({ style: { minWidth: "20px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden !important",
                textOverflow: "ellipsis",
              }}
            >
              {value ? `${value.substring(0, 20)}...` : ""}
            </span>
          );
        },
      },
    },
    {
      name: "CreatedBy",
      label: "Created By",
      options: {
        setCellProps: () => ({ style: { minWidth: "20px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden !important",
                textOverflow: "ellipsis",
              }}
            >
              {value ? `${value.substring(0, 10)}...` : ""}
            </span>
          );
        },
      },
    },
    {
      name: "CreatedOn",
      label: "Created On",
      options: {
        setCellProps: () => ({ style: { minWidth: "20px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden !important",
                textOverflow: "ellipsis",
              }}
            >
              {`${value ? moment(value).format("MM/DD/YYYY") : ""}`}
              {/* HH:mm:ss */}
            </span>
          );
        },
      },
    },
    {
      name: "UpdatedBy",
      label: "Modified By",
      options: {
        setCellProps: () => ({ style: { minWidth: "20px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden !important",
                textOverflow: "ellipsis",
              }}
            >
              {value ? `${value ? value.substring(0, 10) : ""}...` : "N/A"}
            </span>
          );
        },
      },
    },
    {
      name: "UpdatedOn",
      label: "Modified On",
      options: {
        setCellProps: () => ({ style: { minWidth: "20px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden !important",
                textOverflow: "ellipsis",
              }}
            >
              {`${value ? moment(value).format("MM/DD/YYYY") : "N/A"}`}
              {/* HH:mm:ss */}
            </span>
          );
        },
      },
    },
    {
      name: "Action",
      options: {
        filter: true,
        sort: true,
        width: "30px",
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex" }}>
              {Object.keys(role).length > 0 && role.template.includes('delete') && <IconButton
                onClick={(event) => deleteTemplate(value, tableMeta)}
              >
                <DeleteOutlineIcon />
              </IconButton>}
              {Object.keys(role).length > 0 && role.template.includes('edit') && <IconButton
                onClick={(event) => {
                  editTemplate(value, tableMeta);
                }}
              >
                <EditIcon />
              </IconButton>}
              {Object.keys(role).length > 0 && role.template.includes('view') && <IconButton
                onClick={(event) => viewTemplate(value, tableMeta)}
              >
                <VisibilityOutlinedIcon />
              </IconButton>}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
    selectableRows: false,
    responsive: "stacked",
    filter: false,
    download: false,
    print: false,
    search: true,
    sortOrder: {
      name: 'Name',
      direction: 'asc'
    },
    viewColumns: false,
    jumpToPage: true,
    textLabels: {
      body: {
        noMatch: showProgress ? (
          <CircularProgress />
        ) : (
          "Sorry, there is no matching data to display"
        ),
        toolTip: ""
      },
    },
  };

  useEffect(() => {
    setShowProgress(true);
    dispatch(fetchTemplates())
      .then(() => {
        setShowProgress(false);
      })
      .catch((error) => {
        showNotification(error, "error");
        setShowProgress(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenDialog = () => {
    setIsEdit(true);
    setTemplateData([]);
    setShowTemplateDetails(!showTemplateDetails);
    setDialogTitle("Create New Template");
  };

  const handleCloseTemplate = () => {
    setShowProgress(false);
    setShowTemplateDetails(!showTemplateDetails);
    setDialogTitle("Manage Template");
  };

  const editTemplate = (value, rowIndex) => {
    setShowProgress(true);
    const index = templates.findIndex(row => {
      return row.Id === rowIndex.rowData[0];
    });
    dispatch(fetchTemplatesById(templates[index].Id))
      .then(() => {
        setTemplateData(store.getState().templateReducer.template);
        setShowProgress(false);
        setIsEdit(true);
        setShowTemplateDetails(true);
        setDialogTitle("Edit Template");
      })
      .catch((error) => {
        showNotification(error, "error");
        setShowProgress(false);
      });
  };

  const viewTemplate = (value, rowIndex) => {
    setShowProgress(true);
    const index = templates.findIndex(row => {
      return row.Id === rowIndex.rowData[0];
    });
    dispatch(fetchTemplatesById(templates[index].Id))
      .then(() => {
        setTemplateData(store.getState().templateReducer.template);
        setShowProgress(false);
        setIsEdit(false);
        setShowTemplateDetails(true);
        setDialogTitle("View Template");
      })
      .catch((error) => {
        showNotification(error, "error");
        setShowProgress(false);
      });
  };

  const deleteTemplate = (value, rowIndex) => {
    setShowDeleteConfirmation(true);
    const index = templates.findIndex(row => {
      return row.Id === rowIndex.rowData[0];
    });
    setDeleteContent(templates[index]);
  };

  const handleDeleteClose = () => {
    setDeleteContent([]);
    setShowDeleteConfirmation(false);
  };

  const handleDeleteConfirm = () => {
    setShowDeleteProgress(true);
    dispatch(deleteTemplatesById(deleteContent.Id))
      .then(() => {
        dispatch(fetchTemplates())
          .then(() => {
            setDeleteContent([]);
            setShowDeleteConfirmation(false);
            setShowDeleteProgress(false);
            showNotification(
              `${deleteContent.Name} was successfully deleted`,
              "success"
            );
          })
          .catch((error) => {
            showNotification(error, "error");
            setShowDeleteProgress(false);
          });
      })
      .catch((error) => {
        showNotification(error, "error");
        setShowDeleteProgress(false);
      });
  };

  const handleSaveTemplate = (obj) => {
    let payload = {
      Name: obj.TemplateName,
      Type: "",
      TemplateHtml: obj.TemplateMessage,
      GroupId: 0,
      Description: "",
      IsActive: true,
    };
    if (Object.keys(templateData).length > 0) {
      payload.Id = templateData.Id;
    }
    setSaveProgress(true);
    dispatch(
      Object.keys(templateData).length > 0
        ? putTemplate(payload)
        : postTemplate(payload)
    )
      .then(() => {
        setSaveProgress(false);
        setTemplateData([]);
        setShowTemplateDetails(false);
        setShowProgress(true);
        dispatch(fetchTemplates())
          .then(() => {
            setShowProgress(false);
            showNotification(
              Object.keys(templateData).length === 0
                ? `Template ${obj.TemplateName} successfully added`
                : `Changes to Template ${obj.TemplateName} successfully saved`,
              "success"
            );
          })
          .catch((error) => {
            showNotification(error, "error");
            setShowProgress(false);
          });
      })
      .catch((error) => {
        showNotification(error, "error");
        setSaveProgress(false);
      });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onclose}
        TransitionComponent={Transition}
        aria-describedby="Dialog"
        onBackdropClick="false"
        fullWidth
        maxWidth="lg"
        scroll="paper"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle style={{ backgroundColor: "#F4F7FE" }}>
          <div className={classes.alignLeftRight}>
            <Typography variant="subtitle1" color="textPrimary">
              {role.role === "Dispatch"
                ? "View Templates"
                : "Manage Templates"}
            </Typography>
            <IconButton color="dark" aria-label="open drawer" onClick={onclose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {!showTemplateDetails && !showDeleteConfirmation && (
            <Grid container spacing={2}>
              <Grid item xs={12} mt={1} container justifyContent="flex-end">
                {Object.keys(role).length > 0 && role.template.includes('create') && <Button
                  size="small"
                  variant="contained"
                  sx={{
                    backgroundColor: "#8BD1F3",
                    color: "#003261",
                    width: "12rem",
                    "&:hover": {
                      backgroundColor: "#8BD1F3",
                      opacity: 0.8,
                    },
                  }}
                  onClick={handleOpenDialog}
                >
                  Create New Template
                </Button>}
              </Grid>
              <Grid item xs={12}>
                <ThemeProvider theme={getMuiTheme}>
                  <MUIDataTable
                    // title={"Users List"}
                    data={templates}
                    columns={columns}
                    options={options}
                    className={classes.muiDataTableUI}
                  />
                </ThemeProvider>
              </Grid>
            </Grid>
          )}
          {showTemplateDetails && (
            <ManageTemplateDetails
              templateData={templateData}
              onClose={handleCloseTemplate}
              onSave={handleSaveTemplate}
              saveProgress={saveProgress}
              isEdit={isEdit}
              dialogTitle={dialogTitle}
            />
          )}

          {showDeleteConfirmation && (
            <Grid container item xs={12} sx={{ mt: "19px" }}>
              <Grid xs={12} item>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  style={{ color: "#2CABEA" }}
                >
                  Delete Template
                </Typography>
                <Divider />
              </Grid>
              <Grid xs={12} item sx={{ mt: 4 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "12px 16px",
                    // height: "63px",
                    margin: "1rem",
                    background: "#FFFFFF",
                    border: "1px solid rgba(0, 50, 97, 0.85)",
                    boxShadow: "0px 12px 16px rgba(72, 85, 133, 0.15)",
                    borderRadius: "6px",
                    flexWrap: "wrap",
                  }}
                >
                  <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                    {deleteContent && deleteContent.Name}
                  </span>
                  <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                    {deleteContent && deleteContent.LastName}
                  </span>
                  <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                    {deleteContent && deleteContent.CreatedBy}
                  </span>
                  <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                    {deleteContent && deleteContent.CreatedOn
                      ? moment(deleteContent.CreatedOn).format("MM/DD/YYYY")
                      : "N/A"}
                  </span>
                  <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                    {deleteContent && deleteContent.UpdatedBy}
                  </span>
                  <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                    {deleteContent && deleteContent.UpdatedOn
                      ? moment(deleteContent.UpdatedOn).format("MM/DD/YYYY")
                      : "N/A"}
                  </span>
                </div>
              </Grid>
              <Grid
                xs={12}
                item
                sx={{ mt: 6, display: "block", textAlign: "center" }}
              >
                <Typography
                  sx={{ fontWeight: 700, fontSize: "34px", lineHeight: "34px" }}
                >
                  Are you Sure ?
                </Typography>
                <Typography
                  sx={{
                    color: "#75787B",
                    fontSize: "16px",
                    lineHeight: "20px",
                    mt: "4px",
                  }}
                >
                  This will permanently delete the template and make it
                  unavailable for Dispatch creation
                </Typography>
                <div style={{ marginTop: "83px", marginBottom: "80px" }}>
                  <Button autoFocus onClick={handleDeleteClose}>
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "#FA4858",
                      color: "#FFFFFF",
                      "&:hover": {
                        backgroundColor: "#FA4858",
                        opacity: 0.8,
                      },
                    }}
                    onClick={handleDeleteConfirm}
                  >
                    {showDeleteProgress && (
                      <CircularProgress size={25} sx={{ color: "#fff" }} />
                    )}
                    {!showDeleteProgress && `Delete Template`}
                  </Button>
                </div>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
      {/* {showDeleteConfirmation && (
        <Dialog
          open={showDeleteConfirmation}
          onClose={handleDeleteClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Are you Sure ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`${deleteContent.Name} | 
            ${deleteContent.CreatedBy}
            `}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleDeleteClose}>
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#FA4858",
                color: "#FFFFFF",
                // width: "12rem",
                "&:hover": {
                  backgroundColor: "#FA4858",
                  opacity: 0.8,
                },
              }}
              onClick={handleDeleteConfirm}
            >
              {showDeleteProgress && (
                <CircularProgress size={25} sx={{ color: "#fff" }} />
              )}
              {!showDeleteProgress && `Confirm`}
            </Button>
          </DialogActions>
        </Dialog>
      )} */}
    </>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    alignLeftRight: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    paper: {
      maxWidth: "100% !important",
      // width: "1080px !important",
      height: "746px",
      overflow: "hidden !important",
    },
    active: {
      color: "#05CD99",
    },
    inactive: {
      color: "#0D1624",
    },
    muiDataTableUI: {
      borderRadius: "10px 10px 0px 0px !important",

      "& .tss-r6c9w6-MUIDataTable-responsiveStacked": {
        borderRadius: "0",
      },

      "& .MuiTableCell-head": {
        backgroundColor: "#11315E",
        color: "#FFFFFF !important",
        padding: "0.5rem",
        whiteSpace: "nowrap",
        // textAlign: "center",

        "& .Mui-active": {
          color: "#FFFFFF",
        },

        "& .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon":
          {
            color: "#FFFFFF",
          },

        "& .tss-bd2lw8-MUIDataTableHeadCell-sortActive": {
          color: "#FFFFFF",
        },

        "& .css-hyum1k-MuiToolbar-root": {
          backgroundColor: "#11315E !important",
        },

        "& .MuiInput-root:before": {
          borderBottom: "1px solid #FFFFFF !important",
        },

        "& .MuiInput-root:after": {
          borderBottom: "2px solid #FFFFFF !important",
        },
      },
      "& .tss-tlx3x1-MUIDataTableToolbar-root": {
        backgroundColor: "#11315E !important",
        color: "#FFFFFF",
        minHeight: "0.5rem !important",
        borderRadius: "10px 10px 0px 0px !important",
      },
      "& .tss-10rusft-MUIDataTableToolbar-icon": {
        color: "#FFFFFF",
      },
      // "& .MuiSvgIcon-root": {
      //   color: '#FFFFFF'
      // },
      "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root": {
        color: "#FFFFFF",
      },

      // "& .css-zylse7-MuiButtonBase-root-MuiIconButton-root": {
      //   backgroundColor: 'rgba(0, 0, 0, 0.54)',
      //   height: '1rem',
      //   width: '1rem',
      //   padding: '0.8rem'
      // },

      "& .tss-1ufdzki-MUIDataTableSearch-main > .MuiSvgIcon-root": {
        color: "#ffffff",
      },

      "& .tss-1ufdzki-MUIDataTableSearch-main > .MuiButtonBase-root": {
        color: "#ffffff",
      },

      "& .MuiInput-root": {
        color: "#ffffff",
      },

      "& .MuiInput-root:before": {
        borderBottom: "1px solid #FFFFFF !important",
      },

      "& .MuiInput-root:after": {
        borderBottom: "2px solid #FFFFFF !important",
      },
      // "& .tss-1ufdzki-MUIDataTableSearch-main > .MuiFormControl-root > .MuiInput-root:before":
      //   {
      //     borderBottom: "1px solid #FFFFFF !important",
      //   },
      // "& .tss-1ufdzki-MUIDataTableSearch-main > .MuiFormControl-root > .MuiInput-root:after":
      //   {
      //     borderBottom: "2px solid #FFFFFF !important",
      //   },
      // "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root:before": {
      //   borderBottom: '1px solid #FFFFFF !important'
      // },
      // "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root:after ": {
      //   borderBottom: '2px solid #FFFFFF !important'
      // }
    },
  })
);

import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Grid, Button, Typography } from "@mui/material";

const Success = React.forwardRef((props, ref) => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div>
        <Typography variant="h4" align="center">
          Your dispatch has been sent!
        </Typography>
        <Typography variant="body2" align="center" sx={{color: '#75787B'}}>
          You're all set. Want to see how your dispatch is performing?
        </Typography>
      </div>
      <div style={{ marginTop: "60px", marginBottom: '50px' }}>
        <CheckCircleOutlineIcon sx={{ height: "125px", width: "125px", color: "#05CD99" }} />
      </div>
    </div>
  );
});

export default Success;

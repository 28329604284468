import React, { useState, useEffect } from "react";
import {
  Typography,
  IconButton,
  Dialog,
  Button,
  Grid,
  CircularProgress,
  Chip,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Paper,
  DialogContentText,
  DialogActions,
  Divider,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";
import Slide from "@mui/material/Slide";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import useAPIError from "../../common/hooks/useAPIError";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchRecipientsByStatus,
  fetchRecipientById,
  postRecipient,
  putRecipient,
  deleteRecipientById,
  fetchGroups,
  fetchRecipients,
  fetchGroupById,
  putGroup,
  postGroup,
  deleteGroupById,
} from "../../redux/actions/recipientActions";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import store from "../../redux/store";
import ManageRecipientDetails from "./ManageRecipientDetails";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import moment from "moment";
import { formatPhoneNumber } from "../../common/helpers";
import ManageGroupDetails from "./ManageGroupDetails";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return <Paper {...props} />;
}

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: "#e2596b !important",
        },
      },
      MuiTableHead: {
        root: {
          backgroundColor: "#e2596b",
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            backgroundColor: "#e2596b",
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: "#11315E",
          },
        },
      },
    },
  });

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ManageRecipientDialog({ open, onclose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showProgress, setShowProgress] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [showGroupProcessing, setShowGroupProcessing] = useState(false);
  const [showDeleteProgress, setShowDeleteProgress] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteContent, setDeleteContent] = useState([]);
  const [dialogTitle, setDialogTitle] = useState("Manage Recipients");
  const [showRecipientDetails, setShowRecipientDetails] = useState(false);
  const [showGroupDetails, setShowGroupDetails] = useState(false);
  const [recipientData, setRecipientData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [saveProgress, setSaveProgress] = useState(false);
  const [saveGroupProgress, setSaveGroupProgress] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isGroupEdit, setIsGroupEdit] = useState(false);
  const [isRecipientDelete, setIsRecipientDelete] = useState(null);
  const { showNotification } = useAPIError();
  const [tabValue, setTabValue] = React.useState(0);
  const [filterValue, setFilterValue] = React.useState("Active");

  const { recipients, recipient, groups, role } = useSelector((state) => ({
    recipients: state.recipientReducer.recipients || [],
    recipient: state.recipientReducer.recipient || [],
    groups: state.recipientReducer.groups,
    role: state.loginReducer.role,
  }));

  // setCellProps: () => ({ style: { minWidth: "800px", maxWidth: "800px" }}),
  const columns = [
    {
      name: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "FirstName",
      label: "First Name",
    },
    {
      name: "LastName",
      label: "Last Name",
    },
    {
      name: "PhoneNumber",
      label: "Mobile Number",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{formatPhoneNumber(value)}</span>;
        },
      },
    },
    {
      name: "Email",
      label: "Email",
    },
    {
      name: "DispatchPreference",
      label: "Notification Preference",
    },
    {
      name: "IsActive",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              label={value ? "Active" : "Inactive"}
              sx={{
                backgroundColor: value ? "#84C666" : "#FA4858",
                color: "#FFFFFF",
              }}
            />
          );
        },
      },
    },
    {
      name: "Action",
      options: {
        width: "30px",
        hint: "",
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex" }}>
              {Object.keys(role).length > 0 &&
                role.recipient.includes("delete") && (
                  <IconButton
                    onClick={(event) => deleteTemplate(value, tableMeta)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                )}
              {Object.keys(role).length > 0 && role.recipient.includes("edit") && (
                <IconButton
                  onClick={(event) => {
                    editTemplate(value, tableMeta);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
              {Object.keys(role).length > 0 && role.recipient.includes("view") && (
                <IconButton onClick={(event) => viewTemplate(value, tableMeta)}>
                  <VisibilityOutlinedIcon />
                </IconButton>
              )}
            </div>
          );
        },
      },
    },
  ];

  const groupColumns = [
    {
      name: "Name",
      label: "Group Name",
    },
    {
      name: "RecipientCount",
      label: "Members",
    },
    {
      name: "Description",
      label: "Description",
    },
    {
      name: "Action",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "20px", justifyContent: "flex-start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex", textAlign: "center" }}>
              {Object.keys(role).length > 0 &&
                role.recipientGroup.includes("delete") && (
                  <IconButton
                    onClick={(event) => deleteGroup(value, tableMeta)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                )}
              {Object.keys(role).length > 0 &&
                role.recipientGroup.includes("edit") && (
                  <IconButton
                    onClick={(event) => {
                      editGroup(value, tableMeta);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              {Object.keys(role).length > 0 &&
                role.recipientGroup.includes("view") && (
                  <IconButton onClick={(event) => viewGroup(value, tableMeta)}>
                    <VisibilityOutlinedIcon />
                  </IconButton>
                )}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
    selectableRows: false,
    responsive: "stacked",
    filter: false,
    download: false,
    print: false,
    search: true,
    viewColumns: false,
    jumpToPage: true,
    count: recipients.length,
    textLabels: {
      body: {
        noMatch: showProgress ? (
          <CircularProgress />
        ) : (
          "Sorry, there is no matching data to display"
        ),
        toolTip: ""
      },
    },
  };

  const optionsGroup = {
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
    selectableRows: false,
    responsive: "stacked",
    filter: false,
    download: false,
    print: false,
    search: true,
    viewColumns: false,
    jumpToPage: true,
    textLabels: {
      body: {
        noMatch: showProgress ? (
          <CircularProgress />
        ) : (
          "Sorry, there is no matching data to display"
        ),
        toolTip: ""
      },
    },
  };

  useEffect(() => {
    setShowProgress(true);
    Promise.all([
      dispatch(
        fetchRecipientsByStatus(filterValue === "Active" ? true : false)
      ),
      dispatch(fetchGroups()),
    ]).then(() => {
      setShowProgress(false);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenDialog = () => {
    setIsEdit(true);
    setRecipientData([]);
    setShowProcessing(true);
    dispatch(fetchGroups())
      .then(() => {
        setShowRecipientDetails(!showRecipientDetails);
        setShowProcessing(false);
        setDialogTitle("Create New Recipient");
      })
      .catch((error) => {
        showNotification(error, "error");
        setShowProcessing(false);
      });
  };

  const handleOpenGroupDialog = () => {
    setIsGroupEdit(true);
    setGroupData([]);
    setShowGroupProcessing(true);
    dispatch(fetchRecipients())
      .then(() => {
        setShowGroupDetails(!showGroupDetails);
        setShowGroupProcessing(false);
        setDialogTitle("Create New Group");
      })
      .catch((error) => {
        showNotification(error, "error");
        setShowProcessing(false);
      });
  };

  const handleCloseTemplate = () => {
    setShowProgress(false);
    setShowRecipientDetails(!showRecipientDetails);
    setDialogTitle("Manage Recipients");
  };

  const handleCloseGroups = () => {
    setShowProgress(false);
    setShowGroupDetails(!showGroupDetails);
    setDialogTitle("Manage Recipients");
  };

  const editTemplate = (value, rowIndex) => {
    setShowProgress(true);
    Promise.all([
      dispatch(fetchRecipientById(rowIndex.rowData[0])),
      dispatch(fetchGroups()),
    ])
      .then(() => {
        setRecipientData(store.getState().recipientReducer.recipient);
        setShowProgress(false);
        setIsEdit(true);
        setShowRecipientDetails(true);
        setDialogTitle("Edit Recipient");
      })
      .catch((error) => {
        showNotification(error, "error");
        setShowProgress(false);
      });
  };

  const editGroup = (value, rowIndex) => {
    setShowProgress(true);
    const index = groups.findIndex((row) => {
      return row.Name === rowIndex.rowData[0];
    });
    Promise.all([
      dispatch(fetchGroupById(groups[index].Id)),
      dispatch(fetchRecipients()),
    ])
      .then(() => {
        setGroupData(store.getState().recipientReducer.group);
        setShowProgress(false);
        setIsGroupEdit(true);
        setShowGroupDetails(true);
        setDialogTitle("Edit Group");
      })
      .catch((error) => {
        showNotification(error, "error");
        setShowProgress(false);
      });
  };

  const viewTemplate = (value, rowIndex) => {
    setShowProgress(true);
    dispatch(fetchRecipientById(rowIndex.rowData[0]))
      .then(() => {
        setRecipientData(store.getState().recipientReducer.recipient);
        setShowProgress(false);
        setIsEdit(false);
        setShowRecipientDetails(true);
        setDialogTitle("View Recipient");
      })
      .catch((error) => {
        showNotification(error, "error");
        setShowProgress(false);
      });
  };

  const viewGroup = (value, rowIndex) => {
    setShowProgress(true);
    const index = groups.findIndex((row) => {
      return row.Name === rowIndex.rowData[0];
    });
    Promise.all([
      dispatch(fetchGroupById(groups[index].Id)),
      dispatch(fetchRecipients()),
    ])
      .then(() => {
        setGroupData(store.getState().recipientReducer.group);
        setShowProgress(false);
        setIsGroupEdit(false);
        setShowGroupDetails(true);
        setDialogTitle("View Group");
      })
      .catch((error) => {
        showNotification(error, "error");
        setShowProgress(false);
      });
  };

  const deleteTemplate = (value, rowIndex) => {
    setShowDeleteConfirmation(true);
    setIsRecipientDelete(true);
    const index = recipients.findIndex((row) => {
      return row.Id === rowIndex.rowData[0];
    });
    setDeleteContent(recipients[index]);
  };

  const deleteGroup = (value, rowIndex) => {
    setShowDeleteConfirmation(true);
    setIsRecipientDelete(false);
    const index = groups.findIndex((row) => {
      return row.Name === rowIndex.rowData[0];
    });
    setDeleteContent(groups[index]);
  };

  const handleDeleteClose = () => {
    setDeleteContent([]);
    setShowDeleteConfirmation(false);
  };

  const handleDeleteConfirm = () => {
    setShowDeleteProgress(true);
    dispatch(
      isRecipientDelete
        ? deleteRecipientById(deleteContent.Id)
        : deleteGroupById(deleteContent.Id)
    ).then(() => {
      dispatch(fetchRecipientsByStatus(filterValue === "Active" ? true : false))
        .then(() => {
          setDeleteContent([]);
          setShowDeleteConfirmation(false);
          setShowDeleteProgress(false);
          setIsRecipientDelete(null);
          showNotification(
            isRecipientDelete
              ? `${deleteContent.FirstName} ${deleteContent.LastName} deleted.`
              : `${deleteContent.Name} is deleted successfully.`,
            "success"
          );
          dispatch(fetchGroups());
        })
        .catch((error) => {
          showNotification(error, "error");
          setShowProgress(false);
        });
    });
  };

  const handleSaveTemplate = (obj, selectedGroupNames) => {
    let groupIds = [];
    let groups = store.getState().recipientReducer.groups;

    selectedGroupNames.map((selectedGroupName) => {
      let tempGroup = groups.filter(
        (group) => group.Name === selectedGroupName
      );
      groupIds.push(tempGroup[0].Id);
    });

    let payload = {
      FirstName: obj.FirstName,
      LastName: obj.LastName,
      Email: obj.Email,
      PhoneNumber: obj.MobileNumber,
      Address1: "string",
      City: obj.City,
      State: obj.State,
      TimeZone: obj.TimeZone,
      DispatchPreference: obj.NotificationPreference,
      Country: obj.Country,
      County: "",
      PostalCode: "",
      CountryCode: obj.CountryCode.includes("+")
        ? obj.CountryCode
        : `+${obj.CountryCode}`,
      IsActive: obj.RecipientStatus === "Active" ? true : false,
      GroupIds: groupIds,
      GroupNames: [],
      TagNames: [],
    };

    if (Object.keys(recipientData).length > 0) {
      payload.Id = recipientData.Id;
    }
    setSaveProgress(true);
    dispatch(
      Object.keys(recipientData).length > 0
        ? putRecipient(payload)
        : postRecipient(payload)
    )
      .then(() => {
        setSaveProgress(false);
        setRecipientData([]);
        setShowRecipientDetails(false);
        setShowProgress(true);
        Promise.all([
          dispatch(
            fetchRecipientsByStatus(filterValue === "Active" ? true : false)
          ),
          dispatch(fetchGroups()),
        ]).then(() => {
          setShowProgress(false);
          showNotification(
            Object.keys(recipientData).length > 0
              ? `${obj.FirstName} ${obj.LastName} recipient modified successfully.`
              : `${obj.FirstName} ${obj.LastName} recipient created successfully.`,
            "success"
          );
        });
      })
      .catch((error) => {
        showNotification(error, "error");
        setSaveProgress(false);
      });
  };

  const handleSaveGroup = (obj, selectedRecipient) => {
    let recipientIds = [];
    let groups = store.getState().recipientReducer.recipients;

    selectedRecipient.map((selectedGroupName) => {
      let tempGroup = groups.filter(
        (group) => group.Id === selectedGroupName.Id
      );
      recipientIds.push(tempGroup[0].Id);
    });

    let payload = {
      Name: obj.GroupName,
      Description: obj.GroupDescription,
      Recipients: recipientIds,
      IsActive: true,
    };

    if (Object.keys(groupData).length > 0) {
      payload.Id = groupData.Id;
    }
    setSaveGroupProgress(true);
    dispatch(
      Object.keys(groupData).length > 0 ? putGroup(payload) : postGroup(payload)
    )
      .then(() => {
        setSaveGroupProgress(false);
        setGroupData([]);
        setShowGroupDetails(false);
        setShowProgress(true);
        Promise.all([
          dispatch(
            fetchRecipientsByStatus(filterValue === "Active" ? true : false)
          ),
          dispatch(fetchGroups()),
        ]).then(() => {
          setShowProgress(false);
          showNotification(
            Object.keys(groupData).length > 0
              ? `Group called '${obj.GroupName}' edited successfully`
              : `Group called '${obj.GroupName}' created successfully`,
              "success"
              // `${selectedRecipient.length} recipients assigned to ${obj.GroupName} group successfully.`
          );
        });
      })
      .catch((error) => {
        showNotification(error, "error");
        setSaveGroupProgress(false);
      });
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      setFilterValue("Active");
      setShowProgress(true);
      dispatch(fetchRecipientsByStatus(true)).then(() => {
        setShowProgress(false);
      });
    }
    setTabValue(newValue);
  };

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
    setShowProgress(true);
    dispatch(
      fetchRecipientsByStatus(event.target.value === "Active" ? true : false)
    ).then(() => {
      setShowProgress(false);
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onclose}
        TransitionComponent={Transition}
        aria-describedby="Dialog"
        onBackdropClick="false"
        fullWidth
        maxWidth="lg"
        scroll="paper"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle style={{ backgroundColor: "#F4F7FE" }}>
          <div className={classes.alignLeftRight}>
            <Typography variant="subtitle1" color="textPrimary">
              {role.role === "Dispatch"
                ? "View Recipients"
                : "Manage Recipients"}
            </Typography>
            <IconButton color="dark" aria-label="open drawer" onClick={onclose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {!showRecipientDetails &&
            !showDeleteConfirmation &&
            !showGroupDetails && (
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Individuals" {...a11yProps(0)} />
                    <Tab label="Groups" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      mt={1}
                      container
                      justifyContent="space-between"
                    >
                      <Box
                        sx={{
                          minWidth: 120,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#003261",
                            marginRight: "1rem",
                            fontSize: "1rem",
                            fontWeight: 700,
                          }}
                        >
                          Showing:
                        </Typography>
                        <FormControl fullWidth size="small">
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filterValue}
                            label=""
                            onChange={handleFilterChange}
                          >
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Inactive">Inactive</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      {Object.keys(role).length > 0 &&
                        role.recipient.includes("create") && (
                          <Button
                            size="small"
                            variant="contained"
                            sx={{
                              backgroundColor: "#8BD1F3",
                              color: "#003261",
                              width: "12rem",
                              "&:hover": {
                                backgroundColor: "#8BD1F3",
                                opacity: 0.8,
                              },
                            }}
                            onClick={handleOpenDialog}
                          >
                            {showProcessing && (
                              <CircularProgress
                                size={25}
                                sx={{ color: "#fff" }}
                              />
                            )}
                            {!showProcessing && `Create New Recipient`}
                          </Button>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                      <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable
                          // title={"Users List"}
                          data={recipients}
                          columns={columns}
                          options={options}
                          className={classes.muiDataTableUI}
                        />
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      mt={1}
                      container
                      justifyContent="flex-end"
                    >
                      {Object.keys(role).length > 0 &&
                        role.recipientGroup.includes("create") && (
                          <Button
                            size="small"
                            variant="contained"
                            sx={{
                              backgroundColor: "#8BD1F3",
                              color: "#003261",
                              width: "12rem",
                              "&:hover": {
                                backgroundColor: "#8BD1F3",
                                opacity: 0.8,
                              },
                            }}
                            onClick={handleOpenGroupDialog}
                          >
                            {showGroupProcessing && (
                              <CircularProgress
                                size={25}
                                sx={{ color: "#fff" }}
                              />
                            )}
                            {!showGroupProcessing && `Create New Group`}
                          </Button>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                      <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable
                          // title={"Users List"}
                          data={groups}
                          columns={groupColumns}
                          options={optionsGroup}
                          className={classes.muiDataTableUI}
                        />
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Box>
            )}
          {showRecipientDetails && (
            <ManageRecipientDetails
              recipientData={recipientData}
              onClose={handleCloseTemplate}
              onSave={handleSaveTemplate}
              saveProgress={saveProgress}
              isEdit={isEdit}
              dialogTitle={dialogTitle}
            />
          )}
          {showGroupDetails && (
            <ManageGroupDetails
              groupData={groupData}
              onClose={handleCloseGroups}
              onSave={handleSaveGroup}
              saveProgress={saveGroupProgress}
              isGroupEdit={isGroupEdit}
              dialogTitle={dialogTitle}
            />
          )}

          {showDeleteConfirmation && (
            <Grid container item xs={12} sx={{ mt: "19px" }}>
              <Grid xs={12} item>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  style={{ color: "#2CABEA" }}
                >
                  {isRecipientDelete ? `Delete Recipient` : `Delete Group`}
                </Typography>
                <Divider />
              </Grid>
              <Grid xs={12} item sx={{ mt: 4 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "12px 16px",
                    // height: "63px",
                    margin: "1rem",
                    background: "#FFFFFF",
                    border: "1px solid rgba(0, 50, 97, 0.85)",
                    boxShadow: "0px 12px 16px rgba(72, 85, 133, 0.15)",
                    borderRadius: "6px",
                    flexWrap: "wrap",
                  }}
                >
                  {isRecipientDelete && (
                    <>
                      <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                        {deleteContent && deleteContent.FirstName}
                      </span>
                      <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                        {deleteContent && deleteContent.LastName}
                      </span>
                      <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                        {deleteContent &&
                          formatPhoneNumber(deleteContent.PhoneNumber)}
                      </span>
                      <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                        {deleteContent && deleteContent.Email}
                      </span>
                      <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                        {deleteContent && deleteContent.DispatchPreference}
                      </span>
                      <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                        {deleteContent && deleteContent.IsActive
                          ? "Active"
                          : "Inactive"}
                      </span>
                    </>
                  )}
                  {!isRecipientDelete && (
                    <>
                      <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                        {deleteContent && deleteContent.Name}
                      </span>
                      <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                        {deleteContent && deleteContent.RecipientCount}
                      </span>
                      <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                        {deleteContent && deleteContent.Description}
                      </span>
                    </>
                  )}
                </div>
              </Grid>
              <Grid
                xs={12}
                item
                sx={{ mt: 6, display: "block", textAlign: "center" }}
              >
                <Typography
                  sx={{ fontWeight: 700, fontSize: "34px", lineHeight: "34px" }}
                >
                  Are you Sure ?
                </Typography>
                <Typography
                  sx={{
                    color: "#75787B",
                    fontSize: "16px",
                    lineHeight: "20px",
                    mt: "4px",
                  }}
                >
                  {isRecipientDelete
                    ? `This will permanently delete the recipient and make it unavailable for Dispatch creation.`
                    : `This will permanently delete the group and make it unavailable for Dispatch creation`}
                </Typography>
                <div style={{ marginTop: "83px", marginBottom: "80px" }}>
                  <Button autoFocus onClick={handleDeleteClose}>
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "#FA4858",
                      color: "#FFFFFF",
                      "&:hover": {
                        backgroundColor: "#FA4858",
                        opacity: 0.8,
                      },
                    }}
                    onClick={handleDeleteConfirm}
                  >
                    {showDeleteProgress && (
                      <CircularProgress size={25} sx={{ color: "#fff" }} />
                    )}
                    {!showDeleteProgress && isRecipientDelete
                      ? !showDeleteProgress && `Delete Recipient`
                      : !showDeleteProgress && `Delete Group`}
                  </Button>
                </div>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
      {/* {showDeleteConfirmation && (
        <Dialog
          open={showDeleteConfirmation}
          onClose={handleDeleteClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Are you Sure ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`${deleteContent.FirstName} | 
            ${deleteContent.LastName} | 
            ${deleteContent.PhoneNumber} | 
            ${deleteContent.Email} | 
            ${deleteContent.IsActive ? "Active" : "Inactive"} 
            `}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleDeleteClose}>
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#FA4858",
                color: "#FFFFFF",
                // width: "12rem",
                "&:hover": {
                  backgroundColor: "#FA4858",
                  opacity: 0.8,
                },
              }}
              onClick={handleDeleteConfirm}
            >
              {showDeleteProgress && (
                <CircularProgress size={25} sx={{ color: "#fff" }} />
              )}
              {!showDeleteProgress && `Confirm`}
            </Button>
          </DialogActions>
        </Dialog>
      )} */}
    </>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    alignLeftRight: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    paper: {
      maxWidth: "100% !important",
      // width: "1080px !important",
      height: "746px",
      overflow: "hidden !important",
    },
    active: {
      color: "#05CD99",
    },
    inactive: {
      color: "#0D1624",
    },
    muiDataTableUI: {
      borderRadius: "10px 10px 0px 0px !important",

      "& .tss-r6c9w6-MUIDataTable-responsiveStacked": {
        borderRadius: "0",
      },

      "& .MuiTableCell-head": {
        backgroundColor: "#11315E",
        color: "#FFFFFF !important",
        padding: "0.5rem",
        whiteSpace: "nowrap",
        // textAlign: "center",

        "& .Mui-active": {
          color: "#FFFFFF",
        },

        "& .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon":
          {
            color: "#FFFFFF",
          },

        "& .tss-bd2lw8-MUIDataTableHeadCell-sortActive": {
          color: "#FFFFFF",
        },

        "& .css-hyum1k-MuiToolbar-root": {
          backgroundColor: "#11315E !important",
        },

        "& .MuiInput-root:before": {
          borderBottom: "1px solid #FFFFFF !important",
        },

        "& .MuiInput-root:after": {
          borderBottom: "2px solid #FFFFFF !important",
        },
      },
      "& .tss-tlx3x1-MUIDataTableToolbar-root": {
        backgroundColor: "#11315E !important",
        color: "#FFFFFF",
        minHeight: "0.5rem !important",
        borderRadius: "10px 10px 0px 0px !important",
      },
      "& .tss-10rusft-MUIDataTableToolbar-icon": {
        color: "#FFFFFF",
      },
      // "& .MuiSvgIcon-root": {
      //   color: '#FFFFFF'
      // },
      "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root": {
        color: "#FFFFFF",
      },

      // "& .css-zylse7-MuiButtonBase-root-MuiIconButton-root": {
      //   backgroundColor: 'rgba(0, 0, 0, 0.54)',
      //   height: '1rem',
      //   width: '1rem',
      //   padding: '0.8rem'
      // },

      "& .tss-1ufdzki-MUIDataTableSearch-main > .MuiSvgIcon-root": {
        color: "#ffffff",
      },

      "& .tss-1ufdzki-MUIDataTableSearch-main > .MuiButtonBase-root": {
        color: "#ffffff",
      },

      "& .MuiInput-root": {
        color: "#ffffff",
      },

      "& .MuiInput-root:before": {
        borderBottom: "1px solid #FFFFFF !important",
      },

      "& .MuiInput-root:after": {
        borderBottom: "2px solid #FFFFFF !important",
      },
      // "& .tss-1ufdzki-MUIDataTableSearch-main > .MuiFormControl-root > .MuiInput-root:before":
      //   {
      //     borderBottom: "1px solid #FFFFFF !important",
      //   },
      // "& .tss-1ufdzki-MUIDataTableSearch-main > .MuiFormControl-root > .MuiInput-root:after":
      //   {
      //     borderBottom: "2px solid #FFFFFF !important",
      //   },
      // "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root:before": {
      //   borderBottom: '1px solid #FFFFFF !important'
      // },
      // "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root:after ": {
      //   borderBottom: '2px solid #FFFFFF !important'
      // }
    },
  })
);

import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useFormik } from "formik";
import * as yup from "yup";
import useAPIError from "../../common/hooks/useAPIError";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IMaskInput } from "react-imask";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";

// import Notification from "../common/Notifications";
import Stack from "@mui/material/Stack";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Divider,
  IconButton,
  CircularProgress,
  Input,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import Paper from '@mui/material/Paper';
import PropTypes from "prop-types";
import { formatPhoneNumber } from "../../common/helpers";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ManageRecipientDetails = ({
  title,
  recipientData,
  onClose,
  onSave,
  saveProgress,
  isEdit,
  dialogTitle,
}) => {
  const { groups } = useSelector((state) => ({
    groups: state.recipientReducer.groups || [],
  }));
  const { showNotification } = useAPIError();
  const [selectedGroupValue, setSelectedGroupValue] = useState(
    Object.keys(recipientData).length > 0
      ? recipientData.GroupNames
        ? recipientData.GroupNames
        : []
      : []
  );
  const [selectedGroupInputValue, setSelectedGroupInputValue] = useState([]);

  const validationSchema = yup.object({
    FirstName: yup
      .string("Enter your First Name")
      .required("First Name is required")
      .matches(/^[a-zA-Z][a-zA-Z .,'-]*$/, "Invalid First Name"),
    LastName: yup
      .string("Enter your Last Name")
      .required("Last Name is required")
      .matches(/^[a-zA-Z][a-zA-Z .,'-]*$/, "Invalid Last Name"),
    Email: yup
      .string("Enter your Email Address")
      .email("Enter valid email address"),
      // .required("Email is required"),
    CountryCode: yup
      .string()
      .matches(/^\+?\d+$/, "Digits and + allowed in Countrycode")
      .required("Country Code is required"),
    MobileNumber: yup
      .string()
      // .matches(/^[A-Za-z0-9_.]+$/, "Must be only digits and be between 5 and 10 characters")
      .min(5, "Must be at least 5 digits")
      .max(14, "Must be no more than 10 digits")
      .required("Mobile Number is required"),
    Country: yup.string("Enter Country"),
    City: yup.string("Enter City"),
    RecipientStatus: yup
      .string("Enter Recipient Status")
      .required("Recipient Status is required"),
    // TimeZone: yup.string("Enter Time Zone"),
    NotificationPreference: yup
      .string("Enter Notification Preference")
      .required("Notification Preference is required"),
  });

  const formik = useFormik({
    initialValues: {
      FirstName:
        (Object.keys(recipientData).length > 0 && recipientData.FirstName) ||
        "",
      LastName:
        (Object.keys(recipientData).length > 0 && recipientData.LastName) || "",
      Email:
        (Object.keys(recipientData).length > 0 && recipientData.Email) || "",
      CountryCode:
        (Object.keys(recipientData).length > 0 && recipientData.CountryCode) ||
        "",
      MobileNumber:
        (Object.keys(recipientData).length > 0 && recipientData.PhoneNumber) ||
        "",
      // PhoneNumber: recipientData.PhoneNumber || "",
      // RecipientId: recipientData.RecipientId || "",
      Country:
        (Object.keys(recipientData).length > 0 && recipientData.Country) || "",
      City: (Object.keys(recipientData).length > 0 && recipientData.City) || "",
      RecipientStatus:
        Object.keys(recipientData).length > 0
          ? recipientData.IsActive
            ? "Active"
            : "Inactive" || ""
          : "",
      TimeZone:
        Object.keys(recipientData).length > 0 ? recipientData.TimeZone : "",
      NotificationPreference:
        (Object.keys(recipientData).length > 0 &&
          recipientData.DispatchPreference) ||
        "",
      // groups: recipientData.groups || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      !saveProgress && onSave(values, selectedGroupValue);
      !saveProgress && resetForm({ values: "" });
    },
  });

  /* Used to add box shadow to Autocomplete when it's open */
  const AutocompletePaper = (props) => {
    return <Paper elevation={8} style={{ border: "1px solid black" }} {...props} />;
  };

  const status = ["Active", "Inactive"];
  const NotificationPreference = ["Text", "Voice"];
  const TimeZone = ["EST", "CST", "MST", "PST"];

  return (
    <Grid container spacing={2} mt={1} p={1}>
      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            style={{ color: "#2CABEA" }}
          >
            {dialogTitle}
          </Typography>
          <Divider />
          <Grid container spacing={3} mt={2}>
            <Grid item xs={3}>
              <Typography>First Name<span style={{ color: "#FA4858" }}>*</span></Typography>
              <TextField
                fullWidth
                id="FirstName"
                name="FirstName"
                placeholder="Enter First Name"
                variant="outlined"
                size="small"
                margin="dense"
                // disabled={!isEdit}
                inputProps={{
                  readOnly: !isEdit,
                }}
                value={formik.values.FirstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.FirstName && Boolean(formik.errors.FirstName)
                }
              />
              <Typography variant="overline" color="error">
                {formik.touched.FirstName && formik.errors.FirstName}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Last Name<span style={{ color: "#FA4858" }}>*</span></Typography>
              <TextField
                fullWidth
                id="LastName"
                name="LastName"
                placeholder="Enter Last Name"
                variant="outlined"
                size="small"
                margin="dense"
                // disabled={!isEdit}
                inputProps={{
                  readOnly: !isEdit,
                }}
                value={formik.values.LastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.LastName && Boolean(formik.errors.LastName)
                }
              />
              <Typography variant="overline" color="error">
                {formik.touched.LastName && formik.errors.LastName}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>Mobile Number<span style={{ color: "#FA4858" }}>*</span></Typography>
              <div style={{ display: "flex" }}>
                <div style={{ width: "60px" }}>
                  <TextField
                    fullWidth
                    id="CountryCode"
                    name="CountryCode"
                    placeholder="+ #"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    // disabled={!isEdit}
                    inputProps={{
                      readOnly: !isEdit
                    }}
                    value={formik.values.CountryCode}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.CountryCode &&
                      Boolean(formik.errors.CountryCode)
                    }
                    // sx={{ width: "40%" }}
                  />
                  <Typography variant="overline" color="error">
                    {formik.touched.CountryCode && formik.errors.CountryCode}
                  </Typography>
                </div>
                <div style={{ flexGrow: 3, marginLeft: "0.5rem" }}>
                  <TextField
                    fullWidth
                    id="MobileNumber"
                    name="MobileNumber"
                    // placeholder="(###) ###-####"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    // disabled={!isEdit}
                    inputProps={{
                      readOnly: !isEdit,
                      maxLength: 11,
                    }}
                    value={formatPhoneNumber(formik.values.MobileNumber)}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.MobileNumber &&
                      Boolean(formik.errors.MobileNumber)
                    }
                    // sx={{ width: "60%" }}
                  />
                  <Typography variant="overline" color="error">
                    {formik.touched.MobileNumber && formik.errors.MobileNumber}
                  </Typography>
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={3}>
              <Typography>Phone Number</Typography>
              <TextField
                fullWidth
                id="PhoneNumber"
                name="PhoneNumber"
                variant="outlined"
                size="small"
                margin="dense"
                disabled={!isEdit}
                value={formik.values.PhoneNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.PhoneNumber &&
                  Boolean(formik.errors.PhoneNumber)
                }
                inputProps={{
                  maxLength: 10
                }}
              />
              <Typography variant="overline" color="error">
                {formik.touched.PhoneNumber && formik.errors.PhoneNumber}
              </Typography>
            </Grid> */}
            {/* <Grid item xs={3}>
              <Typography>Recipient Id</Typography>
              <TextField
                fullWidth
                id="RecipientId"
                name="RecipientId"
                variant="outlined"
                size="small"
                margin="dense"
                disabled={!isEdit}
                value={formik.values.RecipientId}
                onChange={formik.handleChange}
                error={
                  formik.touched.RecipientId &&
                  Boolean(formik.errors.RecipientId)
                }
              />
              <Typography variant="overline" color="error">
                {formik.touched.RecipientId && formik.errors.RecipientId}
              </Typography>
            </Grid> */}
            <Grid item xs={3}>
              <Typography>Email Address</Typography>
              <TextField
                fullWidth
                id="Email"
                name="Email"
                placeholder="Enter Email"
                variant="outlined"
                size="small"
                margin="dense"
                // disabled={!isEdit}
                inputProps={{
                  readOnly: !isEdit,
                }}
                value={formik.values.Email}
                onChange={formik.handleChange}
                error={formik.touched.Email && Boolean(formik.errors.Email)}
              />
              <Typography variant="overline" color="error">
                {formik.touched.Email && formik.errors.Email}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Country</Typography>
              <TextField
                fullWidth
                id="Country"
                name="Country"
                placeholder="Enter Country"
                variant="outlined"
                size="small"
                margin="dense"
                // disabled={!isEdit}
                inputProps={{
                  readOnly: !isEdit,
                }}
                value={formik.values.Country}
                onChange={formik.handleChange}
                error={formik.touched.Country && Boolean(formik.errors.Country)}
              />
              <Typography variant="overline" color="error">
                {formik.touched.Country && formik.errors.Country}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>City</Typography>
              <TextField
                fullWidth
                id="City"
                name="City"
                placeholder="Enter City"
                variant="outlined"
                size="small"
                margin="dense"
                // disabled={!isEdit}
                inputProps={{
                  readOnly: !isEdit,
                }}
                value={formik.values.City}
                onChange={formik.handleChange}
                error={formik.touched.City && Boolean(formik.errors.City)}
              />
              <Typography variant="overline" color="error">
                {formik.touched.City && formik.errors.City}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Recipient Status<span style={{ color: "#FA4858" }}>*</span></Typography>
              <Select
                fullWidth
                displayEmpty
                id="RecipientStatus"
                name="RecipientStatus"
                variant="outlined"
                size="small"
                margin="dense"
                // disabled={!isEdit}
                inputProps={{
                  readOnly: !isEdit,
                }}
                value={formik.values.RecipientStatus}
                onChange={formik.handleChange}
                error={
                  formik.touched.RecipientStatus &&
                  Boolean(formik.errors.RecipientStatus)
                }
              >
                <MenuItem disabled value="">Select Status</MenuItem>
                {status.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="overline" color="error">
                {formik.touched.RecipientStatus &&
                  formik.errors.RecipientStatus}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Time Zone</Typography>
              <Select
                fullWidth
                variant="outlined"
                size="small"
                margin="dense"
                value={formik.values.TimeZone}
                onChange={formik.handleChange}
                displayEmpty
                id="TimeZone"
                name="TimeZone"
                // disabled={!isEdit}
                error={
                  formik.touched.TimeZone && Boolean(formik.errors.TimeZone)
                }
                inputProps={{
                  readOnly: !isEdit,
                }}
              >
                <MenuItem value="">Select Time Zone</MenuItem>
                {TimeZone.map((zone) => (
                  <MenuItem key={zone} value={zone}>
                    {zone}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="overline" color="error">
                {formik.touched.TimeZone && formik.errors.TimeZone}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography>Notification Preference<span style={{ color: "#FA4858" }}>*</span></Typography>
              <Select
                fullWidth
                variant="outlined"
                size="small"
                margin="dense"
                value={formik.values.NotificationPreference}
                onChange={formik.handleChange}
                displayEmpty
                id="NotificationPreference"
                name="NotificationPreference"
                // label="Role"
                // disabled={!isEdit}
                error={
                  formik.touched.NotificationPreference &&
                  Boolean(formik.errors.NotificationPreference)
                }
                inputProps={{
                  readOnly: !isEdit,
                }}
              >
                <MenuItem disabled value="">Select Preference</MenuItem>
                {NotificationPreference.map((preference) => (
                  <MenuItem key={preference} value={preference}>
                    {preference}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="overline" color="error">
                {formik.touched.NotificationPreference &&
                  formik.errors.NotificationPreference}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>Groups</Typography>
              <Autocomplete
                multiple
                // disabled={!isEdit}
                readOnly={!isEdit}
                value={selectedGroupValue}
                onChange={(event, newValue) => {
                  setSelectedGroupValue(newValue);
                }}
                inputValue={selectedGroupInputValue}
                onInputChange={(event, newInputValue) => {
                  setSelectedGroupInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                // defaultValue={[groups[0]]}
                options={groups.map((group) => group.Name)}
                PaperComponent={AutocompletePaper}
                renderInput={(params) => (
                  <TextField {...params} label="" variant="outlined" />
                )}
              />
              <Typography variant="overline" color="error">
                {formik.touched.groups && formik.errors.groups}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid mt={6} item xs={12}>
              <Divider />
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              mt={5}
            >
              <Stack spacing={2} direction="row">
                <Button
                  sx={{
                    color: "#003261",
                    width: "12rem",
                  }}
                  color="primary"
                  fullWidth
                  variant="outlined"
                  onClick={onClose}
                >
                  {isEdit ? "Cancel" : "Back"}
                </Button>

                {isEdit && (
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    sx={{
                      backgroundColor: "#8BD1F3",
                      color: "#003261",
                      width: "15rem",
                      "&:hover": {
                        backgroundColor: "#8BD1F3",
                        opacity: 0.8,
                      },
                    }}
                    disabled={!isEdit}
                  >
                    {saveProgress && (
                      <CircularProgress size={25} sx={{ color: "#fff" }} />
                    )}
                    {!saveProgress
                      ? Object.keys(recipientData).length > 0
                        ? !saveProgress && `Save Changes`
                        : !saveProgress && `Save & Create Recipient`
                      : ""}
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    active: {
      color: "#05CD99",
    },
    inactive: {
      color: "#0D1624",
    },
  })
);

export default ManageRecipientDetails;

import React, { useEffect } from "react";
import Router from "./Router";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";
import GroupIcon from "@mui/icons-material/Group";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SendIcon from "@mui/icons-material/Send";
import HomeIcon from "@mui/icons-material/Home";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import DispatchDialog from "./components/DispatchDialog";
import ManageUsersDialog from "./components/ManageUsers/ManageUsersDialog";
import ManageTemplatesDialog from "./components/ManageTemplates/ManageTemplatesDialog";
import ManageRecipientDialog from "./components/ManageRecipient/ManageRecipientDialog";
import LogoMenu from "./assets/images/logo-menu.png";
import Logo from "./assets/images/logo.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import LogoutIcon from "@mui/icons-material/Logout";
import IdleTimerDialog from "./components/common/SessionTimeOut/IdleTimerDialog";
import store from "./redux/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useAuth from "./hooks/useAuth";
import Link from "@mui/material/Link";
import { setRoles } from "./redux/actions/loginActions";
import moment from "moment";
import parse from "html-react-parser";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#8BD1F3",
  },

  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#8BD1F3",
    color: '#fff',
    boxShadow: theme.shadows[0],
    fontSize: 15,
  },
}));


const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(["width", "margin"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const FooterLink = styled(Link)(({ theme }) => ({
  root: {
    "&.MuiTypography-colorPrimary": {
      color: "#C4C4C4",
    },
  },
}));

export default function App(props) {
  const { setAuth } = useAuth();
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [isDialogopen, setisDialogOpen] = React.useState(false);
  const [usersOpen, setUsersOpen] = React.useState(false);
  const [openUserMenu, setOpenUserMenu] = React.useState(false);
  const [templateOpen, setTemplateOpen] = React.useState(false);
  const [recipientOpen, setRecipientOpen] = React.useState(false);
  const [activeNav, setActiveNav] = React.useState("Dashboard");
  const [userName, setUserName] = React.useState("");
  const anchorRef = React.useRef(null);
  const [isHoverManagerUser, setIsHoverManagerUser] = React.useState(false);
  const [isHoverMenuOne, setIsHoverMenuOne] = React.useState(false);
  const [isHoverMenuTwo, setIsHoverMenuTwo] = React.useState(false);


  const { role, result } = useSelector((state) => ({
    role: state.loginReducer.role || [],
    result: state.loginReducer.result || [],
  }));

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type === 1) {
        const accessToken = localStorage.getItem("authToken");
        if (accessToken) {
          const ttlSpan = JSON.parse(localStorage.getItem("vRadLoginTimeSpan"));
          if (ttlSpan) {
            if (
              moment(ttlSpan.ttl).isBefore(
                moment(new Date()).format("DD/MM/YYYY hh:mm:ss")
              )
            ) {
              localStorage.removeItem("authToken");
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("FullName");
              localStorage.removeItem("Email");
              localStorage.removeItem("vRadLoginTimeSpan");
              navigate("/login");
            } else {
              dispatch(setRoles(accessToken)).then(() => {
                const roles = [2001, 1984];
                setAuth({
                  roles: roles,
                  accessToken: accessToken,
                });
                navigate("/dashboard");
              });
            }
          } else {
            dispatch(setRoles(accessToken)).then(() => {
              const roles = [2001, 1984];
              setAuth({
                roles: roles,
                accessToken: accessToken,
              });
              navigate("/dashboard");
            });
          }
        }
      }
    }
    // if (Object.keys(store.getState().loginReducer.result).length > 0) {
    //   setUserName(store.getState().loginReducer.result.FullName);
    // }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDialog = () => {
    setisDialogOpen(!isDialogopen);
  };

  const handleManageUserDialog = () => {
    setUsersOpen(!usersOpen);
  };

  const handleManageTemplateDialog = () => {
    setTemplateOpen(!templateOpen);
  };

  const handleManageRecipientDialog = (event) => {
    setRecipientOpen(!recipientOpen);
  };


  const handleMouseOver = () => {
    setIsHoverManagerUser(true);
  };

  const handleMouseOut = () => {
    setIsHoverManagerUser(false);
  };

  const handleMUMouseOver = () => {
    setIsHoverMenuOne(true);
  };

  const handleMUMouseOut = () => {
    setIsHoverMenuOne(false);
  };

  const handleMenuTwoOver = () => {
    setIsHoverMenuTwo(true);
  };

  const handleMenuTwoOut = () => {
    setIsHoverMenuTwo(false);
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(!openDrawer);
  };

  // "Reports", "Admin"
  const pages = ["Dashboard"];
  const handleUserMenu = () => {
    setOpenUserMenu(!openUserMenu);
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("FullName");
    localStorage.removeItem("Email");
    localStorage.removeItem("vRadLoginTimeSpan");
    navigate("/login");
    window.location.reload(false);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {location.pathname !== "/register" &&
          location.pathname !== "/login" &&
          !location.pathname.includes("resetpassword") &&
          location.pathname !== "/forgotpassword" && (
            <Drawer
              variant="permanent"
              anchor="left"
              open={openDrawer}
              sx={{ position: "absolute" }}
              PaperProps={{
                sx: {
                  boxShadow:
                    "0px 4px 8px rgba(0, 0, 0, 0.08), 0px 4px 32px rgba(0, 0, 0, 0.08)",
                  borderRadius: "0px 24px 24px 0px",
                },
              }}
            >
              <DrawerHeader sx={{ justifyContent: "flex-start" }}>
                {openDrawer && (
                  <IconButton
                    color="dark"
                    aria-label="close drawer"
                    onClick={handleDrawerOpen}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
                {!openDrawer && (
                  <IconButton
                    color="dark"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
              </DrawerHeader>
              {/* <Divider /> */}
              <div
                style={{
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "70px",
                }}
              >
                {!openDrawer && (
                  <img
                    src={`${LogoMenu}`}
                    alt="VRad Communcatons"
                    width="52"
                    height="50"
                  />
                )}

                {openDrawer && (
                  <>
                    <img
                      src={`${Logo}`}
                      alt="VRad Communcatons"
                      width="120"
                      height="30"
                    />
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      sx={{ fontSize: "10px", marginTop: "10px" }}
                    >
                      Communications Center
                    </Typography>
                  </>
                )}
              </div>
              {Object.keys(store.getState().loginReducer.role).length > 0 &&
                store
                  .getState()
                  .loginReducer.role.dispatchModule.includes("newDispatch") && (
                  <List sx={{ marginTop: "5px" }}>
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: openDrawer ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={handleDialog}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            // mr: openDrawer ? 3 : "auto",
                            justifyContent: "center",
                            color: "#003261",
                            backgroundColor: "#8BD1F3",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: !openDrawer ? "10px" : 0,
                            borderBottomRightRadius: !openDrawer ? "10px" : 0,
                            borderBottomLeftRadius: "10px",
                            padding: "10px",
                          }}
                        >
                          <SendIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="New Dispatch"
                          sx={{
                            opacity: openDrawer ? 1 : 0,
                            backgroundColor: openDrawer ? "#8BD1F3" : "",
                            padding: openDrawer ? "11.5px" : 0,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                            borderBottomLeftRadius: 0,
                          }}
                          primaryTypographyProps={{
                            color: "#003261",
                            fontSize: "14px",
                            fontWeight: 700,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </List>
                )}
              <List sx={{ marginTop: "5px" }}>
                {pages.map((page) => {
                  return (
                    <ListItem
                      disablePadding
                      sx={{
                        display: "block",
                        borderRight:
                          activeNav === page ? "4px solid #8BD1F3" : 0,
                      }}
                    >
                      <ListItemButton
                        sx={{
                          // minHeight: 48,
                          justifyContent: openDrawer ? "initial" : "center",
                          // px: 2.5,
                          p: 0,
                          pl: openDrawer ? 2 : 1,
                        }}
                        onClick={() => {
                          navigate(`/${page}`);
                          setActiveNav(page);
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            // mr: openDrawer ? 3 : "auto",
                            justifyContent: "center",
                            color: activeNav === page ? "#2CABEA" : "#75787B",
                            // backgroundColor: "#8BD1F3",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: !openDrawer ? "10px" : 0,
                            borderBottomRightRadius: !openDrawer ? "10px" : 0,
                            borderBottomLeftRadius: "10px",
                            padding: "10px",
                          }}
                        >
                          {page === "Dashboard" && <HomeIcon />}
                          {page === "Reports" && <AssessmentOutlinedIcon />}
                          {page === "Admin" && <AccountCircleOutlinedIcon />}
                        </ListItemIcon>
                        <ListItemText
                          primary={page}
                          sx={{
                            opacity: openDrawer ? 1 : 0,
                            padding: openDrawer ? "10px" : 0,
                            color: activeNav === page ? "#2CABEA" : "#75787B",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
              <Divider m={2}></Divider>
              <List sx={{ marginTop: "5px" }}>
                {Object.keys(store.getState().loginReducer.role).length > 0 &&
                  store
                    .getState()
                    .loginReducer.role.recipient.includes("recipient") && (
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        sx={{
                          minHeight: 48,
                          justifyContent: openDrawer ? "initial" : "center",
                          px: 2.5,
                          borderRight: 0,
                        }}
                        onClick={handleManageRecipientDialog}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            // mr: openDrawer ? 3 : "auto",
                            justifyContent: "center",
                            color: isHoverManagerUser
                              ? "#8BD1F3"
                              : "rgba(0, 50, 97, 0.2)",
                            // backgroundColor: "#8BD1F3",
                            border: "2px solid",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: !openDrawer ? "10px" : 0,
                            borderBottomRightRadius: !openDrawer ? "10px" : 0,
                            borderBottomLeftRadius: "10px",
                            padding: "10px",
                            borderRight: openDrawer ? 0 : "",
                            //   "&:hover": {
                            //     color: "#8BD1F3"
                            // }
                          }}
                        >
                          <LightTooltip
                            title="Manage Recipient"
                            placement="right"
                            arrow
                          >
                            <GroupIcon
                              sx={{
                                color: isHoverManagerUser
                                  ? "#8BD1F3"
                                  : "#003261",
                              }}
                            />
                          </LightTooltip>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            store.getState().loginReducer.role.role ===
                            "Dispatch"
                              ? "View Recipient"
                              : "Manage Recipient"
                          }
                          sx={{
                            opacity: openDrawer ? 1 : 0,
                            // backgroundColor: "#8BD1F3",
                            border: "2px solid",
                            borderLeft: 0,
                            color: isHoverManagerUser
                              ? "#8BD1F3"
                              : "rgba(0, 50, 97, 0.2)",
                            padding: openDrawer ? "11.5px" : 0,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                            borderBottomLeftRadius: 0,
                            //   "&:hover": {
                            //     color: "#8BD1F3"
                            // }
                          }}
                          primaryTypographyProps={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: isHoverManagerUser ? "#8BD1F3" : "#003261",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )}
                {Object.keys(store.getState().loginReducer.role).length > 0 &&
                  store
                    .getState()
                    .loginReducer.role.userModule.includes("user") && (
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        onMouseOver={handleMUMouseOver}
                        onMouseOut={handleMUMouseOut}
                        sx={{
                          minHeight: 48,
                          justifyContent: openDrawer ? "initial" : "center",
                          px: 2.5,
                          borderRight: 0,
                        }}
                        onClick={handleManageUserDialog}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            // mr: openDrawer ? 3 : "auto",
                            justifyContent: "center",
                            color: isHoverMenuOne
                              ? "#8BD1F3"
                              : "rgba(0, 50, 97, 0.2)",
                            // backgroundColor: "#8BD1F3",
                            border: "2px solid",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: !openDrawer ? "10px" : 0,
                            borderBottomRightRadius: !openDrawer ? "10px" : 0,
                            borderBottomLeftRadius: "10px",
                            padding: "10px",
                            borderRight: openDrawer ? 0 : "",
                            //   "&:hover": {
                            //     color: "#8BD1F3"
                            // }
                          }}
                        >
                          <LightTooltip
                            title="Manage Users"
                            placement="right"
                            arrow
                          >
                            <ManageAccountsIcon
                              sx={{
                                color: isHoverMenuOne ? "#8BD1F3" : "#003261",
                              }}
                            />
                          </LightTooltip>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            store.getState().loginReducer.role.role !==
                              "Dispatch" &&
                            store.getState().loginReducer.role.role !==
                              "Reporting"
                              ? "Manage Users"
                              : "View Users"
                          }
                          sx={{
                            opacity: openDrawer ? 1 : 0,
                            // backgroundColor: "#8BD1F3",
                            border: "2px solid",
                            borderLeft: 0,
                            color: isHoverMenuOne
                              ? "#8BD1F3"
                              : "rgba(0, 50, 97, 0.2)",
                            padding: openDrawer ? "11.5px" : 0,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                            borderBottomLeftRadius: 0,
                            //   "&:hover": {
                            //     color: "#8BD1F3"
                            // }
                          }}
                          primaryTypographyProps={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: isHoverMenuOne ? "#8BD1F3" : "#003261",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )}
                {Object.keys(store.getState().loginReducer.role).length > 0 &&
                  store
                    .getState()
                    .loginReducer.role.template.includes("template") && (
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        onMouseOver={handleMenuTwoOver}
                        onMouseOut={handleMenuTwoOut}
                        sx={{
                          minHeight: 48,
                          justifyContent: openDrawer ? "initial" : "center",
                          px: 2.5,
                          borderRight: 0,
                        }}
                        onClick={handleManageTemplateDialog}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            // mr: openDrawer ? 3 : "auto",
                            justifyContent: "center",
                            color: isHoverMenuTwo
                              ? "#8BD1F3"
                              : "rgba(0, 50, 97, 0.2)",
                            // backgroundColor: "#8BD1F3",
                            border: "2px solid",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: !openDrawer ? "10px" : 0,
                            borderBottomRightRadius: !openDrawer ? "10px" : 0,
                            borderBottomLeftRadius: "10px",
                            padding: "10px",
                            borderRight: openDrawer ? 0 : "",
                            //   "&:hover": {
                            //     color: "#8BD1F3"
                            // }
                          }}
                        >
                          <LightTooltip
                            title="Manage Template"
                            placement="right"
                            arrow
                          >
                            <NoteAltOutlinedIcon
                              sx={{
                                color: isHoverMenuTwo ? "#8BD1F3" : "#003261",
                              }}
                            />
                          </LightTooltip>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            store.getState().loginReducer.role.role ===
                            "Dispatch"
                              ? "View Template"
                              : "Manage Template"
                          }
                          sx={{
                            opacity: openDrawer ? 1 : 0,
                            // backgroundColor: "#8BD1F3",
                            border: "2px solid",
                            borderLeft: 0,
                            color: isHoverMenuTwo
                              ? "#8BD1F3"
                              : "rgba(0, 50, 97, 0.2)",
                            padding: openDrawer ? "11.5px" : 0,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                            borderBottomLeftRadius: 0,
                            //   "&:hover": {
                            //     color: "#8BD1F3"
                            // }
                          }}
                          primaryTypographyProps={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: isHoverMenuTwo ? "#8BD1F3" : "#003261",
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )}
              </List>
              <List sx={{ marginTop: "5px" }}>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      justifyContent: openDrawer ? "initial" : "center",
                      p: 0,
                      pl: openDrawer ? 2 : 1,
                      display: "flex",
                    }}
                    onClick={handleUserMenu}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: "center",
                        color: "#003261",
                        padding: "10px",
                      }}
                    >
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <Tooltip
                      title={parse(
                        `<p>${localStorage.getItem(
                          "FullName"
                        )}</p><p>${localStorage.getItem("Email")}</p>`
                      )}
                      placement="top"
                    >
                      <ListItemText
                        primary={
                          localStorage.getItem("FullName")
                            ? `${localStorage
                                .getItem("FullName")
                                .substring(0, 15)}...`
                            : ""
                        }
                        secondary={
                          localStorage.getItem("Email")
                            ? `${localStorage
                                .getItem("Email")
                                .substring(0, 15)}...`
                            : ""
                        }
                        primaryTypographyProps={{ fontSize: "0.875rem" }}
                        sx={{
                          opacity: openDrawer ? 1 : 0,
                          padding: openDrawer ? "10px" : 0,
                          color: "#003261",
                        }}
                      />
                    </Tooltip>
                    <MoreHorizIcon
                      sx={{
                        minWidth: 0,
                        display: openDrawer ? "inherit" : "none",
                        // justifyContent: "center",
                        color: "#003261",
                        marginRight: "10px",
                      }}
                    />
                  </ListItemButton>
                  <Collapse in={openUserMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{
                          pl: openDrawer ? 4 : 2,
                        }}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </ListItem>
              </List>
              {/* <Divider /> */}
              <Box
                sx={{
                  bgcolor: "#f5f5f5",
                  whiteSpace: "normal",
                  display: openDrawer ? "flex" : "none",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "auto",
                  padding: "1em",
                }}
                component="footer"
              >
                <Typography
                  align="center"
                  color="#53565a"
                  variant="body2"
                  sx={{ padding: "0.5em " }}
                >
                  Version: 22.3.0.132
                </Typography>
                <Typography
                  align="center"
                  color="#53565a"
                  variant="body3"
                  component="p"
                >
                  {/* Protected by one or more issued or pending patents, listed at
                  &nbsp; */}
                  <FooterLink
                    color="primary"
                    href="https://www.vrad.com/terms-of-use/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </FooterLink>
                </Typography>
              </Box>
            </Drawer>
          )}
        <Box
          component="main"
          sx={{
            width: "100%",
            marginLeft:
              location.pathname !== "/register" &&
              location.pathname !== "/login" &&
              !location.pathname.includes("resetpassword") &&
              location.pathname !== "/forgotpassword"
                ? "3.2rem"
                : 0,
          }}
        >
          <Router />
        </Box>
        {/* <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Footer
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Something here to give the footer a purpose!
        </Typography>
      </Box> */}
      </Box>
      {isDialogopen && (
        <DispatchDialog onclose={handleDialog} open={isDialogopen} />
      )}
      {usersOpen && (
        <ManageUsersDialog onclose={handleManageUserDialog} open={usersOpen} />
      )}
      {templateOpen && (
        <ManageTemplatesDialog
          onclose={handleManageTemplateDialog}
          open={templateOpen}
        />
      )}
      {recipientOpen && (
        <ManageRecipientDialog
          onclose={handleManageRecipientDialog}
          open={recipientOpen}
        />
      )}
      {location.pathname !== "/register" &&
        location.pathname !== "/login" &&
        !location.pathname.includes("resetpassword") &&
        location.pathname !== "/forgotpassword" && <IdleTimerDialog />}
    </>
  );
}

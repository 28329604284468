import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import dispatchReducer from "./dispatchReducer";
import messageReducer from "./messageReducer";
import usersReducer from "./usersReducer";
import templateReducer from "./templateReducer";
import recipientReducer from "./recipientReducer";


export default combineReducers({
  loginReducer,
  dispatchReducer,
  messageReducer,
  usersReducer,
  templateReducer,
  recipientReducer
});

/* eslint-disable import/no-anonymous-default-export */
import * as Actions from "../constants/constants";

const initialState = {
  recipients: [],
  recipient: [],
  groups: [],
  group: [],
  NewAndEditRecipient: [],
  NewAndEditGroup: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_RECIPIENTS:
      return { ...state, recipients: action.payload };
    case Actions.FETCH_GROUPS:
      return { ...state, groups: action.payload };
    case Actions.FETCH_RECIPIENT:
      return { ...state, recipient: action.payload };
    case Actions.PUT_RECIPIENT:
    case Actions.POST_RECIPIENT:
      return { ...state, NewAndEditRecipient: action.payload };
    case Actions.FETCH_GROUP:
      return { ...state, group: action.payload };
    case Actions.PUT_GROUP:
    case Actions.POST_GROUP:
      return { ...state, NewAndEditGroup: action.payload };
    default:
      return state;
  }
};

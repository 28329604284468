import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useFormik } from "formik";
import * as yup from "yup";
import useAPIError from "../../common/hooks/useAPIError";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// import Notification from "../common/Notifications";
import Stack from "@mui/material/Stack";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Divider,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchUsers,
  fetchRoles,
  postUser,
  deleteUser,
  updateUser,
} from "../../redux/actions/userActions";
import moment from "moment";

const ManageTemplateDetails = ({
  templateData,
  onClose,
  onSave,
  saveProgress,
  isEdit,
  dialogTitle,
}) => {
  const validationSchema = yup.object({
    TemplateName: yup
      .string("Enter Template Name")
      .required("Template Name is required")
      .matches(/^[^\s]+(\s+[^\s]+)*$/, "Cannot start or end with a blank space")
      .max(80, "Template Name not exceed 80 characters"),
    TemplateMessage: yup
      .string("Enter Template Message")
      .required("Template Message is required")
      .max(480, "Template Message not exceed 480 characters"),
  });

  const formik = useFormik({
    initialValues: {
      TemplateName: templateData.Name || "",
      TemplateMessage: templateData.TemplateHtml || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      !saveProgress && onSave(values);
      resetForm({ values: "" });
    },
  });

  return (
    <Grid container spacing={2} mt={1} p={1}>
      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            style={{ color: "#2CABEA" }}
          >
            {dialogTitle}
          </Typography>
          <Divider />

          <Grid container spacing={3} mt={2}>
            <Grid item xs={6}>
              <Grid container spacing={3} pl={2} pr={4}>
                <Grid item xs={12}>
                  <Typography>Template Name</Typography>
                  <TextField
                    fullWidth
                    id="TemplateName"
                    name="TemplateName"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    // disabled={!isEdit}
                    inputProps={{
                      readOnly: !isEdit,
                    }}
                    value={formik.values.TemplateName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.TemplateName &&
                      Boolean(formik.errors.TemplateName)
                    }
                  />
                  <Typography variant="overline" color="error">
                    {formik.touched.TemplateName && formik.errors.TemplateName}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Template Message</Typography>
                  <TextField
                    fullWidth
                    id="TemplateMessage"
                    name="TemplateMessage"
                    rows={8}
                    multiline
                    inputProps={{
                      maxLength: 480,
                      style: {
                        height: "120px",
                      },
                      readOnly: !isEdit,
                    }}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    // disabled={!isEdit}
                    value={formik.values.TemplateMessage}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.TemplateMessage &&
                      Boolean(formik.errors.TemplateMessage)
                    }
                  />
                  <Typography variant="overline" color="error">
                    {formik.touched.TemplateMessage &&
                      formik.errors.TemplateMessage}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                height: "100%",
                border: "1px dashed #2CABEA",
                borderRadius: "1rem",
                padding: "1.875rem 1.375rem",
              }}
              // style={{ padding: "0.5rem" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 1rem",
                }}
              >
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ fontsize: "12px" }}
                >
                  Dispatch Message - PREVIEW
                </Typography>
              </div>
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  position: "relative",
                  padding: "0 1rem",
                }}
              >
                <TextField
                  rows={8}
                  multiline
                  aria-label="maximum height"
                  placeholder=""
                  value={formik.values.TemplateMessage}
                  disabled={true}
                  // onChange={(event) => {
                  //   setTemplateTextCount(event.target.value.length);
                  //   setTemplateText(event.target.value);
                  // }}
                  style={{ width: "100%", height: "190px" }}
                  inputProps={{
                    maxLength: 480,
                    style: {
                      height: "150px",
                    },
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 1rem",
                }}
              >
                <Typography
                  variant="body3"
                  align="center"
                  sx={{
                    fontsize: "12px",
                    padding: "0px 1px",
                    width: "230px",
                  }}
                >
                  Older phones may split messages over 160 characters into
                  multiple messages.
                </Typography>
                <span
                  style={{
                    color: "#2CABEA",
                    fontSize: "1rem",
                    width: "70px",
                  }}
                >
                  {formik.values.TemplateMessage.length}
                  <span
                    style={{
                      color: "#003261",
                      fontSize: "0.875rem",
                      marginLeft: "2px",
                    }}
                  >
                    / 480
                  </span>
                </span>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid mt={6} item xs={12}>
              <Divider />
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              mt={5}
            >
              <Stack spacing={2} direction="row">
                <Button
                  sx={{
                    color: "#003261",
                    width: "12rem",
                  }}
                  color="primary"
                  fullWidth
                  variant="outlined"
                  onClick={onClose}
                >
                  {isEdit ? "Cancel" : "Back"}
                </Button>

                {isEdit && (
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    sx={{
                      backgroundColor: "#8BD1F3",
                      color: "#003261",
                      width: "12rem",
                      "&:hover": {
                        backgroundColor: "#8BD1F3",
                        opacity: 0.8,
                      },
                    }}
                    disabled={!isEdit}
                  >
                    {saveProgress && (
                      <CircularProgress size={25} sx={{ color: "#fff" }} />
                    )}
                    {!saveProgress
                      ? Object.keys(templateData).length > 0
                        ? !saveProgress && `Save Changes`
                        : !saveProgress && `Save Template`
                      : ""}
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    active: {
      color: "#05CD99",
    },
    inactive: {
      color: "#0D1624",
    },
  })
);

export default ManageTemplateDetails;

import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import theme from "./theme";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { AuthProvider } from "./context/AuthProvider";
import APIErrorProvider from "./common/providers/APIErrorProvider";
import APIErrorNotification from './components/common/APIErrorNotification/APIErrorNotification'

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <AuthProvider>
            <APIErrorProvider>
              <React.StrictMode>
                <APIErrorNotification />
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </React.StrictMode>
            </APIErrorProvider>
          </AuthProvider>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

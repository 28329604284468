import React from "react";

const Admin = () => (
  <div
    style={{
      position: "absolute",
      left: "50%",
      top: "50%",
    }}
  >
    <h2>Page you are looking for is Under Progress</h2>
  </div>
);

export default Admin;

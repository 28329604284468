import * as React from "react";
import { Typography, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";
import MessageDetails from "./MessageDetails";
import PropTypes from "prop-types";
import Slide from "@mui/material/Slide";
import StatusReportsTable from "./StatusReportsTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StatusReportsDialog({ open, onclose }) {
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Dialog
      open={open}
      onClose={onclose}
      TransitionComponent={Transition}
      aria-describedby="Dialog"
      onBackdropClick="false"
      fullWidth
      maxWidth="lg"
      scroll="paper"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle style={{ backgroundColor: "#F4F7FE" }}>
        <div className={classes.alignLeftRight}>
          <Typography variant="subtitle1" color="textPrimary">
            Dispatch Details
          </Typography>
          <IconButton color="dark" aria-label="open drawer" onClick={onclose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
       <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Dispatch Details Navigation"
            >
              <Tab label="Status Report" {...a11yProps(0)} />
              <Tab label="Message Details" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <StatusReportsTable />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <MessageDetails />
          </TabPanel>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    alignLeftRight: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    paper: {
      maxWidth: "100% !important",
      // width: "1080px !important",
      height: "746px",
    },
  })
);

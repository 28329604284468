import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { Typography, Button, Grid, CircularProgress } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { refreshToken } from "../../../redux/actions/loginActions";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function IdleTimerDialog() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const timeout = 900000; // 15 min to call refresh token
  const [remaining, setRemaining] = useState(timeout);
  const [isIdle, setIsIdle] = useState(false);
  const [progress, setProgress] = useState(false);

  const handleOnActive = () => {
    setIsIdle(false);
  };
  const handleOnIdle = () => {
    setModalIsOpen(true);
    setIsIdle(true);
  };

  const { reset, pause, resume, getRemainingTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  const accessToken = localStorage.getItem("refreshToken");
  const UserName = localStorage.getItem("Email");

  const handleExtendTime = () => {
    let data = {
      Token: accessToken,
      UserName: UserName,
    };
    setProgress(true);
    dispatch(refreshToken(data))
      .then(() => {
        setProgress(false);
        setModalIsOpen(false);
      })
      .catch((error) => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("FullName");
        localStorage.removeItem("Email");
        navigate("/login");
        window.location.reload(false);
      });
  };

  useEffect(() => {
    setRemaining(getRemainingTime());
    setInterval(() => {
      setRemaining(getRemainingTime());
    }, 1000);
    // eslint-disable-next-line
  }, []);

  const REMINDER_TIME = 15000; // 15 Seconds

  useEffect(() => {
    const lastTime = setInterval(() => {
      if (modalIsOpen) {
        handleLogOut();
      }
    }, REMINDER_TIME);

    return () => clearInterval(lastTime); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  });

  const REFRESH_TIME = 600000; // 10 minutes refresh

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isIdle) {
        handleExtendTime();
      }
    }, REFRESH_TIME);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  const handleLogOut = () => {
    setModalIsOpen(false);
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("FullName");
    localStorage.removeItem("Email");
    reset();
    navigate("/login");
    window.location.reload(false);
  };
  return (
    <>
      <Dialog
        open={modalIsOpen}
        TransitionComponent={Transition}
        aria-describedby="Dialog"
        onBackdropClick={false}
        scroll="paper"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle style={{ backgroundColor: "#F4F7FE" }}>
          <div className={classes.alignLeftRight}>
            <Typography variant="subtitle1" color="textPrimary">
              Session Timeout
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} p={1}>
            <Grid item xs={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <WarningAmberIcon
                  style={{ fontSize: "4.5rem", color: "#f7a207" }}
                ></WarningAmberIcon>
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle2" color="textPrimary">
                Your session is about to expire due to inactivity. Please choose
                to either Conitnue the session or Sign Out below.
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                You will be signedOut automatically in{" "}
                <strong>15 seconds.</strong>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              color: "#003261",
              width: "10rem",
            }}
            color="primary"
            variant="outlined"
            onClick={handleLogOut}
          >
            {" "}
            Sign Out{" "}
          </Button>
          <Button
            sx={{
              backgroundColor: "#8BD1F3",
              color: "#003261",
              width: "10rem",
              "&:hover": {
                backgroundColor: "#8BD1F3",
                opacity: 0.8,
              },
            }}
            onClick={handleExtendTime}
            variant="contained"
          >
            {progress && <CircularProgress size={25} sx={{ color: "#fff" }} />}
            {!progress && `Continue Session`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    alignLeftRight: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  })
);

export default IdleTimerDialog;

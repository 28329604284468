/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

export const request = (url, method = "GET", payload, headers = {}) => {
  const token = localStorage.getItem("authToken");
  let config = {
    url: `${process.env.REACT_APP_API_BASE_URL}${url}`,
    method: method,
    data: payload,
    withCredentials: true,
    timeout: 30000, // get the values from process
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token ? `Bearer ${token}` : null,
      ...headers,
    },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((response) => {
        resolve(response.data);
        return response.data;
      })
      .catch((error) => {
        if (error.hasOwnProperty("code") && error.code === "ECONNABORTED") {
          reject("Error: Service Time out. Try again after sometime.");
          return error;
        } else if (error.hasOwnProperty("response")) {
          reject(
            `Error: ${error.response.data.Message.charAt(
              0
            ).toUpperCase()}${error.response.data.Message.slice(1)}`
          );
          return error;
        } else {
          reject(error);
          return error;
        }
      });
  });
};

export const authRequest = (url, method = "GET", payload, headers = {}) => {
  const token = localStorage.getItem("authToken");

  let config = {
    url: `${process.env.REACT_APP_AUTH_BASE_URL}${url}`,
    method: method,
    data: payload,
    withCredentials: true,
    timeout: 30000, // get the values from process
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token ? `Bearer ${token}` : null,
      ...headers,
    },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((response) => {
        if (response.data === "DOESNOTEXIST") {
          reject(
            "Unable to sign in. Please check your password and try again."
          );
          localStorage.removeItem("authToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("FullName");
          localStorage.removeItem("Email");
          return "Error";
        } else {
          resolve(response.data);
          return response.data;
        }
      })
      .catch((error) => {
        if (error.code === "ECONNABORTED") {
          reject("Error: Service Time out. Try again after sometime.");
          return error;
        } else if (error.hasOwnProperty("response")) {
          reject(
            `Error: ${error.response.data.Message.charAt(
              0
            ).toUpperCase()}${error.response.data.Message.slice(1)}`
          );
          return error;
        } else {
          reject(error);
          return error;
        }
      });
  });
};

import { authRequest, request } from "./request";
import {
  LOG_IN_SUCCESS,
  FETCH_ROLES,
  FETCH_NOTIFICATIONS,
} from "../constants/constants";
import { rolesList, parseJwt } from "../../common/helpers";
import moment from "moment";

export function postLogin(data) {
  return (dispatch) => {
    return authRequest("Auth/login", "POST", {
      Username: data.username,
      Password: data.password,
    }).then((response) => {
      dispatch({
        type: LOG_IN_SUCCESS,
        payload: response,
      });
      dispatch(setRoles(response.AuthToken));
      // let decodedToken = parseJwt(response.AuthToken);
      // dispatch({
      //   type: FETCH_ROLES,
      //   payload: rolesList(decodedToken.Role),
      // });
      const ttlSpan = JSON.parse(localStorage.getItem("vRadLoginTimeSpan"));
      if (!ttlSpan) {
        const data = {
          ttl: moment(new Date(), "DD-MM-YYYY hh:mm:ss")
            .add(5, "days")
            .format("DD/MM/YYYY hh:mm:ss"),
        };
        localStorage.setItem("vRadLoginTimeSpan", JSON.stringify(data));
      }
      localStorage.setItem("authToken", response.AuthToken);
      localStorage.setItem("refreshToken", response.RefreshToken);
      localStorage.setItem("FullName", response.FullName);
      localStorage.setItem("Email", response.Email);
    });
  };
}

const promise1 = new Promise((resolve, reject) => {
  resolve("Success!");
});

export function setRoles(token) {
  return (dispatch) => {
    return promise1.then((value) => {
      let decodedToken = parseJwt(token);
      dispatch({
        type: FETCH_ROLES,
        payload: rolesList(decodedToken.Role),
      });
    });
  };
}

export function refreshToken(payLoad) {
  return (dispatch) => {
    return authRequest("Auth/refresh-token", "POST", payLoad).then(
      (response) => {
        dispatch({
          type: LOG_IN_SUCCESS,
          payload: response.Data,
        });
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("FullName");
        localStorage.removeItem("Email");
        localStorage.setItem("authToken", response.AuthToken);
        localStorage.setItem("refreshToken", response.RefreshToken);
        localStorage.setItem("FullName", response.FullName);
        localStorage.setItem("Email", response.Email);
      }
    );
  };
}

export function fetchNotifications() {
  return (dispatch) => {
    return request("notification", "GET", {}).then((response) => {
      dispatch({
        type: FETCH_NOTIFICATIONS,
        payload: response.Data,
      });
    });
  };
}

export function getForgotPassword(email) {
  return (dispatch) => {
    return authRequest(`Users/ForgotPassword?userId=${email}`, "GET", {}).then(
      (response) => {}
    );
  };
}

export function postResetPassword(payload) {
  return (dispatch) => {
    return authRequest("Users/ChangePassword", "POST", payload).then(
      (response) => {}
    );
  };
}

import * as React from "react";
import { Typography, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";
import Slide from "@mui/material/Slide";
import StepHandler from "./NewDispatch/StepHandler";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DispatchDialog({ open, onclose }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onclose}
      TransitionComponent={Transition}
      aria-describedby="Dialog"
      onBackdropClick="false"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle style={{ backgroundColor: "#F4F7FE" }}>
        <div className={classes.alignLeftRight}>
          <Typography variant="subtitle1" color="textPrimary">
            New Dispatch
          </Typography>
          <IconButton color="dark" aria-label="open drawer" onClick={onclose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent sx={{ margin: 0, padding: 0 }}>
        <StepHandler onclose={onclose} />
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alignLeftRight: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    paper: {
      maxWidth: "1080px !important",
      // width: "1080px !important",
      height: "746px",
    },
  })
);

import * as React from "react";
import { Typography, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@mui/styles";
import Slide from "@mui/material/Slide";
import ManageUserTable from './ManageUserTable'
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DispatchDialog({ open, onclose }) {
  const classes = useStyles();
  const { role } = useSelector((state) => ({
    role: state.loginReducer.role
  }));

  return (
    <Dialog
      open={open}
      onClose={onclose}
      TransitionComponent={Transition}
      aria-describedby="Dialog"
      onBackdropClick="false"
      fullWidth
      maxWidth="lg"
      scroll="paper"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle style={{ backgroundColor: "#F4F7FE" }}>
        <div className={classes.alignLeftRight}>
          <Typography variant="subtitle1" color="textPrimary">
          {role.role !== "Dispatch" && role.role !== "Reporting"? "Manage Users" : "View Users"}
          </Typography>
          <IconButton color="dark" aria-label="open drawer" onClick={onclose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <ManageUserTable/>
        {/* <StepHandler /> */}
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    alignLeftRight: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    paper: {
      maxWidth: "100% !important",
      // width: "1080px !important",
      // height: "746px",
      overflow: "hidden !important"
    },
  })
);

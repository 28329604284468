/* eslint-disable import/no-anonymous-default-export */
import * as Actions from "../constants/constants";

const initialState = {
  groupRecipients: [],
  individualRecipients: [],
  selectedGroup: [],
  postDispatch: [],
  statusReports: [],
  templateList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_GROUP_RECIPIENTS:
      return { ...state, groupRecipients: action.payload };
    case Actions.FETCH_INDIVIDUAL_RECIPIENTS:
      return { ...state, individualRecipients: action.payload };
    case Actions.FETCH_STATUS_REPORTS_BY_ID:
      return { ...state, statusReports: action.payload };
    case Actions.SAVE_SELECTED_GROUP:
      return { ...state, selectedGroup: action.payload };
    case Actions.POST_SEND_DISPATCH:
      return { ...state, postDispatch: action.payload };
    case Actions.FETCH_TEMPLATE_LIST:
      return { ...state, templateList: action.payload };
    default:
      return state;
  }
};



import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Link,
  Input,
  CircularProgress,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import InputAdornment from "@mui/material/InputAdornment";
// import { useForm, Controller } from "react-hook-form";
import { useFormik } from "formik";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Logo from "../assets/images/logo.png";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import KeyIcon from "@mui/icons-material/Key";

const Signin = (props) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showProcessing, setShowProcessing] = useState(props.showProcessing);
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Username is required")
      .min(6, "Username must be at least 6 characters")
      .max(80, "Username must not exceed 80 characters")
      .email("Email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(80, "Password must not exceed 40 characters"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      rememberme: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values, {resetForm}) => {
      props.onSubmit(values);
      resetForm({ values: ''});
    },
  });

  // const onSubmit = (data) => {
  //   console.log(JSON.stringify(data, null, 2));
  //   props.onSubmit(data);
  // };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const handleSubmit = () => {
  //   submitForm();
  // };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        // callMyFunction();
        // handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <form
      onSubmit={formik.handleSubmit}
      onKeyDown={(event) => event.keyCode === 13 && formik.handleSubmit()}
    >
      <Box>
        <Box
          sx={{
            // marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={`${Logo}`} alt="VRad Logo" width="200" height="60" />
        </Box>
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" color="textSecondary">
            Communications Center
          </Typography>
        </Box>
        <Grid container spacing={1} sx={{ mt: 4 }}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textPrimary">
              Email Address
            </Typography>
            <TextField
              required
              id="username"
              name="username"
              // label="Username"
              fullWidth
              margin="dense"
              autoComplete="email"
              size="small"
              autoFocus
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textPrimary">
              Password
            </Typography>

            <TextField
              required
              id="password"
              name="password"
              fullWidth
              margin="dense"
              size="small"
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              className={classes.passwordField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <OutlinedInput
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                fullWidth
                margin="dense"
                size="small"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                startAdornment={
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              /> */}
          </Grid>
          <Grid container item xs={12} className={classes.alignLeftRight}>
            {/* <div>
              <label style={{display: 'flex', alignItems: 'center'}}>
                <input
                  className={classes.customCheckbox}
                  name="rememberme"
                  type="checkbox"
                  onChange={formik.handleChange}
                  value="Remember Me"
                />
                <span style={{marginLeft: '0.5rem'}}>Remember Me</span>
              </label>
            </div> */}
            {/* <FormControlLabel
              control={
                <Field
                  type="checkbox"
                  // component={CheckboxWithLabel}
                  name="numbers"
                  value={formik.values.rememberme}
                  onChange={formik.handleChange}
                  // Label={{ label: Remember me }}
                />
              }
              // control={<Checkbox checked={formik.values.rememberme} />}
              label={<Typography color={"inherit"}>Remember me</Typography>}
              name="rememberMe"
            /> */}
            {/* <FormControlLabel
            control={
              <Controller
                control={control}
                name="rememberMe"
                defaultValue="false"
                inputRef={register()}
                render={({ field: { onChange } }) => (
                  <Checkbox
                    color="primary"
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={<Typography color={"inherit"}>Remember me</Typography>}
          /> */}
            <Link
              component="button"
              variant="body2"
              onClick={(event) => {
                event.preventDefault();
                props.onForgotPassword();
              }}
            >
              Forgot password?
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3 }}
              // onClick={handleSubmit(onSubmit)}
              className={classes.button}
            >
              {props.showProcessing && (
                <CircularProgress size={25} sx={{ color: "#fff" }} />
              )}
              {!props.showProcessing && `Sign In`}
            </Button>
          </Grid>
          {/* <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            sx={{ mt: 1 }}
            onClick={handleSubmit(onSubmit)}
            className={classes.button}
            startIcon={<KeyIcon />}
          >
            Sign in with Single Sign-On
          </Button>
        </Grid> */}
        </Grid>
      </Box>
    </form>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    passwordField: {
      // border: "2px solid red", // Testing that these styles work
      "&::-ms-reveal": {
        display: "none"
      }
    },
    alignLeftRight: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    customCheckbox: {
      width: "16px",
      height: "16px",
    },
  })
);

export default Signin;

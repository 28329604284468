import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createStyles, makeStyles } from "@mui/styles";
import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import LoginBackground from "../../components/common/LoginBackground";
import ForgotPassword from "../../components/ForgotPassword";
import { useNavigate } from "react-router-dom";
import { postResetPassword } from "../../redux/actions/loginActions";
import ResetPassword from "../../components/ResetPassword";
import { useParams } from "react-router-dom";

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search)
  // let params = useParams();
  var paramsArr = [] // params && params.id.split("&");
  // paramsArr[0] = paramsArr[0].replace("code=", "");
  // paramsArr[1] = paramsArr[1].replace("userId=", "");
  paramsArr[0]=queryParams.get("code");
  paramsArr[1]=queryParams.get("userId");
  const [isResetPasswordSent, setIsResetPasswordSent] = useState(false);
  useEffect(() => {
    // console.log(new URLSearchParams(window.location.search))
    // console.log(Object.keys(new URLSearchParams(window.location.search)).length)
    // Object.keys(new URLSearchParams(window.location.search)).length === 0 && navigate("/login")
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onReturntoLogin = (obj) => {
    navigate("/login");
  };

  const onResetPassword = (obj) => {
    let payload = {
      UserName: paramsArr[1],
      Email: paramsArr[1],
      Password: obj.newPassword,
      code: paramsArr[0]
    };
    dispatch(postResetPassword(payload))
      .then(() => {
        setIsResetPasswordSent(true);
      })
      .catch((error) => {});
  };

  return (
    <LoginBackground>
      <ResetPassword
        onSubmit={onResetPassword}
        onReturntoLogin={onReturntoLogin}
        isResetPasswordSent={isResetPasswordSent}
      />
    </LoginBackground>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // loginBackground: {
    //   background: `url(${BGImage}) no-repeat center center fixed`,
    //   height: "100vh",
    //   width: "100vw",
    //   backgroundSize: "cover",
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    // },
    // loginCard: {
    //   width: "500px",
    //   // height: "500px",
    //   borderRadius: "1.5rem",
    //   // position:'absolute',
    //   // left: '50%',
    //   // top: '50%'
    // },
  })
);

export default ResetPasswordPage;

import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useFormik } from "formik";
import * as yup from "yup";
import useAPIError from "../../common/hooks/useAPIError";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IMaskInput } from "react-imask";
import Chip from "@mui/material/Chip";
// import Notification from "../common/Notifications";
import Stack from "@mui/material/Stack";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import CircleIcon from "@mui/icons-material/Circle";
import SearchIcon from "@mui/icons-material/Search";
import {
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  TextField,
  Typography,
  Button,
  CircularProgress,
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
  Divider,
  Link,
  Alert,
  AlertTitle,
  Checkbox,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { createStyles, makeStyles } from "@mui/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import { formatPhoneNumber } from "../../common/helpers";
import store from "../../redux/store";
import {
  fetchIndividualRecipients,
  fetchGroupRecipientsWithFilter,
} from "../../redux/actions/dispatchActions";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ManageGroupDetails = ({
  title,
  groupData,
  onClose,
  onSave,
  saveProgress,
  isGroupEdit,
  dialogTitle,
}) => {
  const { recipients } = useSelector((state) => ({
    recipients: state.recipientReducer.recipients || [],
  }));
  const { showNotification } = useAPIError();
  const dispatch = useDispatch();
  const [selectedRecipientCount, setSelectedRecipientCount] = useState(
    Object.keys(groupData).length > 0 ? groupData.length : 0
  );

  const [searchText, setSearchText] = useState("");
  const [showRecipientAlert, setShowRecipientAlert] = useState(false);
  const [newRecipientCount, setNewRecipientCount] = useState(false);
  const [recipientType, setRecipientType] = useState("Individual");
  const [selectRecipientChecked, setSelectRecipientChecked] = React.useState(
    []
  );
  const [selectedRecipientChecked, setSelectedRecipientChecked] =
    React.useState([]);
  const [selectRecipientList, setSelectRecipientList] = useState([]);
  const [selectedRecipientList, setSelectedRecipientList] = useState([]);

  const [selectRecipientListLoading, setSelectRecipientListLoading] =
    useState(false);
  const [selectedRecipientListLoading, setSelectedRecipientListLoading] =
    useState(false);
  const [groupOrIndividualRecipientsList, setGroupOrIndividualRecipientsList] =
    useState([]);
  const [
    preservegroupOrIndividualRecipientsList,
    setPreservegroupOrIndividualRecipientsList,
  ] = useState([]);
  const [selectedGroupRecipientsList, setSelectedGroupRecipientsList] =
    useState(Object.keys(groupData).length > 0 ? groupData.Recipients : []);

  const validationSchema = yup.object({
    GroupName: yup
      .string("Enter your Group Name")
      .matches(/^[^\s]+(\s+[^\s]+)*$/, "Cannot start or end with a blank space")
      .required("Group Name is required"),
    GroupDescription: yup
      .string("Enter your Group Description")
      .required("Group Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      GroupName: (Object.keys(groupData).length > 0 && groupData.Name) || "",
      GroupDescription:
        (Object.keys(groupData).length > 0 && groupData.Description) || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (selectedGroupRecipientsList.length > 0) {
        // !saveProgress &&
        onSave(values, selectedGroupRecipientsList);
        resetForm({ values: "" });
      } else {
        showNotification("Recipient is required", "error");
      }
    },
  });

  // const { groupRecipients } = useSelector((state) => ({
  //   groupRecipients: state.dispatchReducer.groupRecipients || [],
  // }));

  // useImperativeHandle(ref, () => ({
  //   recipientsRef() {
  //     let selectedRecipientValues = {
  //       isError: false,
  //       selectedRecipient: "",
  //       selectedRecipientCount,
  //       selectedRecipientGroupCount,
  //       selectedRecipientIndividualCount,
  //     };
  //     if (selectedGroupRecipientsList.length === 0) {
  //       selectedRecipientValues.isError = true;
  //     } else {
  //       selectedRecipientValues.isError = false;
  //       selectedRecipientValues.selectedRecipient = selectedGroupRecipientsList;
  //     }
  //     return selectedRecipientValues;
  //   },
  // }));

  const onSelectbox1Recipient = (value) => () => {
    const currentIndex = selectRecipientChecked.indexOf(value);
    const newChecked = [...selectRecipientChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
      setSelectRecipientList(newChecked);
    } else {
      newChecked.splice(currentIndex, 1);
      setSelectRecipientList(newChecked);
    }

    setSelectRecipientChecked(newChecked);
  };

  const onSelectbox2Recipient = (value) => () => {
    const currentIndex = selectedRecipientChecked.indexOf(value);
    const newChecked = [...selectedRecipientChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
      setSelectedRecipientList(newChecked);
    } else {
      newChecked.splice(currentIndex, 1);
      setSelectedRecipientList(newChecked);
    }

    setSelectedRecipientChecked(newChecked);
  };

  useEffect(() => {
    let tempGroupRecipients = [...recipients];
    Object.keys(groupData).length > 0 &&
      groupData.Recipients.map((selectedRecipient, parentIndex) => {
        tempGroupRecipients.map((newRecipient, index) => {
          if (newRecipient.Id === selectedRecipient.Id) {
            const currentIndex = tempGroupRecipients.indexOf(newRecipient);
            tempGroupRecipients.splice(currentIndex, 1);
          }
          return 0;
        });
        return 0;
      });
    setGroupOrIndividualRecipientsList([...tempGroupRecipients]);
    setPreservegroupOrIndividualRecipientsList([...recipients]);
    if (Object.keys(groupData).length > 0) {
      let individualRecipient = 0;
      groupData.Recipients.map((recipient) => individualRecipient++);
      setSelectedRecipientCount(individualRecipient);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectAllSelectRecipient = (event) => {
    const newChecked = [];
    groupOrIndividualRecipientsList.map((group) => newChecked.push(group));
    setSelectRecipientChecked(newChecked);
    setSelectRecipientList(newChecked);
  };

  const onDeselectAllSelectRecipient = (event) => {
    const newChecked = [];
    setSelectRecipientChecked(newChecked);
    setSelectRecipientList([]);
  };

  const onSelectAllSelectedRecipient = (event) => {
    const newChecked = [];
    selectedGroupRecipientsList.map((group) => newChecked.push(group));
    setSelectedRecipientChecked(newChecked);
    setSelectedRecipientList(newChecked);
  };

  const onDeselectAllSelectedRecipient = (event) => {
    const newChecked = [];
    setSelectedRecipientChecked(newChecked);
    setSelectedRecipientList([]);
  };

  const getMatch = (arr, searchString) => {
    var reg = new RegExp(searchString.split("").join(".*"), "i");

    return arr.filter((item) => {
      if (
        recipientType === "Group"
          ? item.Name.toString().match(reg)
          : `${item.FirstName} ${item.LastName}`.toString().match(reg)
      ) {
        return item;
      } else {
        return "";
      }
    });
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    if (event.target.value === "") {
      onAddSelectedRecipient(false);
    } else {
      setGroupOrIndividualRecipientsList(
        getMatch(groupOrIndividualRecipientsList, event.target.value)
      );
    }
  };

  const alertTimer = (newCount) => {
    setShowRecipientAlert(true);
    setNewRecipientCount(newCount);
    setTimeout(() => {
      setShowRecipientAlert(false);
      setNewRecipientCount(0);
    }, "3000");
  };

  const onAddSelectedRecipient = (isAlertShow) => {
    const historyRecipient = [...preservegroupOrIndividualRecipientsList];
    const toAddRecipientList = [];
    let existingSelectedGroupWithSelectRecipient = [
      ...selectedGroupRecipientsList,
      ...selectRecipientList,
    ];

    existingSelectedGroupWithSelectRecipient.map((selectedRecipient) => {
      historyRecipient.map((newRecipient) => {
        if (newRecipient.Id === selectedRecipient.Id) {
          toAddRecipientList.push(newRecipient);
          const currentIndex = historyRecipient.indexOf(newRecipient);
          historyRecipient.splice(currentIndex, 1);
        }
        return 0;
      });
      return 0;
    });

    let newCount = 0;
    toAddRecipientList.map((recipient) => newCount++);
    isAlertShow && alertTimer(newCount);
    let existingSelectedGroup = [
      ...selectedGroupRecipientsList,
      ...toAddRecipientList,
    ];
    const uniqueIds = [];
    const uniqueGroups = existingSelectedGroup.filter((group) => {
      const isDuplicate = uniqueIds.includes(group.Id);
      if (!isDuplicate) {
        uniqueIds.push(group.Id);
        return true;
      }
      return false;
    });

    setSelectedGroupRecipientsList(uniqueGroups);
    setGroupOrIndividualRecipientsList(historyRecipient);
    setSearchText("");
    setSelectRecipientList([]);
    onDeselectAllSelectRecipient();
    let individualRecipient = 0;
    uniqueGroups.map((recipient) => individualRecipient++);

    setSelectedRecipientCount(individualRecipient);
  };

  const onRemoveSelectedRecipient = (event) => {
    let tempSelectedList = [...selectedGroupRecipientsList];
    selectedRecipientList.map((selRecipient) => {
      if (selRecipient.Name !== undefined) {
        tempSelectedList.map((listRecipient) => {
          if (selRecipient.Name === listRecipient.Name) {
            const currentIndex = tempSelectedList.indexOf(selRecipient);
            tempSelectedList.splice(currentIndex, 1);
          }
        });
      } else {
        tempSelectedList.map((listRecipient) => {
          if (selRecipient.FirstName === listRecipient.FirstName) {
            const currentIndex = tempSelectedList.indexOf(selRecipient);
            tempSelectedList.splice(currentIndex, 1);
          }
        });
      }
    });
    setSelectedGroupRecipientsList(tempSelectedList);

    let zeroTempGroupRecipients = [];
    preservegroupOrIndividualRecipientsList.map((newRecipient, index) => {
      if (newRecipient.RecipientCount !== 0) {
        zeroTempGroupRecipients.push(newRecipient);
      }
    });
    const historyRecipient = [...preservegroupOrIndividualRecipientsList];
    tempSelectedList.map((selRecipient) => {
      historyRecipient.map((newRecipient) => {
        if (
          recipientType === "Group" &&
          newRecipient.Name === selRecipient.Name
        ) {
          const currentIndex = historyRecipient.indexOf(selRecipient);
          historyRecipient.splice(currentIndex, 1);
        } else if (
          recipientType !== "Group" &&
          newRecipient.FirstName === selRecipient.FirstName
        ) {
          const currentIndex = historyRecipient.indexOf(selRecipient);
          historyRecipient.splice(currentIndex, 1);
        }
      });
    });

    setGroupOrIndividualRecipientsList(historyRecipient);
    setSelectedRecipientList([]);
    onDeselectAllSelectedRecipient();
    setSearchText("");

    let groupRecipient = 0;
    let individualRecipient = 0;
    tempSelectedList.map((recipient) => {
      if (recipient.Name) {
        groupRecipient += recipient.RecipientCount;
      } else {
        individualRecipient += 1;
      }
    });

    setSelectedRecipientCount(groupRecipient + individualRecipient);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} mt={1} p={1}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            style={{ color: "#2CABEA" }}
          >
            {dialogTitle}
          </Typography>
          <Divider />
        </Grid>
        <Grid item sm={12} md={6} style={{ padding: "0.5rem" }}>
          <Typography>Group Name</Typography>
          <TextField
            fullWidth
            id="GroupName"
            name="GroupName"
            variant="outlined"
            size="small"
            margin="dense"
            // disabled={!isGroupEdit}
            inputProps={{
              readOnly: !isGroupEdit,
            }}
            value={formik.values.GroupName}
            onChange={formik.handleChange}
            error={formik.touched.GroupName && Boolean(formik.errors.GroupName)}
          />
          <Typography variant="overline" color="error">
            {formik.touched.GroupName && formik.errors.GroupName}
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: 700, marginTop: "1rem" }}
          >
            Select Recipients
          </Typography>
          <div
            style={{
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <FormControl fullWidth sx={{ flexGrow: 2 }}>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={searchText}
                onChange={handleSearchChange}
                placeholder="Search"
                // disabled={!isGroupEdit}
                inputProps={{
                  readOnly: !isGroupEdit,
                }}
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                label=""
              />
            </FormControl>
            {/* <FormControl sx={{ flexGrow: 1, ml: 1, minWidth: 120 }} size="small">
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={recipientType}
              label=""
              onChange={handleRecipientTypeChange}
            >
              <MenuItem value="Group">Group</MenuItem>
              <MenuItem value="Individual">Individual</MenuItem>
            </Select>
          </FormControl> */}
          </div>
          <div style={{}}>
            <List
              disabled={!isGroupEdit}
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                border: "1.5px solid rgba(0, 50, 97, 0.2)",
                borderRadius: "10px",
                height: "280px",
                overflowY: "scroll",
                marginTop: "10px",
              }}
            >
              {selectRecipientListLoading && (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress size={25} />
                </div>
              )}
              {!selectRecipientListLoading &&
                groupOrIndividualRecipientsList &&
                groupOrIndividualRecipientsList.map((group, index) => {
                  const labelId = `checkbox-list-label-${group.Id}`;
                  return (
                    <ListItem
                      key={`ListItem${index}${group.Id}`}
                      disablePadding
                    >
                      <ListItemButton
                        role={undefined}
                        onClick={onSelectbox1Recipient(group)}
                        dense
                      >
                        {recipientType === "Group" ? (
                          <ListItemText
                            id={labelId}
                            primary={group.Name}
                            secondary={
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <PeopleAltOutlinedIcon
                                  sx={{ height: "12px", width: "20px" }}
                                />
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "initial",
                                  }}
                                >
                                  {group.RecipientCount}
                                </span>
                                {/* <CircleIcon
                                sx={{
                                  height: "5px",
                                  width: "5px",
                                  color: "#003261",
                                  marginLeft: "6px",
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: "5px",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  lineHeight: "initial",
                                }}
                              >
                                Category Text
                              </span> */}
                              </span>
                            }
                          />
                        ) : (
                          <ListItemText
                            id={labelId}
                            primary={`${group.FirstName} ${group.LastName}`}
                            secondary={
                              group.City !== "" &&
                              group.Country !== "" && (
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FmdGoodIcon
                                    sx={{ height: "12px", width: "20px" }}
                                  />
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: 600,
                                      lineHeight: "initial",
                                    }}
                                  >
                                    {`${group.City}, ${group.Country}`}
                                  </span>
                                  {/* <CircleIcon
                                sx={{
                                  height: "5px",
                                  width: "5px",
                                  color: "#003261",
                                  marginLeft: "6px",
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: "5px",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  lineHeight: "initial",
                                }}
                              >
                                Category Text
                              </span> */}
                                </span>
                              )
                            }
                          />
                        )}
                        {isGroupEdit && (
                          <ListItemIcon sx={{ minWidth: 0 }}>
                            <Checkbox
                              edge="start"
                              checked={
                                selectRecipientChecked.indexOf(group) !== -1
                              }
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                        )}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </List>
          </div>
          {isGroupEdit && (
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Link
                  underline="always"
                  onClick={onSelectAllSelectRecipient}
                  style={{ cursor: "pointer" }}
                  disabled={!isGroupEdit}
                >
                  Select All
                </Link>
                <span style={{ padding: "0 5px" }}>|</span>
                <Link
                  underline="always"
                  onClick={onDeselectAllSelectRecipient}
                  style={{ cursor: "pointer" }}
                  disabled={!isGroupEdit}
                >
                  Deselect All
                </Link>
              </div>
              <div>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ width: "55px", height: "32px" }}
                  disabled={selectRecipientChecked.length === 0}
                  onClick={() => onAddSelectedRecipient(true)}
                >
                  Add
                </Button>
              </div>
            </div>
          )}
        </Grid>
        <Grid item sm={12} md={6} style={{ padding: "0.5rem" }}>
          <Typography>Group Description</Typography>
          <TextField
            fullWidth
            id="GroupDescription"
            name="GroupDescription"
            variant="outlined"
            size="small"
            margin="dense"
            // disabled={!isGroupEdit}
            inputProps={{
              readOnly: !isGroupEdit,
            }}
            value={formik.values.GroupDescription}
            onChange={formik.handleChange}
            error={
              formik.touched.GroupDescription &&
              Boolean(formik.errors.GroupDescription)
            }
          />
          <Typography variant="overline" color="error">
            {formik.touched.GroupDescription && formik.errors.GroupDescription}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "1rem",
              zIndex: 99,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 700, flexGrow: 2 }}>
              Selected Recipients:{" "}
              <span style={{ color: "#2CABEA" }}>{selectedRecipientCount}</span>
            </Typography>
          </div>
          <div style={{}}>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                border: "1.5px solid rgba(0, 50, 97, 0.2)",
                borderRadius: "10px",
                height: "333px",
                overflowY: "scroll",
                marginTop: "10px",
              }}
            >
              {selectedRecipientListLoading && (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress size={25} />
                </div>
              )}
              {!selectedRecipientListLoading &&
                selectedGroupRecipientsList &&
                selectedGroupRecipientsList.map((group, index) => {
                  const labelId = `checkbox-list-label-${group.Id}`;
                  return (
                    <ListItem
                      key={`ListItem${index}${group.Id}`}
                      disablePadding
                    >
                      <ListItemButton
                        role={undefined}
                        onClick={onSelectbox2Recipient(group)}
                        dense
                      >
                        {group.Name !== undefined ? (
                          <ListItemText
                            id={labelId}
                            primary={group.Name}
                            secondary={
                              group.City !== "" &&
                              group.Country !== "" && (
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <PeopleAltOutlinedIcon
                                    sx={{ height: "12px", width: "20px" }}
                                  />
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: 600,
                                      lineHeight: "initial",
                                    }}
                                  >
                                    {group.RecipientCount}
                                  </span>
                                  {/* <CircleIcon
                                sx={{
                                  height: "5px",
                                  width: "5px",
                                  color: "#003261",
                                  marginLeft: "6px",
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: "5px",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  lineHeight: "initial",
                                }}
                              >
                                Category Text
                              </span> */}
                                </span>
                              )
                            }
                          />
                        ) : (
                          <ListItemText
                            id={labelId}
                            primary={`${group.FirstName} ${group.LastName}`}
                            secondary={
                              group.City !== "" &&
                              group.Country !== "" && (
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FmdGoodIcon
                                    sx={{ height: "12px", width: "20px" }}
                                  />
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: 600,
                                      lineHeight: "initial",
                                    }}
                                  >
                                    {`${group.City}, ${group.Country}`}
                                  </span>
                                  {/* <CircleIcon
                                sx={{
                                  height: "5px",
                                  width: "5px",
                                  color: "#003261",
                                  marginLeft: "6px",
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: "5px",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  lineHeight: "initial",
                                }}
                              >
                                Category Text
                              </span> */}
                                </span>
                              )
                            }
                          />
                        )}
                        {isGroupEdit && (
                          <ListItemIcon sx={{ minWidth: 0 }}>
                            <Checkbox
                              edge="start"
                              checked={
                                selectedRecipientChecked.indexOf(group) !== -1
                              }
                              tabIndex={-1}
                              disableRipple
                              // disabled={selectedRecipientChecked.length === 0}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                        )}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </List>
          </div>
          {showRecipientAlert && (
            <div
              style={{
                display: "flex",
                marginTop: "-70px",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                zIndex: 1000,
                paddingLeft: "35px",
              }}
            >
              <Alert>{newRecipientCount} recipient(s) added</Alert>
            </div>
          )}
          {isGroupEdit && (
            <div
              style={{
                display: "flex",
                marginTop: showRecipientAlert ? "31px" : "10px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Link
                  underline="always"
                  onClick={onSelectAllSelectedRecipient}
                  style={{ cursor: "pointer" }}
                >
                  Select All
                </Link>
                <span style={{ padding: "0 5px" }}>|</span>
                <Link
                  underline="always"
                  onClick={onDeselectAllSelectedRecipient}
                  style={{ cursor: "pointer" }}
                >
                  Deselect All
                </Link>
              </div>
              <div>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ width: "55px", height: "32px" }}
                  disabled={selectedRecipientChecked.length === 0}
                  onClick={onRemoveSelectedRecipient}
                >
                  Remove
                </Button>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid mt={6} item xs={12}>
          <Divider />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          mt={5}
        >
          <Stack spacing={2} direction="row">
            <Button
              sx={{
                color: "#003261",
                width: "12rem",
              }}
              color="primary"
              fullWidth
              variant="outlined"
              onClick={onClose}
            >
              {isGroupEdit ? "Cancel" : "Back"}
            </Button>

            {isGroupEdit && (
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                sx={{
                  backgroundColor: "#8BD1F3",
                  color: "#003261",
                  width: "15rem",
                  "&:hover": {
                    backgroundColor: "#8BD1F3",
                    opacity: 0.8,
                  },
                }}
                disabled={!isGroupEdit}
              >
                {saveProgress && (
                  <CircularProgress size={25} sx={{ color: "#fff" }} />
                )}
                {!saveProgress
                  ? Object.keys(groupData).length > 0
                    ? !saveProgress && `Save Changes`
                    : !saveProgress && `Save & Create Group`
                  : ""}
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    active: {
      color: "#05CD99",
    },
    inactive: {
      color: "#0D1624",
    },
  })
);

export default ManageGroupDetails;

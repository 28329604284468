import { request } from "./request";
import { FETCH_MESSAGE_LOG, FETCH_MESSAGE_DETAILS_BY_ID } from "../constants/constants";

export function fetchMessageLog(data) {
  return (dispatch) => {
    return request("MessageLog", "GET", {}).then((response) => {
      dispatch({
        type: FETCH_MESSAGE_LOG,
        payload: response.Data,
      });
    });
  };
}
export function fetchMessageLogByFilter(payload) {
  return (dispatch) => {
    return request("MessageLog/filterbydays", "POST", payload).then((response) => {
      dispatch({
        type: FETCH_MESSAGE_LOG,
        payload: response.Data,
      });
    });
  };
}

export function fetchMessageDetailsById(Id) {
  return (dispatch) => {
    return request(`MessageLog/MessageDetails/${Id}`, "GET", {}).then((response) => {
      dispatch({
        type: FETCH_MESSAGE_DETAILS_BY_ID,
        payload: response.Data,
      });
    });
  };
}

import React, { useEffect, useState, useImperativeHandle } from "react";
import {
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  TextField,
  Typography,
  Button,
  CircularProgress,
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
  Link,
  Alert,
  AlertTitle,
} from "@mui/material";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import CircleIcon from "@mui/icons-material/Circle";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { useDispatch } from "react-redux";
import useAPIError from "../../common/hooks/useAPIError";
import {
  fetchIndividualRecipients,
  fetchGroupRecipientsWithFilter,
} from "../../redux/actions/dispatchActions";

const SelectRecipients = React.forwardRef(({ recipientInfo }, ref) => {
  const dispatch = useDispatch();
  const [selectedRecipientCount, setSelectedRecipientCount] = useState(
    Object.keys(recipientInfo).length > 0
      ? recipientInfo.selectedRecipientCount
      : 0
  );
  const [selectedRecipientGroupCount, setSelectedRecipientGroupCount] =
    useState(
      Object.keys(recipientInfo).length > 0
        ? recipientInfo.selectedRecipientGroupCount
        : 0
    );
  const [
    selectedRecipientIndividualCount,
    setSelectedRecipientIndividualCount,
  ] = useState(
    Object.keys(recipientInfo).length > 0
      ? recipientInfo.selectedRecipientIndividualCount
      : 0
  );
  const [searchText, setSearchText] = useState("");
  const [showRecipientAlert, setShowRecipientAlert] = useState(false);
  const [newRecipientCount, setNewRecipientCount] = useState(false);
  const [recipientType, setRecipientType] = useState("Group");
  const [selectRecipientChecked, setSelectRecipientChecked] = React.useState(
    []
  );
  const [selectedRecipientChecked, setSelectedRecipientChecked] =
    React.useState([]);
  const [selectRecipientList, setSelectRecipientList] = useState([]);
  const [selectedRecipientList, setSelectedRecipientList] = useState([]);
  const { showNotification } = useAPIError();
  const [selectRecipientListLoading, setSelectRecipientListLoading] =
    useState(false);
  const [selectedRecipientListLoading, setSelectedRecipientListLoading] =
    useState(false);
  const [groupOrIndividualRecipientsList, setGroupOrIndividualRecipientsList] =
    useState([]);
  const [groupOrIndividualRecipientsListSearch, setGroupOrIndividualRecipientsListSearch] =
    useState([]);
  const [
    preservegroupOrIndividualRecipientsList,
    setPreservegroupOrIndividualRecipientsList,
  ] = useState([]);
  const [selectedGroupRecipientsList, setSelectedGroupRecipientsList] =
    useState(
      Object.keys(recipientInfo).length > 0
        ? recipientInfo.selectedRecipient
        : []
    );

  const { groupRecipients } = useSelector((state) => ({
    groupRecipients: state.dispatchReducer.groupRecipients || [],
  }));

  useImperativeHandle(ref, () => ({
    recipientsRef() {
      let selectedRecipientValues = {
        isError: false,
        selectedRecipient: "",
        selectedRecipientCount,
        selectedRecipientGroupCount,
        selectedRecipientIndividualCount,
      };
      if (selectedGroupRecipientsList.length === 0) {
        selectedRecipientValues.isError = true;
      } else {
        selectedRecipientValues.isError = false;
        selectedRecipientValues.selectedRecipient = selectedGroupRecipientsList;
      }
      return selectedRecipientValues;
    },
  }));

  const onSelectbox1Recipient = (value) => () => {
    const currentIndex = selectRecipientChecked.indexOf(value);
    const newChecked = [...selectRecipientChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
      setSelectRecipientList(newChecked);
    } else {
      newChecked.splice(currentIndex, 1);
      setSelectRecipientList(newChecked);
    }

    setSelectRecipientChecked(newChecked);
  };

  const onSelectbox2Recipient = (value) => () => {
    const currentIndex = selectedRecipientChecked.indexOf(value);
    const newChecked = [...selectedRecipientChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
      setSelectedRecipientList(newChecked);
    } else {
      newChecked.splice(currentIndex, 1);
      setSelectedRecipientList(newChecked);
    }

    setSelectedRecipientChecked(newChecked);
  };

  useEffect(() => {
    let tempGroupRecipients = [...groupRecipients];
    selectedGroupRecipientsList.map((selectedRecipient, parentIndex) => {
      if (selectedRecipient.Name) {
        tempGroupRecipients.map((newRecipient, index) => {
          if (newRecipient.Name === selectedRecipient.Name) {
            const currentIndex = tempGroupRecipients.indexOf(newRecipient);
            tempGroupRecipients.splice(currentIndex, 1);
          }
          return 0;
        });
      }
      return 0;
    });
    let zeroTempGroupRecipients = [];
    tempGroupRecipients.map((newRecipient, index) => {
      if (newRecipient.RecipientCount !== 0) {
        zeroTempGroupRecipients.push(newRecipient);
      }
    });
    setGroupOrIndividualRecipientsList([...tempGroupRecipients]);
    setGroupOrIndividualRecipientsListSearch([...tempGroupRecipients]);
    setPreservegroupOrIndividualRecipientsList([...groupRecipients]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectAllSelectRecipient = (event) => {
    if (groupOrIndividualRecipientsList.length === 0) {
      showNotification("No recipient available", "error");
    } else {
      const newChecked = [];
      groupOrIndividualRecipientsList.map((group) => newChecked.push(group));
      setSelectRecipientChecked(newChecked);
      setSelectRecipientList(newChecked);
    }
  };

  const onDeselectAllSelectRecipient = (event) => {
    if (groupOrIndividualRecipientsList.length === 0) {
      showNotification("No recipient available", "error");
    } else {
      const newChecked = [];
      setSelectRecipientChecked(newChecked);
      setSelectRecipientList([]);
    }
  };

  const onSelectAllSelectedRecipient = (event) => {
    if (selectedGroupRecipientsList.length === 0) {
      showNotification("No recipient available", "error");
    } else {
      const newChecked = [];
      selectedGroupRecipientsList.map((group) => newChecked.push(group));
      setSelectedRecipientChecked(newChecked);
      setSelectedRecipientList(newChecked);
    }
  };

  const onDeselectAllSelectedRecipient = (event) => {
    if (selectedGroupRecipientsList.length === 0) {
      showNotification("No recipient available", "error");
    } else {
      const newChecked = [];
      setSelectedRecipientChecked(newChecked);
      setSelectedRecipientList([]);
    }
  };

  const getMatch = (arr, searchString) => {
    var reg = new RegExp(searchString.split("").join(".*"), "i");
    return arr.filter((item) => {
      if (
        recipientType === "Group"
          ? item.Name.toString().match(reg)
          : `${item.FirstName} ${item.LastName}`.toString().match(reg)
      ) {
        return item;
      } else {
        return "";
      }
    });
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    if (event.target.value === "") {
      // setGroupOrIndividualRecipientsList(
      //   preservegroupOrIndividualRecipientsList
      // );
      onAddSelectedRecipient(false);
    } else {
      let temp = [...groupOrIndividualRecipientsListSearch]
      setGroupOrIndividualRecipientsList(
        getMatch(temp, event.target.value)
      );
    }
  };

  const handleRecipientTypeChange = (event) => {
    setRecipientType(event.target.value);
    setSelectRecipientListLoading(true);
    setSearchText("");
    dispatch(
      event.target.value === "Group"
        ? fetchGroupRecipientsWithFilter()
        : fetchIndividualRecipients()
    ).then(() => {
      if (event.target.value === "Group") {
        let tempGroupRecipients = [
          ...store.getState().dispatchReducer.groupRecipients,
        ];
        selectedGroupRecipientsList.map((selectedRecipient, parentIndex) => {
          if (selectedRecipient.Name) {
            tempGroupRecipients.map((newRecipient, index) => {
              if (newRecipient.Name === selectedRecipient.Name) {
                const currentIndex = tempGroupRecipients.indexOf(newRecipient);
                tempGroupRecipients.splice(currentIndex, 1);
              }
              return 0;
            });
          }
          return 0;
        });

        setGroupOrIndividualRecipientsList([...tempGroupRecipients]);
        setGroupOrIndividualRecipientsListSearch([...tempGroupRecipients]);
        setPreservegroupOrIndividualRecipientsList([
          ...store.getState().dispatchReducer.groupRecipients,
        ]);
      } else {
        let tempGroupRecipients = [
          ...store.getState().dispatchReducer.individualRecipients,
        ];
        selectedGroupRecipientsList.map((selectedRecipient, parentIndex) => {
          if (selectedRecipient.Name === undefined) {
            tempGroupRecipients.map((newRecipient, index) => {
              if (newRecipient.FirstName === selectedRecipient.FirstName) {
                tempGroupRecipients.splice(index, 1);
              }
              return 0;
            });
          }
          return 0;
        });
        setGroupOrIndividualRecipientsList(tempGroupRecipients);
        setGroupOrIndividualRecipientsListSearch(tempGroupRecipients);
        setPreservegroupOrIndividualRecipientsList([
          ...store.getState().dispatchReducer.individualRecipients,
        ]);
      }

      setSelectRecipientListLoading(false);
    });
  };

  const alertTimer = (newCount) => {
    setShowRecipientAlert(true);
    setNewRecipientCount(newCount);
    setTimeout(() => {
      setShowRecipientAlert(false);
      setNewRecipientCount(0);
    }, "3000");
  };

  const onAddSelectedRecipient = (isAlertShow) => {
    const historyRecipient = [...preservegroupOrIndividualRecipientsList];
    const toAddRecipientList = [];

    let existingSelectedGroupWithSelectRecipient = [
      ...selectedGroupRecipientsList,
      ...selectRecipientList,
    ];

    existingSelectedGroupWithSelectRecipient.map((selRecipient) => {
      historyRecipient.map((newRecipient) => {
        if (
          recipientType === "Group" &&
          newRecipient.Id === selRecipient.Id
        ) {
          toAddRecipientList.push(newRecipient);
          const currentIndex = historyRecipient.indexOf(selRecipient);
          historyRecipient.splice(currentIndex, 1);
        } else if (
          recipientType !== "Group" &&
          newRecipient.Id === selRecipient.Id
        ) {
          toAddRecipientList.push(newRecipient);
          const currentIndex = historyRecipient.indexOf(selRecipient);
          historyRecipient.splice(currentIndex, 1);
        }
        return 0;
      });
      return 0;
    });

    let newCount = 0;
    selectRecipientList.map((recipient) => {
      if (recipientType === "Group") {
        newCount += recipient.RecipientCount;
      } else {
        newCount++;
      }
    });
    isAlertShow && alertTimer(newCount);
    let existingSelectedGroup = [
      ...selectedGroupRecipientsList,
      ...toAddRecipientList,
    ];
    const uniqueIds = [];
    const uniqueGroups = existingSelectedGroup.filter((group) => {
      const isDuplicate = uniqueIds.includes(group.Id);
      if (!isDuplicate) {
        uniqueIds.push(group.Id);
        return true;
      }
      return false;
    });

    setSelectedGroupRecipientsList(uniqueGroups);
    setGroupOrIndividualRecipientsList(historyRecipient);
    setGroupOrIndividualRecipientsListSearch(historyRecipient);
    setSearchText("");
    setSelectRecipientList([]);
    onDeselectAllSelectRecipient();
    let groupRecipient = 0;
    let groupCount = 0
    let individualRecipient = 0;
    uniqueGroups.map((recipient) => {
      if (recipient.Name) {
        groupRecipient += recipient.RecipientCount;
        groupCount++
      } else {
        individualRecipient += 1;
      }
    });

    setSelectedRecipientCount(groupRecipient + individualRecipient);
    setSelectedRecipientGroupCount(groupCount);
    setSelectedRecipientIndividualCount(individualRecipient);
  };

  const onRemoveSelectedRecipient = (event) => {
    let tempSelectedList = [...selectedGroupRecipientsList];
    selectedRecipientList.map((selRecipient) => {
      if (selRecipient.Name !== undefined) {
        tempSelectedList.map((listRecipient) => {
          if (selRecipient.Id === listRecipient.Id) {
            const currentIndex = tempSelectedList.indexOf(selRecipient);
            tempSelectedList.splice(currentIndex, 1);
          }
        });
      } else {
        tempSelectedList.map((listRecipient) => {
          if (selRecipient.Id === listRecipient.Id) {
            const currentIndex = tempSelectedList.indexOf(selRecipient);
            tempSelectedList.splice(currentIndex, 1);
          }
        });
      }
    });
    setSelectedGroupRecipientsList(tempSelectedList);

    let zeroTempGroupRecipients = [];
    preservegroupOrIndividualRecipientsList.map((newRecipient, index) => {
      if (newRecipient.RecipientCount !== 0) {
        zeroTempGroupRecipients.push(newRecipient);
      }
    });
    const historyRecipient = [...preservegroupOrIndividualRecipientsList];
    tempSelectedList.map((selRecipient) => {
      historyRecipient.map((newRecipient) => {
        if (
          recipientType === "Group" &&
          newRecipient.Name === selRecipient.Name
        ) {
          const currentIndex = historyRecipient.indexOf(selRecipient);
          historyRecipient.splice(currentIndex, 1);
        } else if (
          recipientType !== "Group" &&
          newRecipient.FirstName === selRecipient.FirstName
        ) {
          const currentIndex = historyRecipient.indexOf(selRecipient);
          historyRecipient.splice(currentIndex, 1);
        }
      });
    });

    setGroupOrIndividualRecipientsList(historyRecipient);
    setGroupOrIndividualRecipientsListSearch(historyRecipient);
    setSelectedRecipientList([]);
    onDeselectAllSelectedRecipient();
    setSearchText("");

    let groupRecipient = 0;
    let groupCount = 0;
    let individualRecipient = 0;
    tempSelectedList.map((recipient) => {
      if (recipient.Name) {
        groupRecipient += recipient.RecipientCount;
        groupCount++
      } else {
        individualRecipient += 1;
      }
    });

    setSelectedRecipientCount(groupRecipient + individualRecipient);
    setSelectedRecipientGroupCount(groupCount);
    setSelectedRecipientIndividualCount(individualRecipient);
  };

  return (
    <Grid container spacing={2} sx={{ height: "100%", paddingTop: "0.5rem" }}>
      <Grid item sm={12} md={6} style={{ padding: "0.5rem" }}>
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          Select Recipients
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <FormControl fullWidth sx={{ flexGrow: 2 }}>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={searchText}
              onChange={handleSearchChange}
              placeholder="Search"
              size="small"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              label=""
            />
          </FormControl>
          <FormControl sx={{ flexGrow: 1, ml: 1, minWidth: 120 }} size="small">
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={recipientType}
              label=""
              onChange={handleRecipientTypeChange}
            >
              <MenuItem value="Group">Group</MenuItem>
              <MenuItem value="Individual">Individual</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{}}>
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              border: "1.5px solid rgba(0, 50, 97, 0.2)",
              borderRadius: "10px",
              height: "280px",
              overflowY: "scroll",
              marginTop: "10px",
            }}
          >
            {selectRecipientListLoading && (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={25} />
              </div>
            )}
            {!selectRecipientListLoading &&
              groupOrIndividualRecipientsList &&
              groupOrIndividualRecipientsList.map((group, index) => {
                const labelId = `checkbox-list-label-${group.Id}`;
                return (
                  <ListItem key={`ListItem${index}${group.Id}`} disablePadding>
                    <ListItemButton
                      role={undefined}
                      onClick={onSelectbox1Recipient(group)}
                      dense
                    >
                      {recipientType === "Group" ? (
                        <ListItemText
                          id={labelId}
                          primary={group.Name}
                          secondary={
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <PeopleAltOutlinedIcon
                                sx={{ height: "12px", width: "20px" }}
                              />
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  lineHeight: "initial",
                                }}
                              >
                                {group.RecipientCount}
                              </span>
                              {/* <CircleIcon
                                sx={{
                                  height: "5px",
                                  width: "5px",
                                  color: "#003261",
                                  marginLeft: "6px",
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: "5px",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  lineHeight: "initial",
                                }}
                              >
                                Category Text
                              </span> */}
                            </span>
                          }
                        />
                      ) : (
                        <ListItemText
                          id={labelId}
                          primary={`${group.FirstName} ${group.LastName}`}
                          secondary={
                            group.City !== "" &&
                            group.Country !== "" && (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FmdGoodIcon
                                  sx={{ height: "12px", width: "20px" }}
                                />
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "initial",
                                  }}
                                >
                                  {`${group.City}, ${group.Country}`}
                                </span>
                                {/* <CircleIcon
                                sx={{
                                  height: "5px",
                                  width: "5px",
                                  color: "#003261",
                                  marginLeft: "6px",
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: "5px",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  lineHeight: "initial",
                                }}
                              >
                                Category Text
                              </span> */}
                              </span>
                            )
                          }
                        />
                      )}
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Checkbox
                          edge="start"
                          checked={selectRecipientChecked.indexOf(group) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </List>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Link
              underline="always"
              onClick={onSelectAllSelectRecipient}
              style={{ cursor: "pointer" }}
            >
              Select All
            </Link>
            <span style={{ padding: "0 5px" }}>|</span>
            <Link
              underline="always"
              onClick={onDeselectAllSelectRecipient}
              style={{ cursor: "pointer" }}
            >
              Deselect All
            </Link>
          </div>
          <div>
            <Button
              variant="outlined"
              size="small"
              sx={{ width: "55px", height: "32px" }}
              disabled={selectRecipientChecked.length === 0}
              onClick={() => onAddSelectedRecipient(true)}
            >
              Add
            </Button>
          </div>
        </div>
      </Grid>
      <Grid item sm={12} md={6} style={{ padding: "0.5rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 700, flexGrow: 2 }}>
            Selected Recipients:{" "}
            <span style={{ color: "#2CABEA" }}>{selectedRecipientCount}</span>
          </Typography>
          <span style={{ flexGrow: 1 }}>|</span>
          <span style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <PeopleAltOutlinedIcon
              sx={{ height: "28px", width: "16px", color: "#003261" }}
            />
            <span style={{ marginLeft: "5px", color: "#003261" }}>
              {selectedRecipientGroupCount}
            </span>
          </span>
          <span style={{ flexGrow: 1 }}>
            <CircleIcon
              sx={{ height: "10px", width: "10px", color: "#003261" }}
            />
          </span>
          <span style={{ display: "flex", alignItems: "center" }}>
            <PersonOutlineOutlinedIcon
              sx={{ height: "16px", width: "16px", color: "#003261" }}
            />
            <span style={{ marginLeft: "5px", color: "#003261" }}>
              {selectedRecipientIndividualCount}
            </span>
          </span>
        </div>
        <div style={{}}>
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              border: "1.5px solid rgba(0, 50, 97, 0.2)",
              borderRadius: "10px",
              height: "333px",
              overflowY: "scroll",
              marginTop: "10px",
            }}
          >
            {selectedRecipientListLoading && (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={25} />
              </div>
            )}
            {!selectedRecipientListLoading &&
              selectedGroupRecipientsList &&
              selectedGroupRecipientsList.map((group, index) => {
                const labelId = `checkbox-list-label-${group.Id}`;
                return (
                  <ListItem key={`ListItem${index}${group.Id}`} disablePadding>
                    <ListItemButton
                      role={undefined}
                      onClick={onSelectbox2Recipient(group)}
                      dense
                    >
                      {group.Name !== undefined ? (
                        <ListItemText
                          id={labelId}
                          primary={group.Name}
                          secondary={
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <PeopleAltOutlinedIcon
                                sx={{ height: "12px", width: "20px" }}
                              />
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  lineHeight: "initial",
                                }}
                              >
                                {group.RecipientCount}
                              </span>
                              {/* <CircleIcon
                                sx={{
                                  height: "5px",
                                  width: "5px",
                                  color: "#003261",
                                  marginLeft: "6px",
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: "5px",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  lineHeight: "initial",
                                }}
                              >
                                Category Text
                              </span> */}
                            </span>
                          }
                        />
                      ) : (
                        <ListItemText
                          id={labelId}
                          primary={`${group.FirstName} ${group.LastName}`}
                          secondary={
                            group.City !== "" &&
                            group.Country !== "" && (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FmdGoodIcon
                                  sx={{ height: "12px", width: "20px" }}
                                />
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "initial",
                                  }}
                                >
                                  {`${group.City}, ${group.Country}`}
                                </span>
                                {/* <CircleIcon
                                sx={{
                                  height: "5px",
                                  width: "5px",
                                  color: "#003261",
                                  marginLeft: "6px",
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: "5px",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  lineHeight: "initial",
                                }}
                              >
                                Category Text
                              </span> */}
                              </span>
                            )
                          }
                        />
                      )}
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Checkbox
                          edge="start"
                          checked={
                            selectedRecipientChecked.indexOf(group) !== -1
                          }
                          tabIndex={-1}
                          disableRipple
                          // disabled={selectedRecipientChecked.length === 0}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </List>
        </div>
        {showRecipientAlert && (
          <div
            style={{
              display: "flex",
              marginTop: "-70px",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              paddingLeft: "35px",
            }}
          >
            <Alert>{newRecipientCount} recipient(s) added</Alert>
          </div>
        )}
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Link
              underline="always"
              onClick={onSelectAllSelectedRecipient}
              style={{ cursor: "pointer" }}
            >
              Select All
            </Link>
            <span style={{ padding: "0 5px" }}>|</span>
            <Link
              underline="always"
              onClick={onDeselectAllSelectedRecipient}
              style={{ cursor: "pointer" }}
            >
              Deselect All
            </Link>
          </div>
          <div>
            <Button
              variant="outlined"
              size="small"
              sx={{ width: "55px", height: "32px" }}
              disabled={selectedRecipientChecked.length === 0}
              onClick={onRemoveSelectedRecipient}
            >
              Remove
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
});

export default SelectRecipients;

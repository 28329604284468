import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Link,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormik, ErrorMessage } from "formik";
import { Form, Formik } from "formik";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Logo from "../assets/images/logo.png";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import KeyIcon from "@mui/icons-material/Key";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const ResetPassword = (props) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("New Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmNewPassword: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      "Passwords must match"
    ),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      props.onSubmit(values);
      resetForm({ values: "" });
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={`${Logo}`} alt="VRad Logo" width="200" height="60" />
        </Box>
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" color="textSecondary">
            Communications Center
          </Typography>
        </Box>
        {!props.isResetPasswordSent && <Box
          sx={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            color="textSecondary"
            sx={{ fontSize: "1.7rem", color: '#000' }}
          >
            Reset Password
          </Typography>
          <Typography variant="caption" color="textSecondary" sx={{color: '#75787B'}}>
            Please enter your new password.
          </Typography>
        </Box>}
        {props.isResetPasswordSent && (
          <Box
            sx={{
              marginTop: "42px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              color="textSecondary"
              sx={{ fontSize: "1.7rem" }}
            >
              Password Changed
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Your password has been successfully changed.
            </Typography>
          </Box>
        )}
        <Grid container spacing={1} sx={{ marginTop: "1rem" }}>
          {!props.isResetPasswordSent && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="textPrimary">
                New Password<span style={{ color: "#FA4858" }}>*</span>
              </Typography>
              <TextField
                required
                id="newPassword"
                name="newPassword"
                fullWidth
                margin="dense"
                placeholder="Enter New Password"
                size="small"
                type={showPassword ? "text" : "password"}
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                InputProps={{
                  // startAdornment: (
                  //   <InputAdornment position="start">
                  //     <LockIcon />
                  //   </InputAdornment>
                  // ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {!props.isResetPasswordSent && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="textPrimary">
                Confirm New Password<span style={{ color: "#FA4858" }}>*</span>
              </Typography>
              <TextField
                required
                id="confirmNewPassword"
                name="confirmNewPassword"
                fullWidth
                margin="dense"
                placeholder="Re-enter New Password"
                size="small"
                type={showConfirmPassword ? "text" : "password"}
                value={formik.values.confirmNewPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmNewPassword &&
                  Boolean(formik.errors.confirmNewPassword)
                }
                helperText={
                  formik.touched.confirmNewPassword &&
                  formik.errors.confirmNewPassword
                }
                InputProps={{
                  // startAdornment: (
                  //   <InputAdornment position="start">
                  //     <LockIcon />
                  //   </InputAdornment>
                  // ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {!props.isResetPasswordSent && (
            <Grid item xs={12} sx={{ marginTop: "22px" }}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          )}
          <Grid item xs={12} sx={{ textAlign: "center", marginTop: "40px" }}>
            <Link
              component="button"
              variant="body2"
              type="submit"
              onClick={() => {
                props.onReturntoLogin();
              }}
            >
              Return to Login
            </Link>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alignLeftRight: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  })
);

export default ResetPassword;

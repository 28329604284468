import { request } from "./request";
import {
  FETCH_TEMPLATES,
  FETCH_TEMPLATE,
  PUT_TEMPLATES,
  POST_TEMPLATES,
} from "../constants/constants";

export function fetchTemplates() {
  return (dispatch) => {
    return request("Template", "GET", {}).then((response) => {
      dispatch({
        type: FETCH_TEMPLATES,
        payload: response.Data,
      });
    });
  };
}

export function fetchTemplatesById(Id) {
  return (dispatch) => {
    return request(`Template/${Id}`, "GET", {}).then((response) => {
      dispatch({
        type: FETCH_TEMPLATE,
        payload: response.Data,
      });
    });
  };
}

export function deleteTemplatesById(Id) {
  return (dispatch) => {
    return request(`Template/${Id}`, "DELETE", {}).then((response) => {
      dispatch({
        type: FETCH_TEMPLATE,
        payload: response.Data,
      });
    });
  };
}

export function postTemplate(payload) {
  return (dispatch) => {
    return request("Template", "POST", payload).then((response) => {
      dispatch({
        type: POST_TEMPLATES,
        payload: response.Data,
      });
    });
  };
}

export function putTemplate(payload) {
  return (dispatch) => {
    return request("Template", "PUT", payload).then((response) => {
      dispatch({
        type: PUT_TEMPLATES,
        payload: response.Data,
      });
    });
  };
}

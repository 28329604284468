import * as React from "react";
import { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import SortIcon from "@mui/icons-material/Sort";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import useAPIError from "../common/hooks/useAPIError";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMessageLog,
  fetchMessageLogByFilter,
} from "../redux/actions/messageActions";
import { fetchStatusReportById } from "../redux/actions/dispatchActions";
import StatusReportssDialog from "../components/StatusReports/StatusReportsDialog";
import { convertToCentral } from "../common/helpers";
// Styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  [`&.undelivered`]: {
    color: "#f44336 !important",
  },
  [`&.delivered`]: {
    color: "#4caf50 !important",
  },
  MUIDataTableHeadCell: {
    sortAction: {
      "& svg": {
        color: theme.palette.primary.light,
      },
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// Sort functionality
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Pagination functionality
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <ArrowBackIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <ArrowBackIcon /> : <ArrowForwardIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// Create complex table head cells array
const headCells = [
  {
    id: "SentOn",
    numeric: false,
    disablePadding: false,
    label: "Sent On",
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Current Status",
  },
  {
    id: "TemplateName",
    numeric: false,
    disablePadding: false,
    label: "Dispatch Type | Preview",
  },
  {
    id: "RecipientCount",
    numeric: false,
    disablePadding: false,
    label: "Σ Recipients | Σ Gps.",
  },
  {
    id: "CreatedOn",
    numeric: false,
    disablePadding: false,
    label: "Date Created",
  },
  {
    id: "CreatedBy",
    numeric: false,
    disablePadding: false,
    label: "Created By",
  },
  {
    id: "Id",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

// Map that table head array to create functionality
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              IconComponent={SortIcon}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// Main export function
export default function DataTable() {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("SentOn");
  const [page, setPage] = React.useState(0);
  const [filterValue, setFilterValue] = React.useState("7D");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Import data
  const dispatch = useDispatch();
  const [dataFetched, setDataFetched] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [fail, setFail] = useState(false);
  const { showNotification } = useAPIError();

  const { messageLog } = useSelector((state) => ({
    messageLog: state.messageReducer.messageLog || [],
  }));

  const fetchMessages = (event) => {
    setDataFetched(false);
    setFail(false);
    dispatch(
      fetchMessageLogByFilter({
        TimeSpanString: filterValue,
        SortBy: "string",
        OrderBy: "string",
        StartIndex: 0,
        PageSize: 0,
      })
    )
      .then(() => {
        setDataFetched(true);
      })
      .catch((error) => {
        showNotification(error, "error");
        setDataFetched(false);
        setFail(true);
      });
  };

  useEffect(() => {
    fetchMessages([...messageLog]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Table UI controls
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - messageLog.length) : 0;

  const handleStatusDialog = () => {
    setStatusOpen(false);
  };

  // Function to build the Action icon, will need work after service connection
  function ActionButton(props) {
    return (
      <IconButton
        aria-label="View dispatch details"
        edge="end"
        size="small"
        onClick={(event) => {
          event.preventDefault();
          setDataFetched(false);
          dispatch(fetchStatusReportById(props.messageId))
            .then(() => {
              setStatusOpen(true);
              setDataFetched(true);
            })
            .catch((error) => {
              showNotification(error, "error");
              setDataFetched(false);
            });
        }}
      >
        <VisibilityIcon />
      </IconButton>
    );
  }

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
    setDataFetched(false);
    setFail(false);
    setPage(0)
    dispatch(
      fetchMessageLogByFilter({
        TimeSpanString: event.target.value,
        SortBy: "string",
        OrderBy: "string",
        StartIndex: 0,
        PageSize: 0,
      })
    )
      .then(() => {
        setDataFetched(true);
      })
      .catch((error) => {
        showNotification(error, "error");
        setDataFetched(false);
        setFail(true);
      });
  };

  return (
    <>
      <Grid container spacing={1} sx={{ height: "100%", padding: 0 }}>
        <Grid item xs={2} style={{ marginTop: "1rem", padding: 0 }}>
          <Box
            sx={{
              minWidth: 120,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#003261",
                marginRight: "1rem",
                fontSize: "1rem",
                fontWeight: 700,
              }}
            >
              Showing:
            </Typography>
            <FormControl fullWidth size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterValue}
                label=""
                onChange={handleFilterChange}
              >
                <MenuItem value="12H">Last 12 Hours</MenuItem>
                <MenuItem value="24H">Last 24 Hours</MenuItem>
                <MenuItem value="7D">Last 7 Days</MenuItem>
                <MenuItem value="60D">Last 60 Days</MenuItem>
                <MenuItem value="90D">Last 90 Days</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "2rem", padding: 0 }}>
          <Box sx={{ width: "100%", boxShadow: 3 }}>
            <Paper sx={{ width: "100%" }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: "100%" }}
                  aria-labelledby="tableTitle"
                  size="small"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={messageLog.length}
                  />
                  <TableBody>
                    {(!dataFetched && !fail) ? (
                      <CircularProgress />
                    ) : (!dataFetched && fail) ? (
                      <Typography sx={{ margin: "10px" }}>An error has occurred. Please try your request again.</Typography>
                    ) : (dataFetched && messageLog.length === 0) ? (
                      <Typography sx={{ margin: "10px" }}>Sorry, there is no matching data to display.</Typography>
                    ) : (
                      messageLog
                        .slice()
                        .sort(getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <StyledTableRow hover tabIndex={-1} key={row.Id}>
                              <StyledTableCell dispatch={dispatch}>
                                {convertToCentral(row.SentOn)}
                              </StyledTableCell>
                              <StyledTableCell
                                className={
                                  "" +
                                  (row.Status === "undelivered"
                                    ? "undelivered"
                                    : row.Status === "delivered" &&
                                      row.DeliverCount > 0
                                    ? "delivered"
                                    : "")
                                }
                              >
                                <strong>
                                  {row.DeliverCount} / {row.RecipientCount}{" "}
                                  Delivered
                                </strong>
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "1px",
                                }}
                              >
                                <strong>
                                  {row.TemplateName !== "" &&
                                  row.TemplateName != null
                                    ? row.TemplateName
                                    : "Custom"}
                                </strong>{" "}
                                |<em>"{row.Message}"</em>
                              </StyledTableCell>
                              <StyledTableCell>
                                <strong>{row.RecipientCount}</strong> |&nbsp;
                                <em>
                                  {row.GroupCount}{" "}
                                  {row.GroupCount > 1 || row.GroupCount === 0
                                    ? " Groups, "
                                    : " Group, "}
                                  {row.IndvRecipientCount + " Indv."}
                                </em>
                              </StyledTableCell>
                              <StyledTableCell>
                                {convertToCentral(row.CreatedOn)}
                              </StyledTableCell>
                              <StyledTableCell>{row.CreatedBy}</StyledTableCell>
                              <StyledTableCell>
                                <ActionButton messageId={row.Id} />
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                    )}
                    {emptyRows > 0 && (
                      <StyledTableRow
                        style={{
                          height: 33 * emptyRows,
                        }}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell colSpan={7} />
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          backgroundColor: "white",
                          borderColor: "white",
                          borderRadius: "16px",
                          padding: "4px 16px",
                        }}
                        startIcon={<AutorenewIcon />}
                        onClick={(event) => {
                          event.preventDefault();
                          setDataFetched(false);
                          dispatch(
                            fetchMessageLogByFilter({
                              TimeSpanString: filterValue,
                              SortBy: "string",
                              OrderBy: "string",
                              StartIndex: 0,
                              PageSize: 0,
                            })
                          )
                            .then(() => {
                              setDataFetched(true);
                            })
                            .catch((error) => {
                              showNotification(error, "error");
                              setDataFetched(false);
                            });
                        }}
                      >
                        Refresh
                      </Button>
                    </TableCell>
                    <TableCell>
                      <em>
                        # of Dispatches: <strong>{messageLog.length}</strong>
                      </em>
                    </TableCell>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      component="div"
                      count={messageLog.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelDisplayedRows={() => ""}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </TableContainer>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <StatusReportssDialog onclose={handleStatusDialog} open={statusOpen} />
    </>
  );
}

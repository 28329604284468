import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createStyles, makeStyles } from "@mui/styles";
import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import LoginBackground from "../../components/common/LoginBackground";
import ForgotPassword from "../../components/ForgotPassword";
import { useNavigate } from "react-router-dom";
import { getForgotPassword } from "../../redux/actions/loginActions";
import useAPIError from "../../common/hooks/useAPIError";

// import Signin from "../../components/Signin";
const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { showNotification } = useAPIError();
  const [isForgotPasswordLinkSent, setIsForgotPasswordLinkSent] =
    useState(false);

  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onReturntoLogin = (obj) => {
    navigate("/login");
  };

  const onSendAgain = (obj) => {
    setIsForgotPasswordLinkSent(false);
    // navigate("/forgotpassword");
  };

  const onSubmitSignin = (obj) => {
    dispatch(getForgotPassword(obj.email))
      .then(() => {
        setIsForgotPasswordLinkSent(true);
      })
      .catch((error) => {
        showNotification(
          error,
          "error"
        );
      });
  };

  return (
    <LoginBackground>
      <ForgotPassword
        onSubmit={onSubmitSignin}
        onReturntoLogin={onReturntoLogin}
        onSendAgain={onSendAgain}
        isForgotPasswordLinkSent={isForgotPasswordLinkSent}
      />
    </LoginBackground>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // loginBackground: {
    //   background: `url(${BGImage}) no-repeat center center fixed`,
    //   height: "100vh",
    //   width: "100vw",
    //   backgroundSize: "cover",
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    // },
    // loginCard: {
    //   width: "500px",
    //   // height: "500px",
    //   borderRadius: "1.5rem",
    //   // position:'absolute',
    //   // left: '50%',
    //   // top: '50%'
    // },
  })
);

export default ForgotPasswordPage;

/* eslint-disable import/no-anonymous-default-export */
import * as Actions from "../constants/constants";

const initialState = {
  result: [],
  role: {},
  notifications: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOG_IN_SUCCESS:
      return { ...state, result: action.payload };
    case Actions.FETCH_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case Actions.FETCH_ROLES:
      return { ...state, role: action.payload };
    default:
      return state;
  }
};

/* eslint-disable import/no-anonymous-default-export */
import * as Actions from "../constants/constants";

const initialState = {
  templates: [],
  template: [],
  NewAndEditTemplate: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_TEMPLATES:
      return { ...state, templates: action.payload };
    case Actions.FETCH_TEMPLATE:
      return { ...state, template: action.payload };
    case Actions.PUT_TEMPLATES:
    case Actions.POST_TEMPLATES:
      return { ...state, NewAndEditTemplate: action.payload };
    default:
      return state;
  }
};

import moment from "moment";

export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");
  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  } 
  return cleaned;
};

export const convertToCentral = (value) => {
  let dateVal = moment(value).format("YYYY-MM-DDTHH:mm:ssZ");
  let utc = new Date(dateVal);
  let CST = `${moment(utc.toLocaleString("en-US", { timeZone: "America/Chicago" })).format(
    "MM / DD @ h:mm a"
  )} (CST)`;
  return CST;
};

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const rolesList = (role) => {
  let roleList = [];
  let admin = {
    role: role,
    dispatchModule: ["newDispatch"],
    userModule: ["user", "view", "create", "edit", "delete", "view"],
    statusPage: ["status"],
    recipient: ["recipient", "create", "edit", "delete", "view"],
    recipientGroup: ["recipientGroup", "create", "edit", "delete", "view"],
    template: ["template", "create", "edit", "delete", "view"],
  };
  
  let dispatchUsers = {
    role: role,
    dispatchModule: ["newDispatch"],
    userModule: ["user", "view"],
    statusPage: ["status"],
    recipient: ["recipient", "view" ],
    recipientGroup: ["recipientGroup", "view"],
    template: ["template", "view"],
  };
  
  let reportingUsers = {
    role: role,
    dispatchModule: ["newDispatch"],
    userModule: ["user", "view"],
    statusPage: ["status"],
    recipient: ["recipient", "create", "edit"],
    recipientGroup: ["recipientGroup", "create", "edit"],
    template: ["template", "create", "edit", "delete"],
  };

  if (role === "Admin") {
    roleList = admin;
  } else if (role === "Reporting") {
    roleList = reportingUsers
  } else if (role === "Dispatch") {
    roleList = dispatchUsers
  }

  return roleList;  
};

import React, { useState, useCallback } from 'react'

export const APIErrorContext = React.createContext({
  error: null,
  showNotification: () => {},
  removeError: () => {},
})

export default function APIErrorProvider({ children }) {
  const [error, setError] = useState(null)

  const removeError = () => setError(null)

  const showNotification = (message, severity) => setError({ message, severity })

  const contextValue = {
    error,
    showNotification: useCallback((message, severity) => showNotification(message, severity), []),
    removeError: useCallback(() => removeError(), []),
  }

  return (
    <APIErrorContext.Provider value={contextValue}>
      {children}
    </APIErrorContext.Provider>
  )
}
/* eslint-disable import/no-anonymous-default-export */
import * as Actions from "../constants/constants";

const initialState = {
  messageLog: [],
  messageDetails: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_MESSAGE_LOG:
      return { ...state, messageLog: action.payload };
    case Actions.FETCH_MESSAGE_DETAILS_BY_ID:
      return { ...state, messageDetails: action.payload };
    default:
      return state;
  }
};

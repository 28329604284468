import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, TextField } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { fetchMessageDetailsById } from "../../redux/actions/messageActions";
import useAPIError from "../../common/hooks/useAPIError";
import store from '../../redux/store'

const MessageDetails = () => {
    const dispatch = useDispatch();
    const [dataFetched, setDataFetched] = useState(false);
    const [messageDetailsData, setMessageDetailsData] = useState([]);
    const { showNotification } = useAPIError();

    // Data carried over from the Status Reports Table view
    const { statusReports } = useSelector((state) => ({
        statusReports: state.dispatchReducer.statusReports || [],
    }));

    // Data fetched for full message details
    const fetchMessageDetails = (event) => {
        setDataFetched(false);
        dispatch(fetchMessageDetailsById(statusReports[0].DispatchId))
        .then(() => {
            setDataFetched(true);
            setMessageDetailsData(store.getState().messageReducer.messageDetails);
        })
        .catch((error) => {
            showNotification(error, "error");
            setDataFetched(false);
        });
    };

    useEffect(() => {
        fetchMessageDetails();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid container spacing={2} sx={{ height: "100%", paddingTop: "0.5rem" }}>
              <Grid item sm={12} md={6} style={{ padding: "0.5rem" }}>
                <div
                    style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "2px",
                    }}
                >
                    <Typography
                        variant="body2"
                        align="center"
                        sx={{ fontsize: "12px", fontWeight: 700 }}
                    >
                        Dispatch Message - <em>SENT</em>
                    </Typography>
                </div>
                <div
                    style={{ marginTop: "1rem", display: "flex", position: "relative" }}
                >
                    <TextField
                        rows={8}
                        multiline
                        aria-label="maximum height"
                        placeholder=""
                        value={messageDetailsData.Message}
                        disabled={true}
                        onChange={(event) => {
                            event.preventDefault();
                        }}
                        style={{ width: "100%", height: "300px", }}
                        inputProps={{
                            maxLength: 480,
                            style: {
                            height: "250px",
                            },
                        }}
                    />
                </div>
                <div
                    style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontWeight: 700,
                    }}
                >
                    <Typography
                        variant="body3"
                        align="center"
                        sx={{ fontsize: "12px", fontWeight: 700, padding: "0px 1px", width: "210px" }}
                    >
                        Older phones may split messages over 160 characters into multiple
                        messages.
                    </Typography>
                    <span style={{ color: "#2CABEA", fontSize: "1rem", width: "70px" }}>
                        {(Object.keys(messageDetailsData).length > 0) && messageDetailsData.Message.length}
                        <span
                            style={{
                            color: "#003261",
                            fontSize: "0.875rem",
                            marginLeft: "2px",
                            }}
                        >
                         / 480
                        </span>
                    </span>
                </div>
              </Grid>
              <Grid item sm={12} md={6} style={{ padding: "0.5rem" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "flex-row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 700, flexGrow: 2 }}>
                    Selected Recipients: &nbsp; 
                    <span style={{ color: "#2CABEA" }}>{messageDetailsData.RecipientCount}</span>
                  </Typography>
                  <span>&nbsp; | &nbsp;</span>
                  <Typography variant="body1" style={{
                        display: "flex",
                        flexDirection: "flex-row",
                        justifyContent: "space-around",
                        alignItems: "center",
                        fontWeight: 700,
                        flexGrow: 1,
                    }}>
                    <span style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                        <PeopleAltOutlinedIcon
                        sx={{ height: "28px", width: "16px", color: "#003261" }}
                        />
                        <span style={{ marginLeft: "5px", color: "#003261" }}>
                            &nbsp;
                            {messageDetailsData.GroupCount}
                            &nbsp;
                        </span>
                    </span>
                    <span style={{ flexGrow: 1 }}>
                        <CircleIcon
                        sx={{ height: "10px", width: "10px", color: "#003261" }}
                        />
                    </span>
                    <span style={{ display: "flex", alignItems: "center" }}>
                        <PersonOutlineOutlinedIcon
                        sx={{ height: "16px", width: "16px", color: "#003261" }}
                        />
                        <span style={{ marginLeft: "5px", color: "#003261" }}>
                            &nbsp;
                            {messageDetailsData.IndvRecipientCount}
                            &nbsp;
                        </span>
                    </span>
                  </Typography>
                </div>
                <div style={{}}>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      border: "1.5px solid rgba(0, 50, 97, 0.2)",
                      borderRadius: "10px",
                      height: "333px",
                      overflowY: "scroll",
                      marginTop: "10px",
                    }}
                  >
                      {messageDetailsData &&
                      messageDetailsData.groupRecipientDetails &&
                      messageDetailsData.groupRecipientDetails.map((group, index) => {
                        const labelId = `checkbox-list-label-${group.GroupId}`;
                        return (
                          <ListItem key={`ListItem${index}${group.GroupId}`}>
                            <ListItemText
                                id={labelId}
                                primary={group.GroupName}
                                secondary={
                                <span
                                    style={{ display: "flex", alignItems: "center" }}
                                >
                                    <PeopleAltOutlinedIcon
                                    sx={{ height: "12px", width: "20px" }}
                                    />
                                    <span
                                    style={{
                                        fontSize: "12px",
                                        fontWeight: 600,
                                        lineHeight: "initial",
                                    }}
                                    >
                                    {group.GroupRecipientsCnt}
                                    </span>
                                </span>
                                }
                            />
                          </ListItem>
                        );
                      })}
                      {messageDetailsData &&
                      messageDetailsData.IndRecipients &&
                      messageDetailsData.IndRecipients.map((indiv, index) => {
                        const labelId = `checkbox-list-label-${indiv.Id}`;
                        return (
                          <ListItem key={`ListItem${index}${indiv.Id}`}>
                            <ListItemText
                                id={labelId}
                                primary={`${indiv.FirstName} ${indiv.LastName}`}
                                secondary={indiv.City !== "" &&
                                    indiv.Country !== "" && (
                                    <span
                                    style={{ display: "flex", alignItems: "center" }}
                                    >
                                    <FmdGoodIcon
                                        sx={{ height: "12px", width: "20px" }}
                                    />
                                        <span
                                            style={{
                                            fontSize: "12px",
                                            fontWeight: 600,
                                            lineHeight: "initial",
                                            }}
                                        >
                                            {`${indiv.City}, ${indiv.Country}`}
                                        </span>
                                    </span>
                                    )}
                                />
                          </ListItem>
                        );
                      })}
                  </List>
                  <div style={{ marginTop: "1.5rem" }}>
                    <Typography variant="body2" sx={{ fontsize: "12px", fontWeight: 700, }}>
                        Delivery method:
                    </Typography>
                    <span
                        style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        }}
                    >
                        <span style={{ fontSize: "1rem", color: "#003261" }}>
                            {messageDetailsData.PreferenceType === "Recipient"
                                ? "Recipient's Preference"
                                : messageDetailsData.PreferenceType}
                        </span>
                        {messageDetailsData.PreferenceType === "Text" && (
                        <span style={{ fontSize: "0.8rem", color: "#6D6E70" }}>
                            Dispatch will be sent via text. This will override
                            recipient's preferred delivery method.
                        </span>
                        )}
                        {messageDetailsData.PreferenceType === "Voice" && (
                        <span style={{ fontSize: "0.8rem", color: "#6D6E70" }}>
                            Dispatch will be sent via text-to-voice. This will override
                            recipient's preferred delivery method.
                        </span>
                        )}
                        {messageDetailsData.PreferenceType === "Recipient" && (
                        <span style={{ fontSize: "0.8rem", color: "#6D6E70" }}>
                            Dispatch will be sent via the recipient's preferred
                            delivery method.
                        </span>
                        )}
                    </span>
                    </div>
                </div>
              </Grid>
            </Grid>
        </>
    );
}

export default MessageDetails;

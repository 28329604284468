import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useFormik } from "formik";
import * as yup from "yup";
import useAPIError from "../../common/hooks/useAPIError";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import Notification from "../common/Notifications";
import Stack from "@mui/material/Stack";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Divider,
  IconButton,
  CircularProgress,
  Box,
  Chip,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchUsers,
  fetchUserById,
  fetchUsersByStatus,
  fetchRoles,
  postUser,
  deleteUser,
  updateUser,
} from "../../redux/actions/userActions";
import moment from "moment";
import store from "../../redux/store";
import { formatPhoneNumber, convertToCentral } from "../../common/helpers";

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: "#e2596b !important",
        },
      },
      MuiTableHead: {
        root: {
          backgroundColor: "#e2596b",
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            backgroundColor: "#e2596b",
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: "#e2596b",
          },
        },
      },
    },
  });

const ManageUserTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userList, rolesList, role } = useSelector((state) => ({
    userList: state.usersReducer.usersList || [],
    rolesList: state.usersReducer.rolesList || [],
    role: state.loginReducer.role,
  }));

  const { showNotification } = useAPIError();
  const [user, setUser] = useState(false);
  const [saveProgress, setSaveProgress] = useState(false);
  const [userListData, setUserListData] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const [userData, setUserData] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [showDeleteProgress, setShowDeleteProgress] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteContent, setDeleteContent] = useState([]);
  const [filterValue, setFilterValue] = React.useState("true");
  const [messageDetails, setMessageDetails] = useState({
    message: "",
    severity: "",
  });

  useEffect(() => {
    setShowProgress(true);
    Promise.all([
      dispatch(fetchUsersByStatus(filterValue)),
      dispatch(fetchRoles()),
    ])
      .then(() => {
        setUserListData(store.getState().usersReducer.usersList);
        setShowProgress(false);
      })
      .catch((error) => {
        showNotification(error, "error");
        setShowProgress(false);
      });
  }, []); // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleDelete = (value, rowIndex) => {
    const index = userListData.findIndex(row => {
      return row.Email === rowIndex.rowData[0];
    });
    setDeleteContent(userListData[index]);
    setOpenDeleteDialog(true);
  };
  const handleDisable = (value, rowIndex) => {
    const index = userListData.findIndex(row => {
      return row.Email === rowIndex.rowData[0];
    });
    return !userListData[index].IsActive
  };

  const handleDeleteConfirm = () => {
    setShowDeleteProgress(true);
    dispatch(deleteUser(deleteContent.Id))
      .then(() => {
        dispatch(
          fetchUsersByStatus(filterValue === "All User" ? null : filterValue)
        )
          .then(() => {
            showNotification(
              `Success: ${deleteContent.FullName} has been set to Inactive.`
            );
            setUserListData(store.getState().usersReducer.usersList);
            setDeleteContent([]);
            setShowDeleteProgress(false);
            setOpenDeleteDialog(false);
          })
          .catch((error) => {
            showNotification(error, "error");
            setShowProgress(false);
          });
      })
      .catch((error) => {
        showNotification(error, "error");
        setShowProgress(false);
      });
  };

  const editUser = (value, rowIndex) => {
    setShowProgress(true);
    const index = userListData.findIndex(row => {
      return row.Email === rowIndex.rowData[0];
    });
    dispatch(fetchUserById(userListData[index].Id))
      .then(() => {
        setUserData(store.getState().usersReducer.user);
        setUser(true);
        setShowProgress(false);
      })
      .catch((error) => {
        showNotification(error, "error");
        setShowProgress(false);
      });
  };

  const handleOpenDialog = () => {
    formik.resetForm();
    setUserData([]);
    setUser(!user);
  };

  const handleDeleteClose = () => {
    setDeleteContent([]);
    setOpenDeleteDialog(!openDeleteDialog);
  };


  const validationSchema = yup.object({
    FirstName: yup
      .string("Enter your First Name")
      .required("First Name is required")
      .matches(/^[a-zA-Z][a-zA-Z .,'-]*$/, "Invalid First Name"),
    LastName: yup
      .string("Enter your Last Name")
      .required("Last Name is required")
      .matches(/^[a-zA-Z][a-zA-Z .,'-]*$/, "Invalid Last Name"),
    Email: yup
      .string("Enter your Email Address")
      .email("Enter valid email address")
      .required("Email is required"),
    Phone: yup
      .string()
      // .matches(/^[A-Za-z0-9_.]+$/, "Must be only digits and be between 5 and 10 characters")
      .min(5, "Must be at least 5 digits")
      .max(14, "Must be no more than 10 digits")
      .required("Phone number is required"),
    IsActive: yup
      .string("Enter your User Status")
      .required("User Status is required"),
    RoleId: yup.string("Enter Role").required("Role is required"),
  });

  const formik = useFormik({
    initialValues: {
      FirstName: Object.keys(userData).length > 0 ? userData.FirstName : "",
      LastName: Object.keys(userData).length > 0 ? userData.LastName : "",
      Email: Object.keys(userData).length > 0 ? userData.Email : "",
      Phone: Object.keys(userData).length > 0 ? userData.Phone : "",
      IsActive:
        Object.keys(userData).length > 0
          ? userData.IsActive
            ? "true"
            : "false" || ""
          : "",
      RoleId: Object.keys(userData).length > 0 ? userData.RoleId : "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    resetForm: ({ resetForm }) => {
      resetForm({ values: "" });
    },
    onSubmit: (values, { resetForm }) => {
      let findRole = rolesList.find((r) => r.Id === values.RoleId);

      if (userData.Id) {
        let obj = {
          Id: userData.Id,
          UserName: values.Email,
          FirstName: values.FirstName,
          LastName: values.LastName,
          Email: values.Email,
          FullName: values.FirstName.concat("", values.LastName),
          Phone: values.Phone,
          IsActive: values.IsActive === "true" ? true : false,
          RoleName: findRole.Name,
          RoleId: values.RoleId,
        };
        setSaveProgress(true);
        dispatch(updateUser(obj))
          .then(() => {
            dispatch(fetchUsersByStatus(filterValue)).then(() => {
              setUserListData(store.getState().usersReducer.usersList);
              showNotification(
                `${values.FirstName} ${values.LastName} Updated successfully`,
                "success"
              );
              setUser(false);
              setSaveProgress(false);
            });
          })
          .catch((error) => {
            showNotification(error, "error");
            setUser(true);
            setSaveProgress(false);
          });
        resetForm({ values: "" });
      } else {
        let obj = {
          UserName: values.Email,
          FirstName: values.FirstName,
          LastName: values.LastName,
          Email: values.Email,
          FullName: values.FirstName.concat(values.LastName),
          Phone: values.Phone,
          IsActive: values.IsActive === "true" ? true : false,
          RoleName: findRole.Name,
          RoleId: values.RoleId,
        };
        setSaveProgress(true);
        dispatch(postUser(obj))
          .then(() => {
            dispatch(fetchUsersByStatus(filterValue)).then(() => {
              setUserListData(store.getState().usersReducer.usersList);
              showNotification(
                `${values.FirstName} ${values.LastName} Added successfully`,
                "success"
              );
              setUser(false);
              setSaveProgress(false);
              resetForm({ values: "" });
            });
          })
          .catch((error) => {
            showNotification(error, "error");
            setUser(true);
            setSaveProgress(false);
          });
      }
    },
  });

  // ROLE ID
  const columns = [
    {
      name: "Email",
      label: "User Email",
      options: {
        setCellProps: () => ({ style: { minWidth: "20px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden !important",
                textOverflow: "ellipsis",
              }}
            >
              {value ? `${value.substring(0, 10)}...` : ""}
            </span>
          );
        },
      },
    },
    {
      name: "RoleName",
      label: "Role",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              label={value}
              sx={{
                backgroundColor: getBGColorRole(value),
                color: getColorRole(value),
              }}
            />
          );
        },
      },
    },
    {
      name: "FirstName",
      label: "First Name",
    },
    {
      name: "LastName",
      label: "Last Name",
    },
    {
      name: "IsActive",
      label: "User Status",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              label={value ? "Active" : "Inactive"}
              sx={{
                backgroundColor: value ? "#84C666" : "#C8C9C7",
                color: value ? "#FFFFFF" : "#000",
              }}
            />
            // <span className={value ? classes.active : classes.inactive}>
            //   {" "}
            //   {value ? "Active" : "Inactive"}{" "}
            // </span>
          );
        },
      },
    },
    {
      name: "CreatedBy",
      label: "Created By",
      options: {
        setCellProps: () => ({ style: { minWidth: "20px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden !important",
                textOverflow: "ellipsis",
              }}
            >
              {value ? `${value.substring(0, 10)}...` : ""}
            </span>
          );
        },
      },
    },
    {
      name: "CreatedOn",
      label: "Created On",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return convertToCentral(value); // value ? moment(value).format("MM / Do @ h:mm a") : "";
        },
      },
    },
    {
      name: "UpdatedBy",
      label: "Modified By",
      options: {
        setCellProps: () => ({ style: { minWidth: "20px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden !important",
                textOverflow: "ellipsis",
              }}
            >
              {value ? `${value.substring(0, 10)}...` : "N/A"}
            </span>
          );
        },
      },
    },
    {
      name: "UpdatedOn",
      label: "Modified On",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? convertToCentral(value) : "N/A";
          // return value ? moment(value).format("MM / Do @ h:mm a") : "";
        },
      },
    },
    {
      name: "Action",
      options: {
        filter: true,
        display: role.role !== "Dispatch" && role.role !== "Reporting",
        sort: true,
        width: "30px",
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex" }}>
              {Object.keys(role).length > 0 &&
                role.userModule.includes("delete") && (
                  <IconButton
                    disabled={handleDisable(value, tableMeta)}
                    onClick={(event) => handleDelete(value, tableMeta)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                )}
              {Object.keys(role).length > 0 &&
                role.userModule.includes("edit") && (
                  <IconButton
                    onClick={(event) => editUser(value, tableMeta)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
    selectableRows: false, // <===== will turn off checkboxes in rows
    responsive: "stacked",
    filter: false,
    download: false,
    print: false,
    search: true,
    viewColumns: false,
    jumpToPage: true,
    textLabels: {
      body: {
        noMatch: showProgress ? (
          <CircularProgress />
        ) : (
          "Sorry, there is no matching data to display"
        ),
        toolTip: ""
      },
    },
  };

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
    setShowProgress(true);
    setUserData([]);
    dispatch(
      fetchUsersByStatus(
        event.target.value === "All User" ? null : event.target.value
      )
    ).then(() => {
      setUserListData(store.getState().usersReducer.usersList);
      setShowProgress(false);
    });
  };

  const getBGColorRole = (role) => {
    if (role === "Admin") {
      return "rgba(250, 145, 72, 0.2)";
    } else if (role === "Reporting") {
      return "rgba(132, 198, 102, 0.2)";
    } else if (role === "Dispatch") {
      return "rgba(139, 209, 243, 0.2)";
    }
  };

  const getColorRole = (role) => {
    if (role === "Admin") {
      return "#FA9148";
    } else if (role === "Reporting") {
      return "#84C666";
    } else if (role === "Dispatch") {
      return "#2CABEA";
    }
  };

  return (
    <>
      <Grid container spacing={2} mt={1} p={1}>
        {/* Manage User Table  Start*/}
        {!user && !openDeleteDialog && (
          <>
            <Grid item xs={12} mt={1} container justifyContent="space-between">
              <Box
                sx={{
                  minWidth: 120,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    color: "#003261",
                    marginRight: "1rem",
                    fontSize: "1rem",
                    fontWeight: 700,
                  }}
                >
                  Showing:
                </Typography>
                <FormControl fullWidth size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterValue}
                    label=""
                    onChange={handleFilterChange}
                  >
                    <MenuItem value="true">Active Users</MenuItem>
                    <MenuItem value="false">Inactive Users</MenuItem>
                    <MenuItem value="null">All Users</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {Object.keys(role).length > 0 &&
                role.userModule.includes("create") && (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      backgroundColor: "#8BD1F3",
                      color: "#003261",
                      width: "12rem",
                      "&:hover": {
                        backgroundColor: "#8BD1F3",
                        opacity: 0.8,
                      },
                    }}
                    onClick={handleOpenDialog}
                  >
                    Create New User
                  </Button>
                )}
              {/* <Button
                      size="small"
                      variant="contained"
                      sx={{
                        backgroundColor: "#8BD1F3",
                        color: "#003261",
                        width: "12rem",
                        "&:hover": {
                          backgroundColor: "#8BD1F3",
                          opacity: 0.8,
                        },
                      }}
                      onClick={handleOpenDialog}
                    >
                      {showProcessing && (
                        <CircularProgress size={25} sx={{ color: "#fff" }} />
                      )}
                      {!showProcessing && `Create New Recipient`}
                    </Button> */}
            </Grid>
            {/* <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              ></Grid>
            </Grid> */}

            <Grid item xs={12} mt={1}>
              <ThemeProvider theme={getMuiTheme}>
                <MUIDataTable
                  title=""
                  data={userListData}
                  columns={columns}
                  options={options}
                  className={classes.muiDataTableUI}
                />
              </ThemeProvider>
            </Grid>
          </>
        )}

        {user && !openDeleteDialog && (
          <>
            <Grid item xs={12}>
              <form onSubmit={formik.handleSubmit}>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  style={{ color: "#2CABEA" }}
                >
                  {Object.keys(userData).length > 0
                    ? `Edit User`
                    : `Create New User`}
                </Typography>
                <Divider />

                <Grid container spacing={3} mt={2}>
                  <Grid item xs={4}>
                    <Typography>
                      User Email<span style={{ color: "#FA4858" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      id="Email"
                      name="Email"
                      variant="outlined"
                      placeholder="Enter Email"
                      size="small"
                      margin="dense"
                      // disabled={Object.keys(userData).length > 0}
                      inputProps={{
                        readOnly: Object.keys(userData).length > 0,
                      }}
                      value={formik.values.Email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.Email && Boolean(formik.errors.Email)
                      }
                    />
                    <Typography variant="overline" color="error">
                      {formik.touched.Email && formik.errors.Email}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      First Name<span style={{ color: "#FA4858" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      id="FirstName"
                      name="FirstName"
                      variant="outlined"
                      placeholder="Enter First Name"
                      size="small"
                      margin="dense"
                      value={formik.values.FirstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.FirstName &&
                        Boolean(formik.errors.FirstName)
                      }
                    />
                    <Typography variant="overline" color="error">
                      {formik.touched.FirstName && formik.errors.FirstName}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      Last Name<span style={{ color: "#FA4858" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      id="LastName"
                      name="LastName"
                      variant="outlined"
                      placeholder="Enter Last Name"
                      size="small"
                      margin="dense"
                      value={formik.values.LastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.LastName &&
                        Boolean(formik.errors.LastName)
                      }
                    />
                    <Typography variant="overline" color="error">
                      {formik.touched.LastName && formik.errors.LastName}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      Phone Number<span style={{ color: "#FA4858" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      id="Phone"
                      name="Phone"
                      size="small"
                      placeholder="Enter Phone Number"
                      margin="dense"
                      variant="outlined"
                      // maxLength={10}
                      inputProps={{
                        maxLength: 11,
                      }}
                      value={formatPhoneNumber(formik.values.Phone)}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.Phone && Boolean(formik.errors.Phone)
                      }
                    />
                    <Typography variant="overline" color="error">
                      {formik.touched.Phone && formik.errors.Phone}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      Role<span style={{ color: "#FA4858" }}>*</span>
                    </Typography>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      margin="dense"
                    >
                      <Select
                        value={formik.values.RoleId}
                        onChange={formik.handleChange}
                        displayEmpty
                        id="RoleId"
                        name="RoleId"
                        // label="Role"
                        error={
                          formik.touched.RoleId && Boolean(formik.errors.RoleId)
                        }
                        // inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem disabled value="">Select Role</MenuItem>
                        {rolesList.map((option) => {
                          if (option.Name !== "ApplicationUser") {
                            return (
                              <MenuItem key={option.Id} value={option.Id}>
                                {option.Name}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                    <Typography variant="overline" color="error">
                      {formik.touched.RoleId && formik.errors.RoleId}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      User Status<span style={{ color: "#FA4858" }}>*</span>
                    </Typography>
                    <FormControl
                      variant="outlined"
                      size="small"
                      margin="dense"
                      fullWidth
                    >
                      <Select
                        value={formik.values.IsActive}
                        id="IsActive"
                        name="IsActive"
                        onChange={formik.handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        error={
                          formik.touched.IsActive &&
                          Boolean(formik.errors.IsActive)
                        }
                      >
                        <MenuItem disabled value="">Select Status</MenuItem>
                        <MenuItem value="true">Active</MenuItem>
                        <MenuItem value="false">InActive</MenuItem>
                      </Select>
                    </FormControl>
                    <Typography variant="overline" color="error">
                      {formik.touched.IsActive && formik.errors.IsActive}
                    </Typography>
                  </Grid>
                  <Grid mt={6} item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    mt={5}
                  >
                    <Stack spacing={2} direction="row">
                      <Button
                        sx={{
                          color: "#003261",
                          width: "12rem",
                        }}
                        color="primary"
                        fullWidth
                        variant="outlined"
                        onClick={handleOpenDialog}
                      >
                        Cancel
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        type="submit"
                        sx={{
                          backgroundColor: "#8BD1F3",
                          color: "#003261",
                          width: "12rem",
                          "&:hover": {
                            backgroundColor: "#8BD1F3",
                            opacity: 0.8,
                          },
                        }}
                      >
                        {saveProgress && (
                          <CircularProgress size={25} sx={{ color: "#fff" }} />
                        )}
                        {!saveProgress
                          ? Object.keys(userData).length > 0
                            ? !saveProgress && `Save Changes`
                            : !saveProgress && `Save & Create User`
                          : ""}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </>
        )}

        {openDeleteDialog && !user && (
          <Grid container item xs={12}>
            <Grid xs={12} item>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                style={{ color: "#2CABEA" }}
              >
                Deactivate User
              </Typography>
              <Divider />
            </Grid>
            <Grid xs={12} item sx={{ mt: 4 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "12px 16px",
                  // height: "63px",
                  margin: "1rem",
                  background: "#FFFFFF",
                  border: "1px solid rgba(0, 50, 97, 0.85)",
                  boxShadow: "0px 12px 16px rgba(72, 85, 133, 0.15)",
                  borderRadius: "6px",
                  flexWrap: "wrap",
                }}
              >
                <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                  {deleteContent && deleteContent.Email}
                </span>
                <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                  {deleteContent && (
                    <Chip
                      label={deleteContent.RoleName}
                      sx={{
                        backgroundColor: getBGColorRole(deleteContent.RoleName),
                        color: getColorRole(deleteContent.RoleName),
                      }}
                    />
                  )}
                </span>
                <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                  {deleteContent && deleteContent.FirstName}
                </span>
                <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                  {deleteContent && deleteContent.LastName}
                </span>
                <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                  {deleteContent && (
                    <Chip
                      label={deleteContent.IsActive ? "Active" : "Inactive"}
                      sx={{
                        backgroundColor: deleteContent.IsActive
                          ? "#84C666"
                          : "#C8C9C7",
                        color: deleteContent.IsActive ? "#FFFFFF" : "#000",
                      }}
                    />
                  )}
                </span>
                <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                  {deleteContent && deleteContent.CreatedBy}
                </span>
                <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                  {deleteContent &&
                    moment(deleteContent.CreatedOn).format("MM/DD/YYYY")}
                </span>
                <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                  {deleteContent && deleteContent.UpdatedBy}
                </span>
                <span style={{ padding: "0.5rem", fontWeight: "700" }}>
                  {deleteContent && deleteContent.UpdatedOn
                    ? moment(deleteContent.UpdatedOn).format("MM/DD/YYYY")
                    : "N/A"}
                </span>
              </div>
            </Grid>
            <Grid
              xs={12}
              item
              sx={{ mt: 6, display: "block", textAlign: "center" }}
            >
              <Typography
                sx={{ fontWeight: 700, fontSize: "34px", lineHeight: "34px" }}
              >
                Are you Sure ?
              </Typography>
              <Typography
                sx={{
                  color: "#75787B",
                  fontSize: "16px",
                  lineHeight: "20px",
                  mt: "4px",
                }}
              >
                This will deactivate user and the user will not be able to log in to the application.
              </Typography>
              <div style={{ marginTop: "83px", marginBottom: "80px" }}>
                <Button autoFocus onClick={handleDeleteClose}>
                  Cancel
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#FA4858",
                    color: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#FA4858",
                      opacity: 0.8,
                    },
                  }}
                  onClick={handleDeleteConfirm}
                >
                  {showDeleteProgress && (
                    <CircularProgress size={25} sx={{ color: "#fff" }} />
                  )}
                  {!showDeleteProgress && `Deactivate User`}
                </Button>
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      maxWidth: "100% !important",
      // width: "1080px !important",
      height: "746px",
      overflow: "hidden !important",
    },
    active: {
      color: "#05CD99",
    },
    inactive: {
      color: "#0D1624",
    },
    muiDataTableUI: {
      borderRadius: "10px 10px 0px 0px !important",

      "& .tss-r6c9w6-MUIDataTable-responsiveStacked": {
        borderRadius: "0",
      },

      "& .MuiTableCell-head": {
        backgroundColor: "#11315E",
        color: "#FFFFFF !important",
        padding: "0.5rem",
        whiteSpace: "nowrap",
        // textAlign: "center",

        "& .Mui-active": {
          color: "#FFFFFF",
        },

        "& .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon":
          {
            color: "#FFFFFF",
          },

        "& .tss-bd2lw8-MUIDataTableHeadCell-sortActive": {
          color: "#FFFFFF",
        },

        "& .css-hyum1k-MuiToolbar-root": {
          backgroundColor: "#11315E !important",
        },

        "& .MuiInput-root:before": {
          borderBottom: "1px solid #FFFFFF !important",
        },

        "& .MuiInput-root:after": {
          borderBottom: "2px solid #FFFFFF !important",
        },
      },
      "& .tss-tlx3x1-MUIDataTableToolbar-root": {
        backgroundColor: "#11315E !important",
        color: "#FFFFFF",
        minHeight: "0.5rem !important",
        borderRadius: "10px 10px 0px 0px !important",
      },
      "& .tss-10rusft-MUIDataTableToolbar-icon": {
        color: "#FFFFFF",
      },
      // "& .MuiSvgIcon-root": {
      //   color: '#FFFFFF'
      // },
      "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root": {
        color: "#FFFFFF",
      },

      // "& .css-zylse7-MuiButtonBase-root-MuiIconButton-root": {
      //   backgroundColor: 'rgba(0, 0, 0, 0.54)',
      //   height: '1rem',
      //   width: '1rem',
      //   padding: '0.8rem'
      // },

      "& .tss-1ufdzki-MUIDataTableSearch-main > .MuiSvgIcon-root": {
        color: "#ffffff",
      },

      "& .tss-1ufdzki-MUIDataTableSearch-main > .MuiButtonBase-root": {
        color: "#ffffff",
      },

      "& .MuiInput-root": {
        color: "#ffffff",
      },

      "& .MuiInput-root:before": {
        borderBottom: "1px solid #FFFFFF !important",
      },

      "& .MuiInput-root:after": {
        borderBottom: "2px solid #FFFFFF !important",
      },
      // "& .tss-1ufdzki-MUIDataTableSearch-main > .MuiFormControl-root > .MuiInput-root:before":
      //   {
      //     borderBottom: "1px solid #FFFFFF !important",
      //   },
      // "& .tss-1ufdzki-MUIDataTableSearch-main > .MuiFormControl-root > .MuiInput-root:after":
      //   {
      //     borderBottom: "2px solid #FFFFFF !important",
      //   },
      // "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root:before": {
      //   borderBottom: '1px solid #FFFFFF !important'
      // },
      // "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root:after ": {
      //   borderBottom: '2px solid #FFFFFF !important'
      // }
    },
  })
);

export default ManageUserTable;

import { request, authRequest } from "./request";
import {
  FETCH_USERS_LIST,
  FETCH_ROLES_LIST,
  POST_ADD_USER,
  DELETE_USER,
  UPDATE_USER,
  FETCH_USERS_BY_ID
} from "../constants/constants";

export function fetchUsers(data) {
  return (dispatch) => {
    return request("User", "GET", {}).then((response) => {
      dispatch({
        type: FETCH_USERS_LIST,
        payload: response.Data,
      });
    });
  };
}

export function fetchUsersByStatus(status) {
  return (dispatch) => {
    return request(`User/UsersByStatus/${status}`, "GET", {}).then(
      (response) => {
        dispatch({
          type: FETCH_USERS_LIST,
          payload: response.Data,
        });
      }
    );
  };
}

export function fetchUserById(Id) {
  return (dispatch) => {
    return request(`User/${Id}`, "GET", {}).then((response) => {
      dispatch({
        type: FETCH_USERS_BY_ID,
        payload: response.Data,
      });
    });
  };
}

export function deleteUser(Id) {
  return (dispatch) => {
    return request(`User/${Id}`, "DELETE", {}).then((response) => {
      dispatch({
        type: DELETE_USER,
        payload: response.Data,
      });
    });
  };
}

export function fetchRoles(data) {
  return (dispatch) => {
    return request("Role", "GET", {}).then((response) => {
      dispatch({
        type: FETCH_ROLES_LIST,
        payload: response.Data,
      });
    });
  };
}

export function postUser(payload) {
  return (dispatch) => {
    return authRequest("Users/PostExternalUser", "POST", payload).then(
      (response) => {
        dispatch({
          type: POST_ADD_USER,
          payload: response.Data,
        });
      }
    );
  };
}

export function updateUser(payload) {
  return (dispatch) => {
    return request("User", "PUT", payload).then((response) => {
      dispatch({
        type: UPDATE_USER,
        payload: response.Data,
      });
    });
  };
}

import React from "react";
import { useSelector } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import SortIcon from "@mui/icons-material/Sort";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { convertToCentral, formatPhoneNumber } from "../../common/helpers";

// Styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  [`&.undelivered`]: {
    color: "#f44336 !important",
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  [`&.delivered`]: {
    color: "#4caf50 !important",
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  MUIDataTableHeadCell: {
    sortAction: {
      "& svg": {
        color: theme.palette.primary.light,
      },
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// Sort functionality
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Pagination functionality
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <ArrowBackIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <ArrowBackIcon /> : <ArrowForwardIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// Create complex table head cells array
const headCells = [
  {
    id: "CreatedOn",
    numeric: false,
    disablePadding: false,
    label: "Date and Time",
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Current Status",
  },
  {
    id: "RecipientPhone",
    numeric: false,
    disablePadding: false,
    label: "Phone Number",
  },
];

// Map that table head array to create functionality
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              IconComponent={SortIcon}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// Main export function
const StatusReportsTable = () => {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("SentOn");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { statusReports } = useSelector((state) => ({
    statusReports: state.dispatchReducer.statusReports || [],
  }));

  // Table UI controls
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - statusReports.length) : 0;

  return (
    <>
      <Grid container spacing={1} sx={{ height: "100%", padding: 0 }}>
        <Grid item xs={12} style={{ marginTop: "2rem", padding: 0 }}>
          <Box sx={{ width: "100%", boxShadow: 3 }}>
            <Paper sx={{ width: "100%" }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: "100%" }}
                  aria-labelledby="tableTitle"
                  size="small"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={statusReports.length}
                  />
                  <TableBody>
                    {statusReports
                        .slice()
                        .sort(getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <StyledTableRow hover tabIndex={-1} key={row.Id}>
                              <StyledTableCell>
                                {convertToCentral(row.CreatedOn)}
                              </StyledTableCell>
                              <StyledTableCell
                                className={
                                  "" +
                                  (row.Status === "undelivered"
                                    ? "undelivered"
                                    : row.Status === "delivered"
                                    ? "delivered"
                                    : "")
                                }
                              >
                                {row.Status}
                              </StyledTableCell>
                              <StyledTableCell>
                                {(row.CountryCode !== "" || row.CountryCode !== null) && row.CountryCode}
                                {' '}
                                {(row.RecipientPhone.length === 10)
                                  ? formatPhoneNumber(row.RecipientPhone)
                                  : row.RecipientPhone}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                    }
                    {emptyRows > 0 && (
                      <StyledTableRow
                        style={{
                          height: 33 * emptyRows,
                        }}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell colSpan={7} />
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      component="div"
                      count={statusReports.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelDisplayedRows={() => ""}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </TableContainer>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default StatusReportsTable;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createStyles, makeStyles } from "@mui/styles";
import {
  fetchNotifications,
  postLogin,
} from "../../redux/actions/loginActions";
import Signin from "../../components/Signin";
import useAuth from "../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import LoginBackground from "../../components/common/LoginBackground";
import moment from "moment";
import useAPIError from "../../common/hooks/useAPIError";

const Login = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { showNotification } = useAPIError();
  const [userObj, setUserObj] = useState({});
  const [showProcessing, setShowProcessing] = useState(false);
  const [messageDetails, setMessageDetails] = useState({
    message: "",
    severity: "",
  });

  const { setAuth } = useAuth();
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    const ttlSpan = JSON.parse(localStorage.getItem("vRadLoginTimeSpan"));
    if (ttlSpan) {
      if (
        moment(ttlSpan.ttl).isBefore(
          moment(new Date()).format("DD/MM/YYYY hh:mm:ss")
        )
      ) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("FullName");
        localStorage.removeItem("Email");
      }
    }
    // localStorage.removeItem("authToken");
    // localStorage.removeItem("refreshToken");
    // localStorage.removeItem("FullName");
    // console.log(localStorage.getItem("vRadUserDetails"));
    // if (localStorage.getItem("vRadUserDetails")) {
    //   let remmbermeObject = localStorage.getItem("vRadUserDetails");
    //   let numberofDays = moment(new Date()).diff(
    //     moment(new Date(remmbermeObject.expiry)),
    //     "days"
    //   );
    //   console.log(numberofDays);
    //   if (numberofDays < 10) {
    //     setUserObj({
    //       userName: remmbermeObject.userName,
    //       password: remmbermeObject.password,
    //       rememberMe: remmbermeObject.rememberMe,
    //     });
    //   } else {
    //     setUserObj({
    //       userName: "",
    //       password: "",
    //       rememberMe: "",
    //     });
    //   }
    // } else {
    //   setUserObj({
    //     userName: "",
    //     password: "",
    //     rememberMe: "",
    //   });
    // }
    // if(moment(new Date()).diff(moment(new Date(rowData.Date)), 'days'))
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onForgotPassword = (obj) => {
    navigate("/forgotpassword");
  };

  const onSubmitSignin = (obj) => {
    setShowProcessing(true);
    dispatch(postLogin(obj))
      .then(() => {
        const accessToken = localStorage.getItem("authToken");
        const roles = [2001, 1984];
        setAuth({
          username: obj.username,
          password: obj.password,
          roles: roles,
          accessToken: accessToken,
        });
        navigate("/dashboard", { replace: true });
        dispatch(fetchNotifications())
        // if (obj.rememberMe === "true") {
        //   const vRadObj = {
        //     value: obj,
        //     expiry: new Date(),
        //   };
        //   localStorage.setItem("vRadUserDetails", vRadObj);
        // }
        setShowProcessing(false);
      })
      .catch((error) => {
        error !== "" && showNotification(error, "error");
        setShowProcessing(false);
      });
  };

  return (
    <LoginBackground>
      <Signin
        onSubmit={onSubmitSignin}
        onForgotPassword={onForgotPassword}
        userObj={userObj}
        showProcessing={showProcessing}
      />
    </LoginBackground>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // loginBackground: {
    //   background: `url(${BGImage}) no-repeat center center fixed`,
    //   height: "100vh",
    //   width: "100vw",
    //   backgroundSize: "cover",
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    // },
    // loginCard: {
    //   width: "500px",
    //   // height: "500px",
    //   borderRadius: "1.5rem",
    //   // position:'absolute',
    //   // left: '50%',
    //   // top: '50%'
    // },
  })
);

export default Login;

import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Link,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormik, ErrorMessage } from "formik";
import { Form, Formik } from "formik";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Logo from "../assets/images/logo.png";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import KeyIcon from "@mui/icons-material/Key";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const ForgotPassword = (props) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, {resetForm}) => {
      props.onSubmit(values);
      setUserEmail(values.email);
      resetForm({ values: ''});
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      onKeyDown={(event) => event.keyCode === 13 && formik.handleSubmit()}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={`${Logo}`} alt="VRad Logo" width="200" height="60" />
        </Box>
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" color="textSecondary">
            Communications Center
          </Typography>
        </Box>
        {!props.isForgotPasswordLinkSent && (
          <Box
            sx={{
              marginTop: "32px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              color="textSecondary"
              sx={{ fontSize: "1.7rem" }}
            >
              Forgot Password
            </Typography>
            <Typography variant="caption" color="textSecondary">
              No worries, we’ll email you instructions to reset your password.
            </Typography>
          </Box>
        )}
        <Grid container spacing={1} sx={{ marginTop: "40px" }}>
          {!props.isForgotPasswordLinkSent && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="textPrimary">
                Email Address
              </Typography>
              <TextField
                fullWidth
                id="email"
                name="email"
                label=""
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
          )}
          {!props.isForgotPasswordLinkSent && (
            <Grid item xs={12} sx={{ marginTop: "22px" }}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Request a reset link
              </Button>
            </Grid>
          )}
          {props.isForgotPasswordLinkSent && (
            <Box
              sx={{
                marginTop: "32px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{ fontSize: "1.7rem" }}
              >
                Email Sent
              </Typography>
              <Typography variant="caption" color="textSecondary">
                We sent an email to <strong>{userEmail}</strong> with a link to reset your password. Didn't receive the email? {" "}
                <Link
                  component="button"
                  variant="caption"
                  type="submit"
                  onClick={() => {
                    props.onSendAgain();
                  }}
                >
                  Click here to resend
                </Link>
              </Typography>
              <CheckCircleOutlineIcon
                sx={{
                  height: "125px",
                  width: "125px",
                  color: "#05CD99",
                  marginTop: "15px",
                }}
              />
            </Box>
          )}
          <Grid item xs={12} sx={{ textAlign: "center", marginTop: "40px" }}>
            <Link
              component="button"
              variant="body2"
              type="submit"
              onClick={() => {
                props.onReturntoLogin();
              }}
            >
              Return to Login
            </Link>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alignLeftRight: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  })
);

export default ForgotPassword;

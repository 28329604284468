import React, { useImperativeHandle, useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import CircleIcon from "@mui/icons-material/Circle";

const ReviewAndConfirm = React.forwardRef(
  ({ templateInfo, recipientInfo, deliveryInfo }, ref) => {
    return (
      <Grid
        container
        spacing={1}
        sx={{
          height: "100%",
          border: "1px dashed #2CABEA",
          borderRadius: "1rem",
          padding: "32px 28px",
        }}
      >
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            height: "100%",
            padding: "1.875rem 1.375rem",
          }}
          style={{ padding: "0.5rem" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "2px",
            }}
          >
            <Typography
              variant="body2"
              align="center"
              sx={{ fontsize: "12px" }}
            >
              Dispatch Message - PREVIEW
            </Typography>
          </div>
          <div
            style={{ marginTop: "1rem", display: "flex", position: "relative" }}
          >
            <TextField
              rows={8}
              multiline
              aria-label="maximum height"
              placeholder=""
              value={templateInfo.templateText}
              disabled={true}
              onChange={(event) => {
                event.preventDefault();
              }}
              style={{ width: "100%", height: "300px" }}
              inputProps={{
                maxLength: 480,
                style: {
                  height: "250px",
                },
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body3"
              align="center"
              sx={{ fontsize: "12px", padding: "0px 1px", width: "210px" }}
            >
              Older phones may split messages over 160 characters into multiple
              messages.
            </Typography>
            <span style={{ color: "#2CABEA", fontSize: "1rem", width: "70px" }}>
              {templateInfo.templateText.length}
              <span
                style={{
                  color: "#003261",
                  fontSize: "0.875rem",
                  marginLeft: "2px",
                }}
              >
                / 480
              </span>
            </span>
          </div>
        </Grid>
        <Grid item sm={12} md={6} style={{ padding: "0.5rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flexspace-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 400, flexGrow: 2 }}>
              Selected Recipients:{" "}
              <span style={{ color: "#2CABEA" }}>
                {recipientInfo.selectedRecipientCount}
              </span>
            </Typography>
            <span style={{ flexGrow: 1 }}>|</span>
            <span
              style={{ display: "flex", alignItems: "center", flexGrow: 1 }}
            >
              <PeopleAltOutlinedIcon
                sx={{ height: "28px", width: "16px", color: "#003261" }}
              />
              <span style={{ marginLeft: "5px", color: "#003261" }}>
                {recipientInfo.selectedRecipientGroupCount}
              </span>
            </span>
            <span style={{ flexGrow: 1 }}>
              <CircleIcon
                sx={{ height: "10px", width: "10px", color: "#003261" }}
              />
            </span>
            <span style={{ display: "flex", alignItems: "center" }}>
              <PersonOutlineOutlinedIcon
                sx={{ height: "16px", width: "16px", color: "#003261" }}
              />
              <span style={{ marginLeft: "5px", color: "#003261" }}>
                {recipientInfo.selectedRecipientIndividualCount}
              </span>
            </span>
          </div>
          <div style={{}}>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                border: "1.5px solid rgba(0, 50, 97, 0.2)",
                borderRadius: "10px",
                height: "233px",
                overflowY: "scroll",
                marginTop: "10px",
              }}
            >
              {recipientInfo.selectedRecipient.map((group) => {
                const labelId = `checkbox-list-label-${group.Id}`;
                return (
                  <ListItem key={group.Id} disablePadding>
                    <ListItemButton
                      role={undefined}
                      // onClick={onSelectbox2Recipient(group)}
                      dense
                    >
                      {group.Name !== undefined ? (
                        <ListItemText
                          id={labelId}
                          primary={group.Name}
                          secondary={
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <PeopleAltOutlinedIcon
                                sx={{ height: "12px", width: "20px" }}
                              />
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  lineHeight: "initial",
                                }}
                              >
                                {group.RecipientCount}
                              </span>
                            </span>
                          }
                        />
                      ) : (
                        <ListItemText
                          id={labelId}
                          primary={`${group.FirstName} ${group.LastName}`}
                          secondary={
                            group.City !== "" &&
                            group.Country !== "" && (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FmdGoodIcon
                                  sx={{ height: "12px", width: "20px" }}
                                />
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "initial",
                                  }}
                                >
                                  {`${group.City}, ${group.Country}`}
                                </span>
                              </span>
                            )
                          }
                        />
                      )}
                      {/* <ListItemIcon sx={{ minWidth: 0 }}>
                        <Checkbox
                          edge="start"
                          checked={
                            selectedRecipientChecked.indexOf(group) !== -1
                          }
                          tabIndex={-1}
                          disableRipple
                          // disabled={selectedRecipientChecked.length === 0}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon> */}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <div style={{ marginTop: "1.5rem" }}>
              <Typography variant="body2" sx={{ fontsize: "12px" }}>
                Delivery method:
              </Typography>
              <span
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <span style={{ fontSize: "1rem", color: "#003261" }}>
                  {deliveryInfo.deliveryMode === "Recipient"
                    ? "Recipient's Preference"
                    : deliveryInfo.deliveryMode}
                </span>
                {deliveryInfo.deliveryMode === "Recipient" && (
                  <span style={{ fontSize: "0.8rem", color: "#6D6E70" }}>
                    Dispatch will be sent via the recipient’s preferred delivery
                    method.
                  </span>
                )}
                {deliveryInfo.deliveryMode === "Text" && (
                  <span style={{ fontSize: "0.8rem", color: "#6D6E70" }}>
                    Dispatch will be sent via text. This will override
                    recipient’s preferred delivery method.
                  </span>
                )}
                {deliveryInfo.deliveryMode === "Voice" && (
                  <span style={{ fontSize: "0.8rem", color: "#6D6E70" }}>
                    Dispatch will be sent via text-to-voice. This will override
                    recipient’s preferred delivery method.
                  </span>
                )}
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
);

export default ReviewAndConfirm;

import React from "react";
import useAPIError from "../../../common/hooks/useAPIError";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const APIErrorNotification = () => {
  const { error, removeError } = useAPIError();
  const [open, setOpen] = React.useState(!!error);

  const handleSubmit = () => {
    removeError();
    // setOpen(error !== null)
    return false
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={!!error}
      autoHideDuration={6000}
      onClose={handleSubmit}
    >
      {error && error.message && <Alert onClose={handleSubmit} severity={error.severity} sx={{ width: "100%", color: "#FFFFFF" }}>
        {error && error.message && <p>{error.message}</p>}
      </Alert>}
    </Snackbar>
    // <Modal open={!!error} data-testid="notification-modal">
    //   <div>
    //     {error && error.message && <p>({error.message})</p>}
    //     <button data-testid="notification-submit-button" onClick={handleSubmit}>
    //       Ok
    //     </button>
    //   </div>
    // </Modal>
  );
}

export default APIErrorNotification

import { applyMiddleware, createStore } from "redux";
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';

const middleWare = applyMiddleware(thunk);
const store = createStore(
  rootReducer,
  middleWare
);

export default store;
import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import RequireAuth from "./components/common/RequiredAuth";
import Login from "./pages/Login/Login";
import ForgotPasswordPage from "./pages/Login/ForgotPasswordPage";
import ResetPasswordPage from "./pages/Login/ResetPasswordPage";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import Layout from "./components/Layout";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Admin from "./pages/Admin/Admin";
import Reports from "./pages/Reports/Reports";

// Dummy roles. May be replaced from service.
const ROLES = {
  User: 2001,
  Admin: 5150,
};

const Router = () => {
  const navigate = useNavigate();

  axios.interceptors.response.use(
    (response) => {
      // setIsTimeOut(false);
      return response;
    },
    (error) => {
      if (Object.keys(error).length > 0 && error.hasOwnProperty("response")) {
        if (error.response.status === 401) {
          localStorage.removeItem("authToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("FullName");
          localStorage.removeItem("Email");
          navigate("/login");
          window.location.reload(false);
        }
      }
      return Promise.reject(error);
    }
  );

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        {/* <Route exact path="/" element={<Login />} /> */}
        <Route exact path="/forgotPassword" element={<ForgotPasswordPage />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/resetpassword" element={<ResetPasswordPage />} />

        {/* protected routes */}
        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route exact path="/dashboard" element={<Home />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/reports" element={<Reports />} />
        </Route>

        {/* catch all */}
        <Route exact path="/notfound" element={<NotFound />} />

        <Route path="*" element={<Navigate to="/login" replace />} />
      </Route>
    </Routes>
  );
};

export default Router;

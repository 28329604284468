import React, { useImperativeHandle, useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useDispatch } from "react-redux";
import store from "../../redux/store";
import useAPIError from "../../common/hooks/useAPIError";
import { fetchTemplates } from "../../redux/actions/dispatchActions";

const ComposeDispatch = React.forwardRef(({ templateInfo }, ref) => {
  const dispatch = useDispatch();
  const { showNotification } = useAPIError();
  const [templateType, setTemplateType] = React.useState(
    Object.keys(templateInfo).length > 0
      ? templateInfo.templateType
      : "CreateFromTemplate"
  );
  const [templateProcessing, setTemplateProcessing] = useState(false);
  const [selectedTemplateValue, setSelectedTemplateValue] = useState(
    Object.keys(templateInfo).length > 0 ? templateInfo.TemplateName : ""
  );
  const [selectedTemplateInputValue, setSelectedTemplateInputValue] =
    useState("");
  const [templateText, setTemplateText] = useState(
    Object.keys(templateInfo).length > 0 ? templateInfo.templateText : ""
  );
  const [templateTextCount, setTemplateTextCount] = useState(0);
  const [templateCollection, setTemplateCollection] = useState([]);
  const [isTemplateTextDisabled, setIsTemplateTextDisabled] = useState(
    Object.keys(templateInfo).length > 0 ? false : true
  );

  const onTemplateTypeChange = (event) => {
    if (event.target.value === "CreateNewDispatch") {
      setIsTemplateTextDisabled(false);
      setTemplateText("");
      setSelectedTemplateValue("");
    } else if (event.target.value === "CreateFromTemplate") {
      setIsTemplateTextDisabled(true);
      setTemplateText("");
      setSelectedTemplateValue("");
    }
    setTemplateType(event.target.value);
  };

  const onTemplateEdit = (event) => {
    setIsTemplateTextDisabled(false);
  };

  useEffect(() => {
    setTemplateProcessing(true);
    dispatch(fetchTemplates())
      .then(() => {
        setTemplateCollection(store.getState().dispatchReducer.templateList);
        setTemplateProcessing(false);
      })
      .catch((error) => {
        showNotification(error, "error");
        setTemplateProcessing(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    composeRef() {
      let composeDispatchValues = {
        isError: false,
        templateText: "",
        TemplateId: "",
        TemplateName: "",
      };
      if (templateText !== "") {
        composeDispatchValues.isError = false;

        if (templateType === "CreateFromTemplate") {
          composeDispatchValues.templateText = templateText;
          let selectedTemplateId = templateCollection.filter(
            (template) => template.Name === selectedTemplateValue
          )[0].Id;
          composeDispatchValues.TemplateId = selectedTemplateId;
          composeDispatchValues.TemplateName = selectedTemplateValue;
          composeDispatchValues.templateType = templateType;
        } else {
          composeDispatchValues.templateType = templateType;
          composeDispatchValues.templateText = templateText;
          composeDispatchValues.TemplateName = "";
          composeDispatchValues.TemplateId = "";
        }
      } else {
        composeDispatchValues.isError = true;
      }
      return composeDispatchValues;
    },
  }));

  return (
    <Grid container spacing={1}>
      <Grid item sm={12} md={5} style={{ padding: "0.5rem" }}>
        <FormControl>
          <RadioGroup
            aria-labelledby="Template type"
            name="templateType"
            value={templateType}
            onChange={onTemplateTypeChange}
          >
            <FormControlLabel
              value="CreateFromTemplate"
              control={<Radio />}
              label="Create From Template"
            />
            {templateType === "CreateFromTemplate" && (
              <Autocomplete
                value={selectedTemplateValue}
                onChange={(event, newValue) => {
                  setSelectedTemplateValue(newValue);
                  if (newValue) {
                    let tempTemplate = store
                      .getState()
                      .dispatchReducer.templateList.filter(
                        (template) => template.Name === newValue
                      );
                    setTemplateText(tempTemplate[0].TemplateHtml);
                  } else {
                    setTemplateText("");
                  }
                }}
                inputValue={selectedTemplateInputValue}
                onInputChange={(event, newInputValue) => {
                  setSelectedTemplateInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={templateCollection.map((option) => option.Name)}
                sx={{
                  width: 220,
                  "&:hover": {
                    border: "1px solid #2CABEA",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    variant="outlined"
                    placeholder="Template Name"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {templateProcessing ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            )}
            <FormControlLabel
              sx={{ mt: 2.5 }}
              value="CreateNewDispatch"
              control={<Radio />}
              label="Create New Dispatch"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {selectedTemplateValue === "" && templateType !== "CreateNewDispatch" && (
        <Grid
          item
          sm={12}
          md={7}
          sx={{
            height: "100%",
            width: "355px",
            // border: "1px dashed #2CABEA",
            // borderRadius: "1rem",
            padding: "1.875rem 1.375rem",
          }}
        ></Grid>
      )}
      {(selectedTemplateValue !== "" ||
        templateType === "CreateNewDispatch") && (
        <Grid
          item
          sm={12}
          md={7}
          sx={{
            height: "100%",
            border: "1px dashed #2CABEA",
            borderRadius: "1rem",
            padding: "1.875rem 1.375rem",
          }}
          // style={{ padding: "0.5rem" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 1rem",
            }}
          >
            <Typography
              variant="body2"
              align="center"
              sx={{ fontsize: "12px" }}
            >
              Dispatch Message - PREVIEW
            </Typography>
            {templateType !== "CreateNewDispatch" &&
              selectedTemplateValue !== "" && (
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ width: "55px", height: "32px" }}
                  onClick={onTemplateEdit}
                >
                  Edit
                </Button>
              )}
          </div>
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              position: "relative",
              padding: "0 1rem",
            }}
          >
            <TextField
              rows={8}
              multiline
              aria-label="maximum height"
              placeholder=""
              value={templateText}
              disabled={isTemplateTextDisabled}
              onChange={(event) => {
                setTemplateTextCount(event.target.value.length);
                setTemplateText(event.target.value);
              }}
              style={{ width: "100%", height: "320px" }}
              inputProps={{
                maxLength: 480,
                style: {
                  height: "280px",
                },
              }}
            />
            {templateType !== "CreateNewDispatch" && !isTemplateTextDisabled && (
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  marginTop: "0.6rem",
                  marginRight: "1.6rem",
                }}
              >
                <RestartAltIcon
                  sx={{
                    color: "#68769F",
                    width: "16px",
                    height: "21px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let tempTemplate = store
                      .getState()
                      .dispatchReducer.templateList.filter(
                        (template) => template.Name === selectedTemplateValue
                      );
                    setTemplateText(tempTemplate[0].TemplateHtml);
                  }}
                />
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 1rem",
            }}
          >
            <Typography
              variant="body3"
              align="center"
              sx={{ fontsize: "12px", padding: "0px 1px", width: "230px" }}
            >
              Older phones may split messages over 160 characters into multiple
              messages.
            </Typography>
            <span style={{ color: "#2CABEA", fontSize: "1rem", width: "70px" }}>
              {templateText.length}
              <span
                style={{
                  color: "#003261",
                  fontSize: "0.875rem",
                  marginLeft: "2px",
                }}
              >
                / 480
              </span>
            </span>
          </div>
        </Grid>
      )}
    </Grid>
  );
});

export default ComposeDispatch;

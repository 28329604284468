import React, { useImperativeHandle, useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const SetDeliveryMode = React.forwardRef(({ deliveryInfo }, ref) => {
  const [deliveryValue, setDeliveryValue] = React.useState(
    Object.keys(deliveryInfo).length > 0
      ? deliveryInfo.deliveryMode
      : "Recipient"
  );

  const handleDeliveryChange = (event) => {
    setDeliveryValue(event.target.value);
  };

  useImperativeHandle(ref, () => ({
    deliveryModeRef() {
      let deliveryModeValues = {
        isError: false,
        deliveryMode: "",
      };
      if (deliveryValue === "") {
        deliveryModeValues.isError = true;
      } else {
        deliveryModeValues.isError = false;
        deliveryModeValues.deliveryMode = deliveryValue;
      }
      return deliveryModeValues;
    },
  }));

  return (
    <Grid container spacing={1} sx={{ height: "100%" }}>
      <Grid item xs={12} style={{ padding: "0.5rem" }}>
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          Select a delivery method:
        </Typography>
        <FormControl sx={{ mt: 1.5 }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={deliveryValue}
            onChange={handleDeliveryChange}
          >
            <FormControlLabel
              value="Recipient"
              control={<Radio />}
              label={
                <span
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ fontSize: "1rem", color: "#003261" }}>
                    Recipient's Preference
                  </span>
                  <span style={{ fontSize: "0.8rem", color: "#6D6E70" }}>
                    Dispatch will be sent via the recipient’s preferred delivery
                    method.
                  </span>
                </span>
              }
            />
            <FormControlLabel
              sx={{ mt: 1.5 }}
              value="Text"
              control={<Radio />}
              label={
                <span
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ fontSize: "1rem", color: "#003261" }}>
                    Text
                  </span>
                  <span style={{ fontSize: "0.8rem", color: "#6D6E70" }}>
                    Dispatch will be sent via text. This will override
                    recipient’s preferred delivery method.
                  </span>
                </span>
              }
            />
            <FormControlLabel
              sx={{ mt: 1.5 }}
              value="Voice"
              control={<Radio />}
              label={
                <span
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ fontSize: "1rem", color: "#003261" }}>
                    Voice
                  </span>
                  <span style={{ fontSize: "0.8rem", color: "#6D6E70" }}>
                    Dispatch will be sent via text-to-voice. This will override
                    recipient’s preferred delivery method.
                  </span>
                </span>
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
});

export default SetDeliveryMode;

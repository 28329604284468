import React, { useState, useRef } from "react";
import ComposeDispatch from "./ComposeDispatch";
import SelectRecipients from "./SelectRecipients";
import SetDeliveryMode from "./SetDeliveryMode";
import ReviewAndConfirm from "./ReviewAndConfirm";
import Success from "./Success";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { createStyles, makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import {
  fetchGroupRecipientsWithFilter,
  postDispatchRecipients,
} from "../../redux/actions/dispatchActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import useAPIError from "../../common/hooks/useAPIError";
import { fetchStatusReportById } from "../../redux/actions/dispatchActions";
import store from "../../redux/store";
import StatusReportssDialog from "../StatusReports/StatusReportsDialog";

export default function StepHandler({ onclose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [statusProcessing, setStatusProcessing] = useState(false);
  const { showNotification } = useAPIError();
  const [messageDetails, setMessageDetails] = useState({
    message: "",
    severity: "",
  });
  const composeRef = useRef();
  const recipientsRef = useRef();
  const deliveryModeRef = useRef();
  const reviewAndConfirmRef = useRef();
  const [templateInfo, setTemplateInfo] = useState([]);
  const [recipientInfo, setRecipientInfo] = useState([]);
  const [deliveryInfo, setDeliveryInfo] = useState([]);
  const [statusOpen, setStatusOpen] = useState(false);
  // const [indRecipientIds, setIndRecipientIds] = useState("");
  // const [groupIds, setGroupIds] = useState("");

  const steps = [
    {
      label: "Compose Dispatch",
      component: "ComposeDispatch",
    },
    {
      label: "Select Recipients",
      component: "SelectRecipients",
    },
    {
      label: "Set Delivery Mode",
      component: "SetDeliveryMode",
    },
    {
      label: "Review & Confirm",
      component: "ReviewAndConfirm",
    },
  ];

  const handleNext = () => {
    if (!processing) {
      switch (activeStep) {
        case 0:
          const composeDispatchObj = composeRef.current.composeRef();
          composeDispatchObj.isError &&
            showNotification(
              "Error: Template text message is required.",
              "error"
            );
          !composeDispatchObj.isError && setProcessing(true);
          !composeDispatchObj.isError &&
            dispatch(fetchGroupRecipientsWithFilter())
              .then(() => {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setProcessing(false);
              })
              .catch((error) => {
                showNotification(error, "error");
                setProcessing(false);
              });
          setTemplateInfo(composeDispatchObj);
          break;
        case 1:
          const selectRecipientObj = recipientsRef.current.recipientsRef();
          selectRecipientObj.isError &&
            showNotification("Error: Recipient list is required.", "error");
          !selectRecipientObj.isError &&
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setRecipientInfo(selectRecipientObj);
          break;
        case 2:
          const deliveryModeRefObj = deliveryModeRef.current.deliveryModeRef();
          deliveryModeRefObj.isError &&
            showNotification("Error: Delivery Mode is required.", "error");
          !deliveryModeRefObj.isError &&
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setDeliveryInfo(deliveryModeRefObj);
          break;
        case 3:
          let groupIds = [];
          let indRecipientIds = [];
          recipientInfo.selectedRecipient.map((recipient) => {
            if (recipient.Name) {
              groupIds.push(recipient.Id);
            } else {
              indRecipientIds.push(recipient.Id);
            }
          });
          let Payload = {
            TemplateId: templateInfo.TemplateId,
            TemplateName: templateInfo.TemplateName,
            Message: templateInfo.templateText,
            PreferenceType: deliveryInfo.deliveryMode,
            GroupIds: groupIds,
            IndRecipientIds: indRecipientIds,
          };
          setProcessing(true);
          dispatch(postDispatchRecipients(Payload))
            .then(() => {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              showNotification(
                "Success: Dispatch sent successfully",
                "success"
              );
              setProcessing(false);
            })
            .catch((error) => {
              showNotification(error, "error");
              setProcessing(false);
            });
          break;
        default:
          break;
      }
    }
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (!processing) {
      switch (activeStep) {
        case 1:
          setRecipientInfo([]);
          const selectRecipientObj = recipientsRef.current.recipientsRef();
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          !selectRecipientObj.isError && setRecipientInfo(selectRecipientObj);
          break;
        case 2:
          const deliveryModeRefObj = deliveryModeRef.current.deliveryModeRef();
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          !deliveryModeRefObj.isError && setDeliveryInfo(deliveryModeRefObj);
          break;
        case 3:
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          break;
        default:
          break;
      }
    }
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setTemplateInfo([]);
    setRecipientInfo([]);
    setDeliveryInfo([]);
    setActiveStep(0);
  };

  const handleStatusReport = () => {
    setStatusProcessing(true);
    dispatch(
      fetchStatusReportById(store.getState().dispatchReducer.postDispatch)
    ).then(() => {
      setStatusProcessing(false);
      setStatusOpen(true);
    });
  };

  const handleStatusDialog = () => {
    setStatusOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          className={classes.overrideStepper}
        >
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={classes.stepperContainer}>
          <div className={classes.stepperContent}>
            {activeStep === 0 && (
              <ComposeDispatch ref={composeRef} templateInfo={templateInfo} />
            )}
            {activeStep === 1 && (
              <SelectRecipients
                ref={recipientsRef}
                recipientInfo={recipientInfo}
              />
            )}
            {activeStep === 2 && (
              <SetDeliveryMode
                ref={deliveryModeRef}
                deliveryInfo={deliveryInfo}
              />
            )}
            {activeStep === 3 && (
              <ReviewAndConfirm
                ref={reviewAndConfirmRef}
                templateInfo={templateInfo}
                recipientInfo={recipientInfo}
                deliveryInfo={deliveryInfo}
              />
            )}
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Success />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{
                      width: "256px",
                      backgroundColor: "#8BD1F3",
                      color: "#003261",
                      "&:hover": {
                        backgroundColor: "#8BD1F3",
                        opacity: 0.8,
                      },
                    }}
                    onClick={handleStatusReport}
                  >
                    {statusProcessing && (
                      <CircularProgress size={25} sx={{ color: "#fff" }} />
                    )}
                    {!statusProcessing && `View Status Report`}
                  </Button>
                  <Button
                    variant="outlined"
                    size="medium"
                    startIcon={<SendIcon />}
                    sx={{ width: "256px", marginTop: "1.5rem" }}
                    onClick={handleReset}
                  >
                    Send Another Dispatch
                  </Button>
                </div>
              </Paper>
            )}
          </div>
          {activeStep < steps.length && (
            <div className={classes.steppernav}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{
                    mt: 1,
                    mr: 1,
                    backgroundColor: "#8BD1F3",
                    color: "#003261",
                    width: "180px",
                    "&:hover": {
                      backgroundColor: "#8BD1F3",
                      opacity: 0.8,
                    },
                  }}
                  startIcon={
                    activeStep === steps.length - 1 && !processing ? (
                      <SendIcon />
                    ) : (
                      ""
                    )
                  }
                >
                  {processing && (
                    <CircularProgress size={25} sx={{ color: "#fff" }} />
                  )}
                  {!processing &&
                    (activeStep === steps.length - 1
                      ? !processing && "Send Dispatch"
                      : !processing && "Next Step")}
                </Button>
              </Box>
            </div>
          )}
        </div>
      </Box>
      <StatusReportssDialog onclose={handleStatusDialog} open={statusOpen} />
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overrideStepper: {
      marginTop: "2.5rem",
      // paddingRight: "4.313rem",
      paddingLeft: "2rem",
      borderRight: "1px solid #1C365F",
      "& .MuiStepConnector-root": {
        display: "none",
        border: "none",
      },
      // "& .MuiStepLabel-label .Mui-disabled": {
      //   border: 'none'
      // },
      "& .MuiStepLabel-label": {
        color: "#003261",
        opacity: 0.5,
      },
      "& .MuiStepLabel-root": {
        width: "100%",
        paddingRight: "4.313rem",
        whiteSpace: "nowrap",
        border: "none",
      },
      // "& .MuiStep-root": {
      //   borderRight: '3px solid #1C365F'
      // },
      "& .MuiStep-root .Mui-disabled": {
        border: "none",
      },
      "& .MuiSvgIcon-root": {
        // display: 'none'
      },
      "& .Mui-active": {
        color: "#003261 !important",
        opacity: "1 !important",
        fontWeight: "700 !important",
        // borderRight: '3px solid #1C365F'
      },
      "& .Mui-completed": {
        color: "#84C666 !important",
        opacity: "1 !important",
        border: "none",
      },
      "& .Mui-disabled": {
        border: "none",
      },
    },
    stepperContainer: {
      padding: "2.5rem 3.875rem 3rem 3.625rem",
      height: "100%",
      width: "100%",
    },
    stepperContent: {
      height: "95%",
    },
    steppernav: {
      height: "5%",
    },
  })
);

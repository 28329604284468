export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_GROUP_RECIPIENTS = 'FETCH_GROUP_RECIPIENTS';
export const FETCH_INDIVIDUAL_RECIPIENTS = 'FETCH_INDIVIDUAL_RECIPIENTS';
export const FETCH_STATUS_REPORTS_BY_ID = 'FETCH_STATUS_REPORTS_BY_ID';
export const FETCH_MESSAGE_DETAILS_BY_ID = 'FETCH_MESSAGE_DETAILS_BY_ID';
export const FETCH_MESSAGE_LOG = 'FETCH_MESSAGE_LOG';
export const SAVE_SELECTED_GROUP = 'SAVE_SELECTED_GROUP';
export const POST_SEND_DISPATCH = 'POST_SEND_DISPATCH';
export const FETCH_USERS_LIST = 'FETCH_USERS_LIST';
export const FETCH_USERS_BY_ID = 'FETCH_USERS_BY_ID';
export const FETCH_ROLES_LIST = 'FETCH_ROLES_LIST';
export const POST_ADD_USER = 'POST_ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
export const FETCH_TEMPLATE = 'FETCH_TEMPLATE';
export const POST_TEMPLATES = 'POST_TEMPLATES';
export const PUT_TEMPLATES = 'PUT_TEMPLATES';
export const FETCH_RECIPIENTS = 'FETCH_TEMPLATES';
export const FETCH_GROUPS = 'FETCH_GROUPS';
export const FETCH_RECIPIENT = 'FETCH_TEMPLATE';
export const POST_RECIPIENT = 'POST_TEMPLATES';
export const PUT_RECIPIENT = 'PUT_TEMPLATES';
export const FETCH_TEMPLATE_LIST = 'FETCH_TEMPLATE_LIST';
export const FETCH_GROUP = 'FETCH_GROUP';
export const POST_GROUP = 'POST_GROUP';
export const PUT_GROUP = 'PUT_GROUP';
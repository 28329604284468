/* eslint-disable import/no-anonymous-default-export */
import * as Actions from "../constants/constants";

const initialState = {
  usersList: [],
  rolesList: [],
  user: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_USERS_LIST:
      return { ...state, usersList: action.payload };
    case Actions.FETCH_USERS_BY_ID:
      return { ...state, user: action.payload };
    case Actions.FETCH_ROLES_LIST:
        return { ...state, rolesList: action.payload};
    case Actions.POST_ADD_USER:
    case Actions.DELETE_USER:
    case Actions.UPDATE_USER:
    default:
      return state;
  }
};

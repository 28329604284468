import { request } from "./request";
import {
  FETCH_RECIPIENTS,
  FETCH_RECIPIENT,
  PUT_RECIPIENT,
  POST_RECIPIENT,
  FETCH_GROUPS,
  FETCH_GROUP, POST_GROUP , PUT_GROUP
} from "../constants/constants";

export function fetchRecipients() {
  return (dispatch) => {
    return request("Recipient", "GET", {}).then((response) => {
      dispatch({
        type: FETCH_RECIPIENTS,
        payload: response.Data,
      });
    });
  };
}

export function fetchRecipientsByStatus(status = null) {
  return (dispatch) => {
    return request(`Recipient/GetRecipientsByStatus/${status}`, "GET", {}).then((response) => {
      dispatch({
        type: FETCH_RECIPIENTS,
        payload: response.Data,
      });
    });
  };
}

export function fetchGroups() {
  return (dispatch) => {
    return request("Group", "GET", {}).then((response) => {
      dispatch({
        type: FETCH_GROUPS,
        payload: response.Data,
      });
    });
  };
}

export function fetchRecipientById(Id) {
  return (dispatch) => {
    return request(`Recipient/${Id}`, "GET", {}).then((response) => {
      dispatch({
        type: FETCH_RECIPIENT,
        payload: response.Data,
      });
    });
  };
}

export function fetchGroupById(Id) {
  return (dispatch) => {
    return request(`Recipient/group/${Id}/recipients`, "GET", {}).then((response) => {
      dispatch({
        type: FETCH_GROUP,
        payload: response.Data,
      });
    });
  };
}

export function deleteRecipientById(Id) {
  return (dispatch) => {
    return request(`Recipient/${Id}`, "DELETE", {}).then((response) => {
      dispatch({
        type: FETCH_RECIPIENT,
        payload: response.Data,
      });
    });
  };
}

export function deleteGroupById(Id) {
  return (dispatch) => {
    return request(`Group/${Id}`, "DELETE", {}).then((response) => {
      dispatch({
        type: FETCH_GROUP,
        payload: response.Data,
      });
    });
  };
}

export function postRecipient(payload) {
  return (dispatch) => {
    return request("Recipient", "POST", payload).then((response) => {
      dispatch({
        type: POST_RECIPIENT,
        payload: response.Data,
      });
    });
  };
}

export function putRecipient(payload) {
  return (dispatch) => {
    return request("Recipient", "PUT", payload).then((response) => {
      dispatch({
        type: PUT_RECIPIENT,
        payload: response.Data,
      });
    });
  };
}

export function postGroup(payload) {
  return (dispatch) => {
    return request("Group", "POST", payload).then((response) => {
      dispatch({
        type: POST_GROUP,
        payload: response.Data,
      });
    });
  };
}

export function putGroup(payload) {
  return (dispatch) => {
    return request("Group", "PUT", payload).then((response) => {
      dispatch({
        type: PUT_GROUP,
        payload: response.Data,
      });
    });
  };
}
